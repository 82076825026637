<template>
	<div class="psc-cb-ipart-container" :style="{backgroundColor:Settings.rowSetting.background.contBackGroundCol}">
    	<div class="section-text" :class="Settings.rowSetting.width==2?'textWidth':Settings.rowSetting.width==1?'fullWidth':'inner'">
			<div class="psc-cb-text-container" :class="`preset${preset}` + (Settings.swapPosition==5 && Settings.toolIndex==2?'  reverse-row':'')">
				<div class="psc-cb-text-textcontent" >
					<h2 class="psc-cb-text-title" v-html="Settings.mainHeading" v-if="![3,4].includes(preset)&& Settings.mainHeading && Settings.mainHeading.length "></h2>
					<h1 class="psc-cb-text-title" v-html="Settings.mainHeading" v-if="preset==4 && Settings.mainHeading && Settings.mainHeading.length "></h1>
					<div class="psc-cb-text-body" v-html="Settings.mainHeadingContext" v-if="preset!==5 && Settings.mainHeadingContext&& Settings.mainHeadingContext.length"></div>
					<div class="psc-cb-text-actions">
						<a :target="Settings.primaryButtonLinkTarget==2?'_blank':''" :class="Settings.mainButtonClass" v-if="Settings.mainBtnLinkPath&&Settings.mainBtnLinkPath.length" :href="Settings.mainBtnLinkPath + (Settings.primaryButtonSource==2&& Settings.hasOwnProperty('primaryBtnLinkParameters')?Settings.primaryBtnLinkParameters:'')" class="psc-cb-button psc-cb-button-primary">{{Settings.mainButtonLabel}}</a>
						<a :target="Settings.secondaryButtonLinkTarget==2?'_blank':''" :class="Settings.secondaryMainButtonClass" v-if="Settings.mainSecondaryBtnLinkPath&&Settings.mainSecondaryBtnLinkPath.length" :href="Settings.mainSecondaryBtnLinkPath + (Settings.secondayButtonSource==2&& Settings.hasOwnProperty('secondaryBtnLinkParameters')?Settings.secondaryBtnLinkParameters:'')" class="psc-cb-button psc-cb-button-secondary">{{Settings.secondaryMainButtonLabel}}</a>
					</div>
					<div class="psc-cb-text-multibody" v-if="preset==5">
						<div class="psc-cb-text-multibody-block"
							v-for="(item,index) of Settings.items"
							:key="index"
						>
							<h3 class="psc-cb-text-multibody-block-title" v-html="item.title"></h3>
							<div class="psc-cb-text-multibody-block-content" v-html="item.context"></div>
						</div>
					</div>
				</div>
				<div class="psc-cb-text-imagecontent" v-if="[1,2,4].includes(preset)&& Settings.mainImagePath">
					<div class="psc-cb-text-image">
						<img :src="Settings.mainImagePath" :alt="Settings.mainImageAlt" />
					</div>
				</div>
			</div>
		</div>
		<div class="psc-cb-ipart-blocklink" v-if="Settings.rowSetting.blockBtnLinkPath">
			<a :target="Settings.rowSetting.buttonLinkTarget==2?'_blank':''" :class="Settings.rowSetting.blockClass" :href="Settings.rowSetting.blockBtnLinkPath?Settings.rowSetting.blockBtnLinkPath+(Settings.rowSetting.buttonSource==2&& Settings.rowSetting.hasOwnProperty('blockBtnLinkParameters')?Settings.rowSetting.blockBtnLinkParameters:''):'javascript:void(0)'" class="psc-cb-button">{{Settings.rowSetting.blockLabel}}</a>
		</div>
	</div>
</template>

<script>
import common from "../../mtapi/common";

export default {
  name: "Texts",
  components: {
  },
  props: {
    Settings: Object,
  },
  data() {
    return {
      style: {},
      contBackGroundColHex: "rgba(255, 255, 255, 0)",
      defaultItem: common.getDefaultItems(2),
      isActive: this.Settings.toolType && this.Settings.toolType != "" ? false : true,
      editorIndex: 0,
      openEditorFlag: false,
      mdIcon: '<i class="md-icon md-icon-font md-tab-icon md-theme-default fas fa-users"></i>',
      preset: this.Settings.toolType ? this.Settings.toolType : "",
      context: "",
      configForm: {
        mainBtnLink:this.Settings && this.Settings.mainBtnLink ? this.Settings.mainBtnLink : '',
        secondaryMainBtnLink:this.Settings && this.Settings.secondaryMainBtnLink ? this.Settings.secondaryMainBtnLink : '',
        mainImagePath: this.Settings && this.Settings.mainImagePath ? this.Settings.mainImagePath : 'https://api.pseudocode.com.au/imgsrc/',
        mainHeading:this.Settings && this.Settings.mainHeading? this.Settings.mainHeading: "Follow your dreams",
        mainHeadingContext:this.Settings && this.Settings.mainHeadingContext? this.Settings.mainHeadingContext: "We’re constantly trying to express ourselves and actualize our dreams. If you have the opportunity to play this game of life you need to appreciate every moment",
        secondMainHeadingContext:this.Settings && this.Settings.secondMainHeadingContext? this.Settings.secondMainHeadingContext: "We’re constantly trying to express ourselves and actualize our dreams. If you have the opportunity to play this game of life you need to appreciate every moment",
        showIcon:(this.Settings && this.Settings.showIcon) ||this.Settings.showIcon == 0? this.Settings.showIcon: 1,
        iconPostion: this.Settings.iconPostion || 0,
        iconSize: this.Settings.iconSize || "medium",
        openTab: this.Settings.openTab || 0,
        items:this.Settings.items,
        rowSetting:
          this.Settings && this.Settings.rowSetting
            ? this.Settings.rowSetting
            : {
                spacing: {
                  margin: {
                    t: 0,
                    r: 0,
                    b: 0,
                    l: 0,
                    ratio: "px!important",
                  },
                  padding: {
                    t: 0,
                    r: 0,
                    b: 0,
                    l: 0,
                    ratio: "px!important",
                  },
                },
                themes: {
                  // name: 'light',
                  primaryColor: "red",
                  classes: "",
                },
                background: {
                  backGroundCol: "",
                  imageParallex: false,
                  selectBackground: 0,
                  mainColor: "",
                  contBackGroundCol: "rgba(255, 255, 255, 0)",
                  backgroundStyle: 0,
                  imagePath: "",
                  imageKey: "",
                  textCol: "",
                  tabCol: "",
                  opacity: 50,
                },
              },
        toolType:
          this.Settings && this.Settings.toolType ? this.Settings.toolType : "",
        toolIndex: 2,
      },
    };
  },
  mounted() {
    this.updateValue();
  },
  methods: {
    selectPreset(i) {
      this.isActive = false;
      this.preset = i;
    },
    updateValue() {},
  },
};
</script>