
<template>
	<div class="psc-cb-ipart-container" :style="{backgroundColor:Settings.rowSetting.background.contBackGroundCol}">
		<div class="section-perspective-carousel" :class="Settings.rowSetting.width==2?'textWidth':Settings.rowSetting.width==1?'fullWidth':'inner'">
			<div  class="psc-cb-perspective-carousel-container" :class="'preset'+preset">
				<h2 class="psc-cb-perspective-carousel-title psc-cb-ipart-title" v-html="Settings.mainHeading" v-if="Settings.mainHeading&&Settings.mainHeading.length"></h2>
				<div class="psc-cb-perspective-carousel-intro psc-cb-ipart-intro" v-html="Settings.mainHeadingContext" v-if="Settings.mainHeadingContext&&Settings.mainHeadingContext.length"></div>
				<div class="psc-cb-perspective-carousel-contentbar">
					<div v-if="![2,5,6].includes(preset)" class="psc-cb-perspective-carousel-sidecontent" v-html="Settings.sideMainHeadingContext"></div>
					<div class="psc-cb-perspective-carousel-cards">
						<carousel-3d :controls-visible="true" :clickable="false" :height="Settings.cardHeight" :controlsPrevHtml="Settings.controlsType==3?arrowIcon.caretLeft:Settings.controlsType==4?arrowIcon.chevronLeft:arrowIcon.angleLeft" :controlsNextHtml="Settings.controlsType==3?arrowIcon.caretRight:Settings.controlsType==4?arrowIcon.chevronRight:arrowIcon.angleRight">
							<slide v-for="(item,i) of Settings.items" :index="i" :key="i" >
								<div class="psc-cb-perspective-carousel-card-image-container">
									<div class="psc-cb-perspective-carousel-card-image" :style="{ 'background-image': 'url(' + item.imagePath + ')' }"></div>
								</div>
								<p class="psc-cb-perspective-carousel-card-image-text" v-html="item.context"></p>
							</slide>
						</carousel-3d>
					</div>
				</div>
			</div>
	  	</div>
		<div class="psc-cb-ipart-blocklink" v-if="Settings.rowSetting.blockBtnLinkPath">
			<a :target="Settings.rowSetting.buttonLinkTarget==2?'_blank':''" :class="Settings.rowSetting.blockClass" :href="Settings.rowSetting.blockBtnLinkPath?Settings.rowSetting.blockBtnLinkPath+(Settings.rowSetting.buttonSource==2&& Settings.rowSetting.hasOwnProperty('blockBtnLinkParameters')?Settings.rowSetting.blockBtnLinkParameters:''):'javascript:void(0)'" class="psc-cb-button">{{Settings.rowSetting.blockLabel}}</a>
		</div>
  	</div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';
export default {
  name: "PerspectiveCarousel",
  components: {
    Carousel3d,
    Slide
  },
  props: {
    Settings: Object,
  },
	data() {
		return {
			arrowIcon:{
				angleLeft:'<i class="fa fa-angle-left"></i>',
				caretLeft:'<i class="fa fa-caret-left"></i>',
				chevronLeft:'<i class="fa fa-chevron-left"></i>',
				angleRight:'<i class="fa fa-angle-right"></i>',
				caretRight:'<i class="fa fa-caret-right"></i>',
				chevronRight:'<i class="fa fa-chevron-right"></i>'
			},
			style: {},
			isActive: this.Settings.toolType != undefined && this.Settings.toolType !== "" ? false : true,
			mdIcon: '<i class="md-icon md-icon-font md-tab-icon md-theme-default fas fa-users"></i>',
			preset: this.Settings.toolType != undefined && this.Settings.toolType !== "" ? this.Settings.toolType : "",
		};
	},
	methods:{
	}
 };
</script>