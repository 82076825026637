<template>
	<div class="psc-cb-ipart-container" :style="{backgroundColor:Settings.rowSetting.background.contBackGroundCol}">
    <div  class="section-embedded-video" :class=" Settings.rowSetting.width == 2 ? 'textWidth' : Settings.rowSetting.width == 1 ? 'fullWidth' : 'inner' ">
      <div class="psc-cb-embvideo-container">
        <h2 class="psc-cb-embvideo-title psc-cb-ipart-title" v-html="Settings.mainHeading"></h2>
        <div class="psc-cb-embvideo-intro psc-cb-ipart-intro" v-html="Settings.mainHeadingContext"></div>
        <div class="psc-cb-embvideo-video">
          <div v-if="Settings.embeddedLinkType==2" v-html="Settings.embeddedLink"></div>
          <iframe v-if="Settings.embeddedLinkType==3" width="560" height="315" :src="'https://www.youtube.com/embed/'+getId(Settings.externalLink)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div class="psc-cb-ipart-blocklink" v-if="Settings.rowSetting.blockBtnLinkPath">
			<a :target="Settings.rowSetting.buttonLinkTarget==2?'_blank':''" :class="Settings.rowSetting.blockClass" :href="Settings.rowSetting.blockBtnLinkPath?Settings.rowSetting.blockBtnLinkPath+(Settings.rowSetting.buttonSource==2&& Settings.rowSetting.hasOwnProperty('blockBtnLinkParameters')?Settings.rowSetting.blockBtnLinkParameters:''):'javascript:void(0)'" class="psc-cb-button">{{Settings.rowSetting.blockLabel}}</a>
		</div>
	</div>
</template>

<script>
import common from "../../mtapi/common";
export default {
  name: "Embedded-Video",
  components: {
  },
  props: {
    Settings: Object,
    licenseInfo:Object,
  },
  data() {
    return {
      style: {},
      contBackGroundColHex: "rgba(255, 255, 255, 0)",
      defaultItem: common.getDefaultItems(21),
      isActive: this.Settings.toolType && this.Settings.toolType != "" ? false : true,
      editorIndex: 0,
      openEditorFlag: false,
      preset: this.Settings.toolType ? this.Settings.toolType : "",
      editorData: '',
      editorConfig: { iMIS: { allowedBlockAttributes: ['id', 'class', 'data-test'] } },
      configForm: {
        showIcon: (this.Settings && this.Settings.showIcon) || this.Settings.showIcon == 0 ? this.Settings.showIcon : 1,
        iconPostion: this.Settings.iconPostion || 0,
        iconSize: this.Settings.iconSize || "30px",
        openTab: this.Settings.openTab || 0,
        embeddedLink:this.Settings.embeddedLink || '<iframe width="560" height="315" src="https://www.youtube.com/embed/KEQjddadQC4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
        externalLink:this.Settings.externalLink || 'https://www.youtube.com/watch?v=KEQjddadQC4&ab_channel=PseudoCode',
        embeddedLinkType:this.Settings.embeddedLinkType || 2,
        mainHeading: this.Settings && this.Settings.hasOwnProperty('mainHeading') ? this.Settings.mainHeading : common.getDefaultConfigLabel(21).mainHeading,
        mainHeadingContext: this.Settings && this.Settings.hasOwnProperty('mainHeadingContext') ? this.Settings.mainHeadingContext : common.getDefaultConfigLabel(21).mainHeadingContext,
        items:this.Settings.items,
        rowSetting: this.Settings && this.Settings.rowSetting ? this.Settings.rowSetting
            : {
                spacing: { margin: { t: 0, r: 0, b: 0, l: 0, ratio: "px", },
                          padding: { t: 0, r: 0, b: 0, l: 0, ratio: "px", },
                },
                themes: { primaryColor: "red", classes: "", },
                background: {
                  backGroundCol: "",
                  imageParallex: false,
                  selectBackground: 0,
                  mainColor: "",
                  contBackGroundCol: "rgba(255, 255, 255, 0)",
                  backgroundStyle: 0,
                  imagePath: "",
                  imageKey: "",
                  textCol: "",
                  tabCol: "",
                  opacity: 50,
                },
              },
        toolType: this.Settings && this.Settings.toolType ? this.Settings.toolType : "",
        toolIndex: 21,
      },
    };
  },
  mounted() {
    this.updateValue();
  },
  methods: {
    getId(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11)? match[2]: null;
    },
    updateValue() {
      this.$parent.$emit("configForm", this.configForm);
    },
  },
};
</script>
<style lang="scss">
    .card-shadow {
      padding: 15px 15px!important;
      margin: 15px 10px !important;
      box-shadow: 0 0 5px 0;
     
    }
</style>



