<template>
	<div class="psc-cb-ipart-container" :style="{backgroundColor:Settings.rowSetting.background.contBackGroundCol}">
		<div class="section-accordion" :class="Settings.rowSetting.width==2?'textWidth':Settings.rowSetting.width==1?'fullWidth':'inner'">
			<div class="psc-cb-accordion-container preset1">
				<div v-for="(item, i) of Settings.items" :key="i" class="psc-cb-accordion-group" :class="{'expanded':item.isOpen}">
					<a class="psc-cb-accordion-toggle" @click.prevent="loadCollepseFunc(item,i)">
						<em v-if="Settings.showIcon" class="psc-cb-accordion-icon" :class="item.icon+' '+iconClasses[Settings.iconSize]"></em>
						<span class="psc-cb-accordion-title" v-html="item.title"></span>
						<span class="psc-cb-accordion-stateicon"><em :class="getIconClass(item)"></em></span>
					</a>
					<div class="psc-cb-accordion-content" v-html="item.context"></div>
				</div>
			</div>
		</div>
    <div class="psc-cb-ipart-blocklink" v-if="Settings.rowSetting.blockBtnLinkPath">
      <a :target="Settings.rowSetting.buttonLinkTarget==2?'_blank':''" :class="Settings.rowSetting.blockClass" :href="Settings.rowSetting.blockBtnLinkPath?Settings.rowSetting.blockBtnLinkPath+(Settings.rowSetting.buttonSource==2 && Settings.rowSetting.hasOwnProperty('blockBtnLinkParameters')?Settings.rowSetting.blockBtnLinkParameters:''):'javascript:void(0)'" class="psc-cb-button">{{Settings.rowSetting.blockLabel}}</a>
    </div>
 	</div>
</template>

<script>
import common from "../../mtapi/common";

export default {
  name: "Accordion",
  components: {},
  props: {
    Settings: Object,
  },
  data() {
    return {
      iconClasses: common.getClassName(),
      componentKey: 0,
      defaultItem: common.getDefaultItems(10),
      isActive:
        this.Settings.toolType && this.Settings.toolType != "" ? false : true,
      preset: this.Settings.toolType ? this.Settings.toolType : "",
      classes: [],
      configForm: {
        collapse_inactive: this.Settings.collapse_inactive || 0,
        expand: this.Settings.expand || 1,
        collapse_icon_position: this.Settings.collapse_icon_position || 1,
        expandIcon: this.Settings.expandIcon || "fas fa-plus",
        collapseIcon: this.Settings.collapseIcon || "fas fa-minus",
        swapPosition: this.Settings && this.Settings.swapPosition ? this.Settings.swapPosition : "",
        copyAdd: this.Settings && this.Settings.copyAdd ? this.Settings.copyAdd : 0,
        mainHeading: this.Settings && this.Settings.mainHeading ? this.Settings.mainHeading : "Follow your dreams",
        mainHeadingContext: this.Settings && this.Settings.mainHeadingContext ? this.Settings.mainHeadingContext : "We’re constantly trying to express ourselves and actualize our dreams. If you have the opportunity to play this game of life you need to appreciate every moment",
        showIcon: (this.Settings && this.Settings.showIcon) || this.Settings.showIcon == 0 ? this.Settings.showIcon : 1,
        iconPostion: this.Settings.iconPostion || 0,
        iconSize: this.Settings.iconSize || "medium",
        openTab: this.Settings.openTab || 0,
        items: this.Settings && this.Settings.items ? this.Settings.items
            : common.getItems(
                this.Settings.toolIndex,
                this.Settings.toolType,
                1
              ),
        rowSetting:
          this.Settings && this.Settings.rowSetting
            ? this.Settings.rowSetting
            : {
                spacing: {
                  margin: {
                    t: 0,
                    r: 0,
                    b: 0,
                    l: 0,
                    ratio: "px!important",
                  },
                  padding: {
                    t: 0,
                    r: 0,
                    b: 0,
                    l: 0,
                    ratio: "px!important",
                  },
                },
                themes: {
                  primaryColor: "red",
                  classes: "",
                },
                background: {
                  backGroundCol: "",
                  imageParallex: false,
                  selectBackground: 0,
                  mainColor: "",
                  contBackGroundCol: "rgba(255, 255, 255, 0)",
                  backgroundStyle: 0,
                  imagePath: "",
                  imageKey: "",
                  textCol: "",
                  tabCol: "",
                  opacity: 50,
                },
              },
        toolType:
          this.Settings && this.Settings.toolType ? this.Settings.toolType : "",
        toolIndex: 1,
      },
    };
  },
  mounted() {
    this.updateValue();
  },

  watch: {
    "Settings.expand": function (newVal, oldVal) {
      if (newVal) {
        this.forceRerender();
      }
    },
    "Settings.collapse_inactive": function (newVal, oldVal) {
      if (newVal) {
        this.forceRerender();
      }
    },
  },

  methods: {
    getIconClass(item){
      return item.isOpen?this.Settings.collapseIcon:this.Settings.expandIcon
    },
     loadCollepseFunc(item,index){
      if(!this.Settings.collapse_inactive && this.Settings.expand!=2){
        this.Settings.items.forEach((ele,i) => {
          if(i==index){
            ele.isOpen=!item.isOpen
          }else{
            ele.isOpen=false
          }
        });
      }else{
        item.isOpen=!item.isOpen
      }
		item.stateicon	=	(item.isOpen)?this.Settings.collapseIcon:this.Settings.expandIcon;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    getCollapseTitles(items) {
      let titles = [];
      items.forEach((element) => {
        titles.push(element.title);
      });
      return titles;
    },
    getIcon(i) {
      if (document.getElementById("expandAll" + i)) {
        if (
          document
            .getElementById("expandAll" + i)
            .getAttribute("aria-expanded") == "true"
        ) {
          this.configForm.items[i].classes = this.configForm.expandIcon;
        } else {
          this.configForm.items[i].classes = this.configForm.collapseIcon;
        }
      }
    },
    selectPreset(i) {
      this.isActive = false;
      this.preset = i;
    },
    updateValue() {
      this.$parent.$emit("configForm", this.configForm);
    },
    getAccordionIcons(items) {
      let icons = [];
      items.forEach((element) => {
        icons.push(element.icon);
      });
      return icons;
    },
    getAccordionTitles(items) {
      let titles = [];
      items.forEach((element) => {
        titles.push(element.title);
      });
      return titles;
    },
  },
};
</script>