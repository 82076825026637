<template>
<div class="psc-cb-ipart-container" :style="{backgroundColor:Settings.rowSetting.background.contBackGroundCol}">
  <div class="section-counter" :class="Settings.rowSetting.width == 2? 'textWidth': Settings.rowSetting.width == 1? 'fullWidth' : 'inner'" >
    <div class="psc-cb-counters-container">
      <h2 v-if="Settings.mainHeading&&Settings.mainHeading.length" class="psc-cb-counters-title psc-cb-ipart-title" v-html="Settings.mainHeading"></h2>
      <div v-if="Settings.mainHeadingContext&&Settings.mainHeadingContext.length" class="psc-cb-counters-intro psc-cb-ipart-intro" v-html="Settings.mainHeadingContext"></div>
      <ul class="psc-cb-counters-counters" >
        <li v-for="(item, index) of Settings.toolType ? Settings.items : defaultItem[1]" :key="index">
          <div class="psc-cb-counters-icon"><i :class="item.icon"></i></div>
          <div class="psc-cb-counters-counter">
            <span class="psc-cb-counters-count timer" :data-to="item.counterType==2?item.hardCodedCounter:item.selectedIqaColumn" data-speed="1500"></span>
            <span class="psc-cb-counters-suffix" v-html="item.Suffix"></span>
          </div>
          <div class="psc-cb-counters-title" v-html="item.title"></div>
        </li>
      </ul>
    </div>
    <div class="psc-cb-ipart-blocklink" v-if="Settings.rowSetting.blockBtnLinkPath">
			<a :target="Settings.rowSetting.buttonLinkTarget==2?'_blank':''" :class="Settings.rowSetting.blockClass" :href="Settings.rowSetting.blockBtnLinkPath?Settings.rowSetting.blockBtnLinkPath+(Settings.rowSetting.buttonSource==2&& Settings.rowSetting.hasOwnProperty('blockBtnLinkParameters')?Settings.rowSetting.blockBtnLinkParameters:''):'javascript:void(0)'" class="psc-cb-button">{{Settings.rowSetting.blockLabel}}</a>
		</div>
  </div>
</div>
</template>

<script>
import common from "../../mtapi/common";

export default {
  components: {},
  props: {
    Settings: Object,
    licenseInfo: Object,
    defaultEventData: Array,
  },
  data() {
    return {
      eventData: [],
      style: {},
      contBackGroundColHex: "rgba(255, 255, 255, 0)",
      defaultItem: common.getDefaultCounterItems(16),
      isActive: this.Settings.toolType && this.Settings.toolType != "" ? false : true,
      editorIndex: 0,
      openEditorFlag: false,
      preset: this.Settings.toolType ? this.Settings.toolType : "",
      configForm: {
       mainHeading: this.Settings && this.Settings.hasOwnProperty('mainHeading') ? this.Settings.mainHeading : common.getDefaultConfigLabel(16).mainHeading,
        mainHeadingContext: this.Settings && this.Settings.hasOwnProperty('mainHeadingContext') ? this.Settings.mainHeadingContext : common.getDefaultConfigLabel(16).mainHeadingContext,
        items: this.Settings && this.Settings.items ? this.Settings.items
            : [
                {
                  title: "Member",
                  icon: "fas fa-users",
                  context: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
                },
                {
                  title: "Non-Member",
                  icon: "far fa-smile",
                  context: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
                },
                {
                  title: "Member",
                  icon: "fas fa-users",
                  context: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
                },
                {
                  title: "Non-Member",
                  icon: "far fa-smile",
                  context: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
                },
                {
                  title: "Member",
                  icon: "fas fa-users",
                  context: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
                },
                {
                  title: "Non-Member",
                  icon: "far fa-smile",
                  context: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
                }
              ],
        rowSetting: this.Settings && this.Settings.rowSetting ? this.Settings.rowSetting
            : {
                spacing: {
                  margin: { t: 0, r: 0, b: 0, l: 0, ratio: "px!important", },
                  padding: { t: 0, r: 0, b: 0, l: 0, ratio: "px!important", },
                },
                themes: { primaryColor: "red", classes: "", },
                background: {
                  backGroundCol: "",
                  imageParallex: false,
                  selectBackground: 0,
                  mainColor: "",
                  contBackGroundCol: "rgba(255, 255, 255, 0)",
                  backgroundStyle: 0,
                  imagePath: "",
                  imageKey: "",
                  textCol: "",
                  tabCol: "",
                  opacity: 50,
                },
              },
        toolType: this.Settings && this.Settings.toolType ? this.Settings.toolType : "",
        toolIndex: 16,
      },
    };
  },
  mounted() {
   this.reloadcounterFun();
  },
  methods: {
     reloadcounterFun(){
       (function ($) {
      $.fn.countTo = function (options) {
        options = options || {};
        return $(this).each(function () {
          // set options for current element
          var settings = $.extend( {}, $.fn.countTo.defaults, { from: $(this).data("from"), to: $(this).data("to"), speed: $(this).data("speed"), refreshInterval: $(this).data("refresh-interval"), decimals: $(this).data("decimals"), }, options );
          // how many times to update the value, and how much to increment the value on each update
          var loops      = Math.ceil(settings.speed / settings.refreshInterval),
              increment  = (settings.to - settings.from) / loops;
          // references & variables that will change with each update
          var self     = this,
            $self      = $(this),
            loopCount  = 0,
            value      = settings.from,
            data       = $self.data("countTo") || {};
          $self.data("countTo", data);
          // if an existing interval can be found, clear it first
          if (data.interval) {
            clearInterval(data.interval);
          }
          data.interval = setInterval(updateTimer, settings.refreshInterval);
          // initialize the element with the starting value
          render(value);
          function updateTimer() {
            value += increment;
            loopCount++;
            render(value);
            if (typeof settings.onUpdate == "function") {
              settings.onUpdate.call(self, value);
            }
            if (loopCount >= loops) {
              $self.removeData("countTo");
              clearInterval(data.interval);
              value = settings.to;
              if (typeof settings.onComplete == "function") {
                settings.onComplete.call(self, value);
              }
            }
          }
          function render(value) {
            var formattedValue = settings.formatter.call(self, value, settings);
            $self.html(formattedValue);
          }
        });
      };
      $.fn.countTo.defaults = {
        from: 0, // the number the element should start at
        to: 0, // the number the element should end at
        speed: 1000, // how long it should take to count between the target numbers
        refreshInterval: 100, // how often the element should be updated
        decimals: 0, // the number of decimal places to show
        formatter: formatter, // handler for formatting the value before rendering
        onUpdate: null, // callback method for every time the element is updated
        onComplete: null, // callback method for when the element finishes updating
      };
      function formatter(value, settings) {
        return value.toFixed(settings.decimals);
      }
    })(jQuery);
    jQuery(function ($) {
      // custom formatting example
      $(".count-number py-2").data("countToOptions", {
        formatter: function (value, options) {
          return value
            .toFixed(options.decimals)
            .replace(/\B(?=(?:\d{3})+(?!\d))/g, ",");
        },
      });

      // start all the timers
      $(".timer").each(count);
      function count(options) {
        var $this = $(this);
        options = $.extend(
          {},
          options || {},
          $this.data("countToOptions") || {}
        );
        $this.countTo(options);
      }
    });
    },
    getOpacity() {
      //   let op={background-color: #676767}'
      //   return op
    },

  },
};
</script>
