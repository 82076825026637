<template>
	<div class="psc-cb-root" v-if="ready" :class="getToolIndexClass()" >
		<DisplaySetting
			:id="'ID' + ContentItemKey"
			v-bind:Settings="config.Settings"
			v-bind:SortOrder="ContentItemKey"
			v-bind:sortIndex="config.SortOrder"
		/>
	</div>
</template>
<script>
import mtapi from "./mtapi";
import DisplaySetting from "./displayBuddy/DisplaySetting.vue";
let dynamicClassName = {
	0: 'section-tabset',
	1: 'section-accordion',
	2: 'section-text',
	3: 'section-testimonial',
	4: 'section-cards',
	5: 'section-header',
	6: '',
	7: 'section-teams',
	8: 'section-pricing',
	9: 'section-features',
	10: 'section-flipbox',
	11: '',
	12: '',
	13: 'section-event',
	14: 'section-product',
	15: 'gallery-style',
	16: 'section-counter',
	17: '',
	18: 'section-badges',
	19: 'section-marquee',
	20: 'section-perspective-carousel',
}
export default {
	name: "display",
	props: ["ContentKey", "ContentItemKey"],
	components: { DisplaySetting, },
	data(){
		return{
			queryResult: [],
			imageOBject: {},
			ready: false,
			externalfilesloaded: false,
			dev: (new URLSearchParams(window.location.search)).get('showConsole')??false, //in URL: &showConsole=true
			config: { Settings: {}, },
		};
	},
	async created(){
		let response = await mtapi.axios.get( `/api/ContentItem?ContentKey=${this.ContentKey}&ContentItemKey=${this.ContentItemKey}`);
		if( response?.data?.$type?.startsWith( `Asi.Soa.Core.DataContracts.PagedResult`)){
			this.config = response?.data?.Items?.$values[0]?.Data;
			if ( this.config && this.config.Settings && this.config.Settings.resultFormat) {
				this.config.Settings.perPage ? this.config.Settings.perPage : 9;
				for (let index = 1; index <= 4; index++) {
					this.config.Settings.resultFormat["section" + index] = this.unjsonEscape(this.config.Settings.resultFormat["section" + index]);
					if(index <=3){
						this.config.Settings.resultFormat["sec" + index] = this.unjsonEscape(this.config.Settings.resultFormat["sec" + index]);
					} 
				}
				this.config.Settings.resultFormat["sectionImage"] = this.unjsonEscape(this.config.Settings.resultFormat["sectionImage"]);
			}
			this.config.Settings.mainHeading = this.unjsonEscape( this.config.Settings.mainHeading);
			if ( this.config.Settings && [2, 3, 4, 5, 7, 9, 10, 12, 16, 18, 19, 20, 21, 22, 23].includes(this.config.Settings.toolIndex)) {
				this.config.Settings.mainHeadingContext = this.unjsonEscape( this.config.Settings.mainHeadingContext);
				if ( this.config.Settings && [  20].includes(this.config.Settings.toolIndex)) {
					this.config.Settings.sideMainHeadingContext = this.unjsonEscape( this.config.Settings.sideMainHeadingContext);
				}
				if ( this.config.Settings && [  21].includes(this.config.Settings.toolIndex)) {
					this.config.Settings.embeddedLink = this.unjsonEscape( this.config.Settings.embeddedLink);
				}
				if(this.config.Settings.secondMainHeadingContext){
					this.config.Settings.secondMainHeadingContext = this.unjsonEscape(this.config.Settings.secondMainHeadingContext);
				}
			}
		    // this.config.Settings.rowSetting.inlineThemeCss = this.unjsonEscape( this.config.Settings.rowSetting.inlineThemeCss);


			if ( this.config && this.config.Settings && this.config.Settings.items && this.config.Settings.items.length > 0)
				this.config.Settings.items.forEach((element) => {
					 element.title = this.unjsonEscape(element.title);
					 element.context = this.unjsonEscape(element.context);
					 element.secondContext = this.unjsonEscape(element.secondContext);
					 element.b_title = this.unjsonEscape(element.b_title);
               		 element.b_itemContext = this.unjsonEscape(element.b_itemContext);
               		 element.b_context = this.unjsonEscape(element.b_context);
				});
			if (this.config && this.config.Settings && this.config.Settings.website) {
				this.getThemeList(this.config.Settings.website);
			} 
			else {
				document.documentElement.style.setProperty( "--someVariable", this.config && this.config.Settings && this.config.Settings.primaryColor ? this.config.Settings.primaryColor : "#676767" );
			}
		} 
		else {
			this.config = response?.data;
			if ( this.config && this.config.Settings && this.config.Settings.items.length > 0 )
				this.config.Settings.items.forEach((element) => { 
					element.title = this.unjsonEscape(element.title); 
					element.context = this.unjsonEscape(element.context);
					element.b_title = this.unjsonEscape(element.b_title);
               		element.b_itemContext = this.unjsonEscape(element.b_itemContext); 
               		element.b_context = this.unjsonEscape(element.b_context); 
				});
			if (this.config && this.config.Settings && this.config.Settings.website) {
				this.getThemeList(this.config.Settings.website);
			} else {
				document.documentElement.style.setProperty( "--someVariable", this.config.Settings.primaryColor ? this.config.Settings.primaryColor : "#676767" );
			}
		}
		if(!(this.config && this.config.Settings && this.config.Settings.website) || (this.config && this.config.Settings && this.config.Settings.website && !this.config && this.config.Settings && this.config.Settings.themePath)){
			this.config.Settings.themePath = 'https://pseudo-imis.s3.ap-southeast-2.amazonaws.com/content-buddy-theme/default-content-buddy-theme.css'
		}
		
		await this.checkAndLoadStylesheets();
		this.ready = true;
	},
	methods: {
		getToolIndexClass(){
			return ('contentBuddy' + this.ContentItemKey + ' ' + dynamicClassName[this.config.Settings.toolIndex])
		},
		async getDefalultTheme(){
			var requestOptions = {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(licence)
			};
			const response = await fetch("https://xq14xfyrpe.execute-api.us-east-2.amazonaws.com/validateLicenseInfo/validateLicenseInfo",requestOptions);
			const data = await response.json();
			this.licenseInfo = data.result;
		},
		async checkAndLoadStylesheets(){
			const thispageurl			=	window.location.href;
			let timestamp				=	this.getTimestamp();
			let loadedtime				=	this.getCBData('cb-loaded-time');
			let loadedpage				=	this.getCBData('cb-loaded-url');
			if(!loadedpage||timestamp>loadedtime){
				this.setCBData('cb-loaded-time',timestamp+60);
				this.setCBData('cb-loaded-url',thispageurl);
				this.externalfilesloaded=	true;
			}
			else{
				// if(this.dev)console.log('External files loaded');
			}
		}
		,getCBData(k){
			return localStorage.getItem(k);
		}
		,setCBData(k,v){
			localStorage.setItem(k,v);
		}
		,getFileName(url){
			return url.replace(/^.*[\\\/]/, '')
		}
		,getTimestamp(){
			return Math.floor(Date.now()/1000);
		}
		,isLoaded(filename){
			let stylesheets		=	document.getElementsByTagName('link');
			for(let i=0;i<stylesheets.length;i++){
				let linktag		=	stylesheets[i];
				let href		=	linktag.href;
				let linkname	=	this.getFileName(href);
				return (filename==linkname)?true:false;
			}
		},
		getImage(partyId) {
			return mtapi.axios.get(`/api/PartyImage?PartyId=${partyId}`).then((resp) => {
				if ( resp.status === 200 && resp?.data && resp?.data?.Items && resp?.data?.Items.$values ) {
					if ( resp?.data?.Items.$values[0] && resp?.data?.Items.$values[0].Purpose != "" ) {
					const base64Blob = new mtapi.ImisEntity( resp?.data?.Items.$values[0] )?.Image?.$value;
					const blob = mtapi.base64ToBlob(base64Blob, "image/jpeg");
					let imagess = URL.createObjectURL(blob);
					let data = { id: partyId, image: imagess, };
					return data;
					} else {
					let data = { id: partyId, image: this.marcImg, };
					return data;
					}
				}
			});
		},
		pagination(totalcount) {
			var pages = 0;
			pages = totalcount / 10;
			this.totalpages = Math.round(pages);
		},
		unjsonEscape(str) {
			if (typeof str === "string" && str.length > 0)
				str = str .replace(/&amp;/g, "&") .replace(/&lt;/g, "<") .replace(/&gt;/g, ">") .replace(/&quot;/g, '"');
			return str;
		},
		async getThemeList(web) {
			let emitVariable = { website: web, primaryCol: "", };
			let response = await mtapi.axios.get( `/api/iqa?QueryName=$/Pseudocode/content-buddy/List of Themes&parameter=` + web );
			if ( response && response.data && response.data.Items && response.data.Items.$values && response.data.Items.$values.length && response.data.Items.$values.length > 0 ) {
					response.data.Items.$values[0].Properties.$values.forEach((element, index) => {
						if (element.Name == "Styles") {
							if (element.Value) {
								let key = JSON.parse(element.Value);
								if (key.hasOwnProperty("primary-colour")) {
									this.config.Settings.primaryCol = key["primary-colour"];
									document.documentElement.style.setProperty("--someVariable",key["primary-colour"]);
								}
							}
						}
					}
				);
			}
		},
	},
};
</script>
