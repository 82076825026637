<template>
  <div class="psc-cb-ipart-container" :style="{backgroundColor:Settings.rowSetting.background.contBackGroundCol}">
		<div class="section-products" :class="Settings.rowSetting.width==2?'textWidth':Settings.rowSetting.width==1?'fullWidth':'inner'">
			<div class="psc-cb-products-container" :class="`preset${preset}`">
				<div class="psc-cb-titlebar">
					<h2 class="psc-cb-products-title" v-html="Settings.title" v-if="Settings.title&&Settings.title.length"></h2>
					<a v-if="Settings.btnVersionKey&&Settings.btnVersionKey.length" :href="Settings.btnVersionKey" v-html="Settings.btnLabel" class="psc-cb-button"></a>
				</div>
				<ul v-if="preset==1" class="psc-cb-products-list">
					<li v-for="(item,index) of eventData && eventData.length>0 ? eventData : 3" :key="index" class="psc-cb-products-product">
						<div class="dynamichead" v-html="getDynamicSection1(Settings.resultFormat.sec1, item)"></div>
						<div class="dynamicbody" v-html="getDynamicSection2(Settings.resultFormat.sec2, item)"></div>
						<div class="dynamicfoot" v-html="getDynamicSection2(Settings.resultFormat.sec3, item)"></div>
					</li>
				</ul>
				<carousel v-if="preset==2" class="psc-cb-products-slider"
					:per-page="1" :navigationEnabled="true" :mouse-drag="false" :paginationPadding="3"
					indicators="hover" :navigationNextLabel="`<i class='fas fa-angle-right'></i>`"
					:navigationPrevLabel="`<i class='fas fa-angle-left'></i>`"
					:navigationClickTargetSize="8"
					:paginationActiveColor="Settings.primaryColor ? Settings.primaryColor : 'var(--brand-2-bg)'"
					:paginationColor=" Settings.primaryColor? Settings.primaryColor: 'var(--brand-2-bg)'"
					:autoplay="Settings.autoScroll" :autoplayTimeout="Settings.scrollTiming" :loop="true"
					:mouseDrag="true" :speed="Settings.sliderSpeed">
					<template v-for="(item, index) of eventData.length > 0 ? eventData : 3">
						<slide :key="index">
							<div class="psc-cb-products-product">
								<div class="dynamichead" v-html="getDynamicSection1(configForm.resultFormat.sec1, item)"></div>
								<div class="dynamiccontentgroup">
									<div class="dynamicbody" v-html="getDynamicSection2(configForm.resultFormat.sec2, item)"></div>
									<div class="dynamicfoot" v-html="getDynamicSection2(configForm.resultFormat.sec3, item)"></div>
								</div>
							</div>
						</slide>
					</template>
				</carousel>
				<carousel v-if="preset==3" 
          			class="psc-cb-products-carousel"
					:per-page="1" 
					:navigationEnabled="true" 
					:mouse-drag="false" 
					:indicators="'hover'"
					:paginationPadding="3" :perPage="3"
					:navigationNextLabel="`<i class='fas fa-angle-right '></i>`"
					:navigationPrevLabel="`<i class='fas fa-angle-left'></i>`"
					:navigationClickTargetSize="8"
					:paginationActiveColor="Settings.primaryColor ? Settings.primaryColor : 'var(--brand-2-bg)'"
					:paginationColor=" Settings.primaryColor? Settings.primaryColor: 'var(--brand-2-bg)'"
					:autoplay="Settings.autoScroll" 
					:autoplayTimeout="Settings.scrollTiming" 
					:loop="true"
					:mouseDrag="true" 
          			:speed="Settings.sliderSpeed">
					<template v-for="(item, index) of eventData.length > 0 ? eventData : 6">
						<slide :key="index">
							<div class="psc-cb-products-product">
								<div class="dynamichead" v-html="getDynamicSection1(configForm.resultFormat.sec1, item)"></div>
								<div class="dynamicbody" v-html="getDynamicSection2(configForm.resultFormat.sec2, item)"></div>
								<div class="dynamicfoot" v-html="getDynamicSection2(configForm.resultFormat.sec3, item)"></div>
							</div>
						</slide>
					</template>
				</carousel>
			</div>
		</div>
    <div class="psc-cb-ipart-blocklink" v-if="Settings.rowSetting.blockBtnLinkPath">
      <a :target="Settings.rowSetting.buttonLinkTarget==2?'_blank':''" :class="Settings.rowSetting.blockClass" :href="Settings.rowSetting.blockBtnLinkPath?Settings.rowSetting.blockBtnLinkPath+(Settings.rowSetting.buttonSource==2&& Settings.rowSetting.hasOwnProperty('blockBtnLinkParameters')?Settings.rowSetting.blockBtnLinkParameters:''):'javascript:void(0)'" class="psc-cb-button">{{Settings.rowSetting.blockLabel}}</a>
    </div>
	</div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import common from "../../mtapi/common";
import moment from "moment";
import mtapi from "../../mtapi";

// import { BlogCard } from "@/components";

export default {
  components: {
    Carousel,
    Slide,
    // BlogCard,
  },
  props: {
    Settings: Object,
  },
  data() {
    return {
      getReady: false,
      eventData: [],
      style: {},
      contBackGroundColHex: "rgba(255, 255, 255, 0)",
      defaultItem: common.getDefaultItems(4),
      isActive:
        this.Settings.toolType && this.Settings.toolType != "" ? false : true,
      editorIndex: 0,
      openEditorFlag: false,
      preset: this.Settings.toolType ? this.Settings.toolType : "",
      defaultHtml: `<h6 class="card-category optionalIcon">CON | Intermediate</h6>
                    <h3 class="card-title mt-4">Bookkeeprs Recording Workshop</h3>
                    <div class="prices mt-4">
                      <p><span class="type"><strong>Member</strong></span><span class="price"><strong>$80</strong></span></p>
                      <p><span class="type">Non-Member</span><span class="price">$90</span></p>
                    </div>`,
      configForm: {
        resultFormat:
          this.Settings && this.Settings.resultFormat
            ? this.Settings.resultFormat
            : {},
        rowSetting:
          this.Settings && this.Settings.rowSetting
            ? this.Settings.rowSetting
            : {
                spacing: {
                  margin: {
                    t: 0,
                    r: 0,
                    b: 0,
                    l: 0,
                    ratio: "px!important",
                  },
                  padding: {
                    t: 0,
                    r: 0,
                    b: 0,
                    l: 0,
                    ratio: "px!important",
                  },
                },
                themes: {
                  // name: 'light',
                  primaryColor: "red",
                  classes: "",
                },
                background: {
                  backGroundCol: "",
                  imageParallex: false,
                  selectBackground: 0,
                  mainColor: "",
                  contBackGroundCol: "rgba(255, 255, 255, 0)",
                  backgroundStyle: 0,
                  imagePath: "",
                  imageKey: "",
                  textCol: "",
                  tabCol: "",
                  opacity: 50,
                },
              },
        toolType:
          this.Settings && this.Settings.toolType ? this.Settings.toolType : "",
        toolIndex: 14,
      },
    };
  },
  mounted() {
    this.getDocumentVersionKey(this.Settings.iqaDocumentVersionKey, 0);
    var items = document.getElementsByClassName("VueCarousel-dot");
    setTimeout(() => {
      for (let i = 0; i < items.length; i++) {
        items[i].addEventListener("click", function (e) {
          e.preventDefault();
        });
      }
    }, 1000);
  },
  methods: {
    openMoreButtonLink() {
      if (this.Settings.btnLinkPath != "") {
        let link = this.Settings.queryString
          ? "?" + this.Settings.queryString
          : "";
        window.open(this.Settings.btnLinkPath + link);
      }
    },
    async getDocumentVersionKey(value, index) {
      if (value) {
        if (index == 0) {
          this.respData = [];
        } else if (index == 1) {
          this.PostCodeData = [];
        }
        let response = await mtapi.axios.get(
          `/api/iqa?QueryDocumentVersionKey=${value}&Limit=${this.Settings.perPage}`
        );
        if (response.status === 200) {
          response.data.Items.$values[0].Properties.$values.forEach((val) => {
            if (index == 0) {
              if (!this.respData.includes(val.Name)) {
                this.respData.push(val.Name);
              }
            } else if (index == 1) {
              this.PostCodeData.push(val.Name);
            }
          });

          this.eventData = [];
          response.data.Items.$values.forEach((ele) => {
            let dataObj = {};
            ele.Properties.$values.forEach((item) => {
              dataObj[item.Name] = item.Value;
            });
            this.eventData.push(Object.assign({}, dataObj));
          });
          this.getReady = true;
          for (let i = 0; i < items.length; i++) {
            items[i].addEventListener("click", function (e) {
              e.preventDefault();
            });
          }
        }
      }
    },
    getDynamicSection1(value, item) {
      let tempHTML = "";
      value.split("[[").forEach((values) => {
        if (values.includes("]]")) {
          let key = values.split("]]")[0];
          if (item[key] == "") {
            value = value.replace(
              "[[" + key + "]]",
              require("@/assets/img/calendar.png")
            );
          } else {
            value = value.replace(
              "[[" + key + "]]",
              window.location.origin + "/" + item[key]
            );
          }
        }
      });
      tempHTML = value;
      return tempHTML;
    },
    getDynamicSection2(value, item) {
      let tempHTML = "";
      if (item) {
        value.split("[[").forEach((values) => {
          if (values.includes("]]")) {
            let key = values.split("]]")[0];
            if (typeof item[key] == "object") {
              // value = value.replace("[[" + key + "]]", item[key]['$value']);
              if (
                typeof item[key]["$value"] != "number" &&
                this.isDate(item[key]["$value"])
              ) {
                value = value.replace(
                  "[[" + key + "]]",
                  this.DateFormat(item[key]["$value"])
                );
              } else {
                value = value.replace("[[" + key + "]]", item[key]["$value"]);
              }
            } else {
              if (typeof item[key] != "number" && this.isDate(item[key])) {
                value = value.replace(
                  "[[" + key + "]]",
                  this.DateFormat(item[key])
                );
              } else {
                value = value.replace("[[" + key + "]]", item[key]);
              }
            }
          }
        });
        tempHTML = value;
      }
      return tempHTML;
    },
    getDynamicSection3(value, item) {
      let tempHTML = "";
      value.split("[[").forEach((values) => {
        if (values.includes("]]")) {
          let key = values.split("]]")[0];
          if (typeof item[key] != "number" && this.isDate(item[key])) {
            value = value.replace(
              "[[" + key + "]]",
              this.DateFormat(item[key])
            );
          } else {
            value = value.replace("[[" + key + "]]", item[key]);
          }
        }
      });
      tempHTML = value;
      return tempHTML;
    },
    DateFormat: function (date) {
      return moment(date, "YYYY-MM-DD").format("Do MMM YYYY");
    },
    isDate(date) {
      if(date==undefined) return false
      return	date.match(/^(\d{4})\-(\d{2})\-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/);
      // return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
    },
    getHeightCard() {
      let height = {
        height:
          this.Settings && this.Settings.cardHeight
            ? this.Settings.cardHeight + "px"
            : "100px",
        overflow: "hidden",
      };
      return height;
    },
    getVHeightCard() {
      let height = {
        height:
          this.Settings && this.Settings.cardHeight
            ? this.Settings.cardHeight + "px"
            : "150px",
        overflow: "hidden",
      };
      return height;
    },
    getLineCard() {
      let height = {
        "-webkit-line-clamp":
          this.Settings && this.Settings.cardLines
            ? this.Settings.cardLines
            : 4,
      };

      return height;
    },
  },
};
</script>
