<template>
	<div class="psc-cb-ipart-container" :style="{backgroundColor:Settings.rowSetting.background.contBackGroundCol}">
    <div class="section-testimonial" :class="Settings.rowSetting.width==2?'textWidth':Settings.rowSetting.width==1?'fullWidth':'inner'">
			<div class="psc-cb-testimonial-container" :class="`preset${preset}`">
				<h2 class="psc-cb-testimonial-title psc-cb-ipart-title" v-html="Settings.mainHeading" v-if="Settings.mainHeading&&Settings.mainHeading.length"></h2>
				<div class="psc-cb-testimonial-description psc-cb-ipart-intro" v-html="Settings.mainHeadingContext" v-if="Settings.mainHeadingContext&&Settings.mainHeadingContext.length"></div>
				<div class="psc-cb-testimonial-cards" :class="rowClasses[Settings.cardsPerRow]">
					<div
						v-for="(item, index) of Settings.items"
						:key="index"
						class="psc-cb-testimonial-card"
					>
						<div class="psc-cb-testimonial-card-quote" v-html="item.context"></div>
						<div class="psc-cb-testimonial-card-name" v-if="Settings.toolType!=2" v-html="item.title"></div>
						<div class="psc-cb-testimonial-card-role" v-if="Settings.toolType!=2" v-html="item.optionalHeading"></div>
						<div class="psc-cb-testimonial-card-image" v-if="Settings.toolType!=2">
							<div :style="{backgroundImage: 'url('+item.imagePath+')'}"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
    <div class="psc-cb-ipart-blocklink" v-if="Settings.rowSetting.blockBtnLinkPath">
			<a :target="Settings.rowSetting.buttonLinkTarget==2?'_blank':''" :class="Settings.rowSetting.blockClass" :href="Settings.rowSetting.blockBtnLinkPath?Settings.rowSetting.blockBtnLinkPath+(Settings.rowSetting.buttonSource==2&& Settings.rowSetting.hasOwnProperty('blockBtnLinkParameters')?Settings.rowSetting.blockBtnLinkParameters:''):'javascript:void(0)'" class="psc-cb-button">{{Settings.rowSetting.blockLabel}}</a>
		</div>
	</div>
</template>

<script>
import common from "../../mtapi/common";
export default {
  name: "Testimonial",
  components: {},
  props: {
    Settings: Object,
  },
  data() {
    return {
      rowClasses:common.getRowClasses(),
      isActive: (this.Settings.toolType || this.Settings.toolType == 0) && this.Settings.toolType != "" ? false : true,
      preset: this.Settings.toolType || this.Settings.toolType == 0 ? this.Settings.toolType : "",
      configForm: {
        swapPosition:
          this.Settings && this.Settings.swapPosition
            ? this.Settings.swapPosition
            : "",
        copyAdd:
          this.Settings && this.Settings.copyAdd ? this.Settings.copyAdd : 0,
        mainHeading:
          this.Settings && this.Settings.mainHeading
            ? this.Settings.mainHeading
            : "Follow your dreams",
        mainHeadingContext:
          this.Settings && this.Settings.mainHeadingContext
            ? this.Settings.mainHeadingContext
            : "We’re constantly trying to express ourselves and actualize our dreams. If you have the opportunity to play this game of life you need to appreciate every moment",
        showIcon:
          (this.Settings && this.Settings.showIcon) ||
          this.Settings.showIcon == 0
            ? this.Settings.showIcon
            : 1,
        iconPostion: this.Settings.iconPostion || 0,
        iconSize: this.Settings.iconSize || "medium",
        openTab: this.Settings.openTab || 0,
        items:this.Settings.items,
        rowSetting:
          this.Settings && this.Settings.rowSetting
            ? this.Settings.rowSetting
            : {
                spacing: {
                  margin: {
                    t: 0,
                    r: 0,
                    b: 0,
                    l: 0,
                    ratio: "px!important",
                  },
                  padding: {
                    t: 0,
                    r: 0,
                    b: 0,
                    l: 0,
                    ratio: "px!important",
                  },
                },
                themes: {
                  // name: 'light',
                  primaryColor: "red",
                  classes: "",
                },
                background: {
                  backGroundCol: "",
                  imageParallex: false,
                  selectBackground: 0,
                  mainColor: "",
                  contBackGroundCol: "rgba(255, 255, 255, 0)",
                  backgroundStyle: 0,
                  imagePath: "",
                  imageKey: "",
                  textCol: "",
                  tabCol: "",
                  opacity: 50,
                },
              },
        toolType:
          this.Settings && this.Settings.toolType ? this.Settings.toolType : "",
        toolIndex: 3,
      },
    };
  },
  mounted() {
    this.updateValue();
  },
  methods: {
    sectionTestimonials(image) {
      return {
        backgroundImage: `url(${image})`,
      };
    },
    selectPreset(i) {
      this.isActive = false;
      this.preset = i;
    },
    updateValue() {},
    getHeightCard(){
    
      let height= {height: this.Settings && this.Settings.cardHeight  ? this.Settings.cardHeight+'px' : '100px',
                  overflow: 'hidden'
                  }
                 
      return height;
    },
    getLineCard(){
      let height= {
                  "-webkit-line-clamp": this.Settings && this.Settings.cardLines  ? this.Settings.cardLines : 4
                  }
                 
      return height;
    }
  },
};
</script>
