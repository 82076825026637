<template>
	<div class="psc-cb-ipart-container" :style="{backgroundColor:saveSettings.rowSetting.background.contBackGroundCol}">
    <link rel="stylesheet" href="https://unpkg.com/vue-multiselect@2.1.0/dist/vue-multiselect.min.css">
		<div class="section-dynfilter" :class="saveSettings.rowSetting.width==2 ? 'textWidth':saveSettings.rowSetting.width==1 ? 'fullWidth':'inner'">
       <div class="psc-cb-filterbar" v-if="SearchFilters && SearchFilters.length > 0">
        <ul class="psc-cb-filterbar-filters" >
          <li  class="psc-cb-filterbar-filters-inline" v-for="(searchFilter, z) of singleSearchFilterList" :key="(z+'_inline')" >
              <label v-if="searchFilter && searchFilter.data && (searchFilter.data.FilterType == 'Free Text' || searchFilter.data.FilterType == 'CheckBox' || (searchFilter.data.FilterType == 'Single Select' && searchFilter.dropdownData))" v-html="searchFilter.data.FilterLabel"></label>
              <input v-if="searchFilter && searchFilter.data && searchFilter.data.FilterType == 'Free Text'" v-model="filterData[searchFilter.data.FilterLabel]" v-on:keydown.enter="doEvent($event)" @input="checkValidator($event, z)" type="text" >
              <input v-if="searchFilter && searchFilter.data && searchFilter.data.FilterType == 'CheckBox' && preset!=3" type="checkbox"  v-model="filterData[searchFilter.data.FilterLabel]" >
              <input v-if="searchFilter && searchFilter.data && searchFilter.data.FilterType == 'CheckBox' && preset==3" type="checkbox"  v-model="filterData[searchFilter.data.FilterLabel]" @change="callOnSelectFunction()" >
              <multiselect
                v-if="searchFilter && searchFilter.data && searchFilter.data.FilterType == 'Single Select' && searchFilter.dropdownData && preset!=3"
                v-model="filterData[searchFilter.data.FilterLabel]"
                :options="searchFilter.dropdownData"
                :custom-label="nameWithLang"
                placeholder="Select one"
                :label="searchFilter.dropdownSettings.textField"
                :track-by="searchFilter.dropdownSettings.idField"
              ></multiselect>
              <multiselect
                v-if="searchFilter && searchFilter.data && searchFilter.data.FilterType == 'Single Select' && searchFilter.dropdownData && preset==3"
                v-model="filterData[searchFilter.data.FilterLabel]"
                :options="searchFilter.dropdownData"
                :custom-label="nameWithLang"
                placeholder="Select one"
                :label="searchFilter.dropdownSettings.textField"
                :track-by="searchFilter.dropdownSettings.idField"
                @select="callOnSelectFunction()"
              ></multiselect>
          </li>
          <li v-if="singleSearchFilterList.length && preset==3">
            <a href="#" class="psc-cb-button" v-on:click="callOnSelectFunction()"><i class="fa fa-times"></i></a>
          </li>
          <li  class="psc-cb-filterbar-filters-block" v-for="(multiselect, i) of multiSearchFilterList" :key="i+'_block'">
             <label v-if="multiselect && multiselect.data &&  multiselect.data.FilterType == 'Multi Select' && multiselect.dropdownData" v-html="multiselect.data.FilterLabel"></label>
              <multiselect
                v-if="multiselect && multiselect.data && multiselect.data.FilterType == 'Multi Select' && multiselect.dropdownData"
                v-model="filterData[multiselect.data.FilterLabel]"
                tag-placeholder="Add this as new tag"
                placeholder="Search or add a tag"
                :label="multiselect.dropdownSettings.textField"
                :track-by="multiselect.dropdownSettings.idField"
                :options="multiselect.dropdownData"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
              ></multiselect>
         </li>
        </ul>
        <div class="psc-cb-filterbar-actions" v-if="preset!=3">
          <a href="#" class="psc-cb-button" v-on:click.prevent="searchResult();getBigData();"><i class="fa fa-search"></i> <span>Search</span></a>
          <!-- <a href="#" class="psc-cb-button" v-if="!isDisabled" v-on:click="advanceSearchResult()"><i class="fa fa-sliders-h"></i> <span>Advanced Search</span></a> -->
          <a href="#" class="psc-cb-button"  v-on:click.prevent="searchAgain()"><i class="fa fa-undo"></i></a>
        </div>
      </div>
     <h2 class="psc-cb-dynfilter-title psc-cb-ipart-title" v-html="saveSettings.mainHeading" v-if="saveSettings.mainHeading && saveSettings.mainHeading.length && preset!=4 && preset!=5"></h2>
      <div class="psc-cb-dynfilter-description psc-cb-ipart-intro" v-html="saveSettings.mainHeadingContext" v-if="saveSettings.mainHeadingContext && saveSettings.mainHeadingContext.length  && preset!=4 && preset!=5"></div>
      <div class="psc-cb-dyncontent-container" :class="`preset${preset}`" v-if="preset==2 || preset==3">
        <div v-if="loading" class="psc-cb-loader">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <circle cx="50" cy="50" fill="none" stroke="#000000" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
            </circle>
          </svg>
        </div>
        <ul v-if="!loading" class="psc-cb-dyncontent-list">
          <li v-for="(item, index) of items" :key="index" class="psc-cb-dyncontent-content">
            <div class="dynamichead" v-if="saveSettings.isProfileImage">
              <div class='psc-cb-dyncontent-image'>
                <div :style="getProfileImage(item)"></div>
              </div>
            </div>
            <div class="dynamichead"  v-if="!saveSettings.isProfileImage" v-html="getDynamicTitle2(saveSettings.resultFormat.sectionImage, item)"></div>
            <div class="dynamicbody" v-html="getDynamicTitle2(saveSettings.resultFormat.section1, item)"></div>
            <div class="dynamicfoot" v-html="getDynamicTitle2(saveSettings.resultFormat.section2, item)"></div>
          </li>
        </ul>
        <pagination v-if="preset!=4 && preset!=5 && items && items.length > 0" v-model="infoPagination" class="psc-cb-pagination" with-text :page-count="totalpages" @input="changePage"/>
      </div>

			<div class="psc-cb-dynfilter-container" :class="`preset${preset}`" v-if="preset==4 || preset==5">
				<h2 class="psc-cb-dynfilter-title psc-cb-ipart-title" v-html="saveSettings.mainHeading" v-if="saveSettings.mainHeading && saveSettings.mainHeading.length && (preset==4 || preset==5)"></h2>
				<div class="psc-cb-dynfilter-description psc-cb-ipart-intro" v-html="saveSettings.mainHeadingContext" v-if="saveSettings.mainHeadingContext && saveSettings.mainHeadingContext.length && (preset==4 || preset==5)"></div>
				<div class="psc-cb-dynfilter-mainpanel">
					<div class="psc-cb-dynfilter-filterbar" >
						<ul class="psc-cb-dynfilter-filters">
              <li class="filtertrigger">
                <a>	<span>Filters ({{isActiveFlag.filter(Boolean).length}})</span> <i class="fa fa-angle-down"></i> </a>
							</li>
							<li>
                <a :class="{'active':isActiveFlag['All']}" v-on:click="checkTicks('All'); (saveSettings.typeOfPagination == 3 && !saveSettings.isIQAFilterEnable) || (saveSettings.typeOfPagination == 1 && !saveSettings.isIQAFilterEnable) ? '' : searchResult2();">
									<span class="psc-cb-dynfilter-filter-icon"><i class="fa" :class="{ 'fa-circle':!isActiveFlag['All'], 'fa-check-circle':isActiveFlag['All'] }"></i></span>
									<span  class="psc-cb-dynfilter-filter-label">All</span>
								</a>
							</li>
							<li v-for="(item, index) in dropdownArray" :key="index">
								<a :class="{'active':isActiveFlag[index]}" v-on:click="checkTicks(index); (saveSettings.typeOfPagination == 3 && !saveSettings.isIQAFilterEnable) || (saveSettings.typeOfPagination == 1 && !saveSettings.isIQAFilterEnable) ? '' : searchResult2(item);">
                  <span class="psc-cb-dynfilter-filter-icon"><i class="fa" :class="{ 'fa-circle':!isActiveFlag[index], 'fa-check-circle':isActiveFlag[index] }"></i></span>
                  <span  class="psc-cb-dynfilter-filter-label">{{ item.desp }}</span>
								</a>
							</li>
							<li class="searchtrigger">
								<a @click.prevent="showSearchBar=!showSearchBar"><i class="fa fa-search"></i></a>
							</li>
						</ul>
						<ul class="psc-cb-dynfilter-search" v-if="showSearchBar">
							<li> <input class="psc-cb-input-text" v-model="eventSearchInput" @input="searchResult2(null, null, $event)" v-on:keydown.enter="doEvent($event)" type="text" placeholder="Search" /> </li>
						</ul>
					</div>
					<div class="psc-cb-dynfilter-results">
						<div v-if="loading" class="psc-cb-loader">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
								<circle cx="50" cy="50" fill="none" stroke="#000000" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
									<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
								</circle>
							</svg>
						</div>
						<ul v-if="preset==4&&!loading" class="psc-cb-dynfilter-events-list">
							<li v-for="(item, index) of items" :key="index" class="psc-cb-dynfilter-events-event">
								<div class="dynamichead" v-html="getDynamicSection1(saveSettings.resultFormat.sec1, item)"></div>
								<div class="dynamicbody" v-html="getDynamicSection2(saveSettings.resultFormat.sec2, item)"></div>
								<div class="dynamicfoot" v-html="getDynamicSection2(saveSettings.resultFormat.sec3, item)"></div>
							</li>
						</ul>
						<ul v-if="preset==5&&!loading" class="psc-cb-dynfilter-products-list">
							<li v-for="(item, index) of items" :key="index" class="psc-cb-dynfilter-products-product">
								<div class="dynamichead" v-html="getDynamicSection1(saveSettings.resultFormat.sec1, item)"></div>
								<div class="dynamicbody" v-html="getDynamicSection2(saveSettings.resultFormat.sec2, item)"></div>
								<div class="dynamicfoot" v-html="getDynamicSection2(saveSettings.resultFormat.sec3, item)"></div>
							</li>
						</ul>
						<pagination v-if="!eventSearchInput.length" v-model="infoPagination" class="psc-cb-pagination" with-text :page-count="totalpages" @input="changePage"/>
					</div>
				</div>
			</div>
      <div class="psc-cb-ipart-blocklink" v-if="saveSettings.rowSetting.blockBtnLinkPath">
        <a :target="saveSettings.rowSetting.buttonLinkTarget==2?'_blank':''" :class="saveSettings.rowSetting.blockClass"  :href="saveSettings.rowSetting.blockBtnLinkPath?saveSettings.rowSetting.blockBtnLinkPath+(saveSettings.rowSetting.buttonSource==2 && saveSettings.rowSetting.hasOwnProperty('blockBtnLinkParameters')?saveSettings.rowSetting.blockBtnLinkParameters:''):'javascript:void(0)'" class="psc-cb-button">{{saveSettings.rowSetting.blockLabel}}</a>
      </div>
		</div>
	</div>
</template>
<script src="https://unpkg.com/vue-multiselect@2.1.0"></script>
<script>
import {Pagination } from "@/components";
import Vue from "vue";
import mtapi from "../../mtapi";
import Multiselect from "vue-multiselect";
// import VueMasonryWall from "vue-masonry-wall";
import moment from "moment";
var url = new URL(window.location.href)
export default {
  name: "FilterdData",
  components: {
    // ProfileCard,
    Pagination,
    Multiselect,
    // VueMasonryWall,
  },
  props: {
    saveSettings: Object,
  },
  data() {
    return {
      isMasonary: true,
      isAsync: true,
      isLoadMore: true,
      imagePath: window.location.origin,
      mainEventArr2: [],
      mainEventArr4: [],
      mainEventArr5: [],
      mainEventArr6: [],
      lastIndex4: 1,
      isSelectAdvSearch: false,
      isEventSearch: false,
      searchFilterGeneralSearch: "",
      isActiveFlag: [],
      dropdownArray: [],
      mainarr: [],
      mainEventArr: [],
      dropdownvalue: "",
      advSearchInput: "",
      eventSearchInput: "",
      counter: 0,
      isAdvancedSearch: false,
      isSimpleSearch: false,
      advancedItems: [],
      bigDataFlag: true,
      totalcountloop: 0,
      totalpagesAdc: 0,
      bigData: [],
      isSearchClick: false,
      isAdvanceSearchClick: false,
      errorAlert: false,
      checkIsValid: false,
      value: [{ name: "Javascript", code: "js" }],
      options: [
        { name: "Vue.js", code: "vu" },
        { name: "Javascript", code: "js" },
        { name: "Open Source", code: "os" },
        { name: "Java", code: "vu1" },
        { name: "Angular", code: "js1" },
        { name: "Nodejs", code: "os1" },
        { name: "HTML", code: "vu2" },
        { name: "Phyton", code: "js2" },
        { name: "Data Machine", code: "os2" },
      ],
      value2: { name: "Vue.js", language: "JavaScript" },
      options2: [
        { name: "Vue.js", language: "JavaScript" },
        { name: "Rails", language: "Ruby" },
        { name: "Sinatra", language: "Ruby" },
        { name: "Laravel", language: "PHP" },
        { name: "Phoenix", language: "Elixir" },
      ],
      searchText: "", // If value is falsy, reset searchText & searchItem
      items: [],
      lastSelectItem: {},
      advacnedinfoPagination: 1,
      userData: {},
      filterData:{},
      selectedMovies: [],
      infoPagination: 1,
      firstStep: true,
      pagiantionvisible: false,
      queryResult: [],
      images: [],
      totalpages: 0,
      PageNo: 1,
      imageOBject: {},
      marcImg: "https://pseudo-imis.s3-ap-southeast-2.amazonaws.com/RadiusSearchDisplay/assets/PictureLogo.png",
      isActive: false,
      preset: this.saveSettings && this.saveSettings.preset ? this.saveSettings.preset : "",
      manualRotateState: false,
      manualRotateState2: false,
      manualRotateState3: false,
      configForm: {},
      SearchFilters: [],
      singleSearchFilterList:[],
      multiSearchFilterList : [],
      AdvanceSearchFilters: [],
	  loading: false,
	  showSearchBar: false
    };
  },
  mounted() {
    this.configForm = this.saveSettings;
    if ( this.configForm && this.configForm.SearchFilters && this.configForm.SearchFilters.length && this.configForm.SearchFilters.length > 0 ) {
      this.configForm.SearchFilters = this.configForm.SearchFilters.sort( (a, b) => a.Position - b.Position );
    }
    if ( this.configForm && this.configForm.AdvanceSearchFilters && this.configForm.AdvanceSearchFilters.length && this.configForm.AdvanceSearchFilters.length > 0 ) {
      this.configForm.AdvanceSearchFilters = this.configForm.AdvanceSearchFilters.sort( (a, b) => a.Position - b.Position );
    }
    if (this.saveSettings && this.saveSettings.secondaryPreset) {
      this.isActive = false;
      this.preset = this.saveSettings.secondaryPreset;
    }
    this.iqaSearchFilterSingle();
    this.iqaAdvanceSearchFilter();

    if (this.saveSettings.toolType == "4" || this.saveSettings.toolType == "5") {
      this.checkTicks("All");
      this.searchResult2(null, true);
    }
    if (this.saveSettings.toolType != "4" && this.saveSettings.toolType != "5") {
      setTimeout(() => {
        this.defaultSearchResult();
      }, 1000);
    }

    window.addEventListener( "scroll", (event) => {
        if ( this.saveSettings.typeOfPagination == 3 && window.scrollY + window.innerHeight >= document.documentElement.scrollHeight - 1 ) {
          if (this.saveSettings.isIQAFilterEnable)
            this.changePage(this.infoPagination + 1);
          else 
            this.getInfinite();
        }
      },{passive: true,});
    jQuery('.filtertrigger').on('click','a',function(e){
      e.preventDefault();
      jQuery(this).parent().parent().toggleClass('open');
    });
  },
  methods: {
    callOnSelectFunction(){
      setTimeout(() => {
        this.searchResult();
      }, 100);
    },
    getProfileImage(item){
      return ({'background-image': 'url(' + [[this.imageOBject[item.ID] ? this.imageOBject[item.ID] : this.marcImg]] + ')'})
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
    },
    getInfinite() {
      let mainEventArr3 = [];
      this.dropdownArray.forEach((resp, i) => {
        if (this.isActiveFlag[i] || this.isActiveFlag["All"]) {
          mainEventArr3.push(resp.desp.toLowerCase());
        }
      });
      this.bigData.forEach((resp, z) => {
        mainEventArr3.forEach((element) => {
          if (JSON.stringify(resp).toLowerCase().includes(element)) {
            if (!this.mainEventArr2.includes(resp) && resp) {
              this.mainEventArr2.push(resp);
            }
          }
        });

       if ( this.eventSearchInput && JSON.stringify(resp) .toLowerCase() .includes(this.eventSearchInput.toLowerCase()) ) {
          if (!this.mainEventArr2.includes(resp)) {
            this.mainEventArr2.push(resp);
          }
        }
      });

      this.isSelectAdvSearch = this.mainEventArr2.length > 0 ? true : false;
      this.totalpagesAdc = Math.floor( this.mainEventArr2.length / this.saveSettings.perPage + (this.mainEventArr2.length % this.saveSettings.perPage > 0 ? 1 : 0) );
      let cond = this.lastIndex4 * this.saveSettings.perPage <= this.mainEventArr2.length ? this.lastIndex4 * this.saveSettings.perPage : this.mainEventArr2.length;
      if (this.lastIndex4 == 1 && this.mainEventArr2.length > 0) {
        for (let index = 0; index < cond; index++) {
          this.mainEventArr4.push(this.mainEventArr2[index]);
        }
        this.lastIndex4 = 2;
      } else if (this.mainEventArr2.length > this.mainEventArr4.length) {
        for ( let index = (this.lastIndex4 - 1) * this.saveSettings.perPage; index < cond; index++ ) {
          this.mainEventArr4.push(this.mainEventArr2[index]);
        }
        this.lastIndex4++;
      }
      if (this.mainEventArr2.length == this.mainEventArr4.length) {
        this.isLoadMore = false;
      } else {
        this.isLoadMore = true;
      }
    },
    clearAdvFilter() {
      this.advancedItems = [];
      this.advSearchInput = "";
      this.isSimpleSearch = true;
      this.AdvanceSearchFilters.forEach((element) => {
        element.searchFilterValues = [];
      });
    },
    checkValidator(event, i) {
      Vue.set(this.SearchFilters, i, this.SearchFilters[i]);
    },
    nameWithLang(value) {
      return `${value.Description}`;
    },
    onSelect(items, lastSelectItem) {
      this.items = items;
      this.lastSelectItem = lastSelectItem;
    },
    reset() {
      this.items = []; // reset
    },
    validateSearchFilters() {
      let x = false;
      for (var i = 0; i < this.SearchFilters.length; i++) {
        if (this.SearchFilters[i] && this.SearchFilters[i].searchFilterValues) {
          if ( this.SearchFilters[i].data.IsMandatory && !this.SearchFilters[i].searchFilterValues.length )
            x = true;
        } else if(this.SearchFilters[i].data.IsMandatory && !this.SearchFilters[i].value.length ) {
            x = true;
        }
      }
      return x;
    },
    searchResult() {
      this.isAdvancedSearch = false;
      this.isSimpleSearch = true;
      if (this.saveSettings.typeOfPagination == 1) {
        this.items = [];
      }
      this.errorAlert = false;
      if (this.saveSettings.toolType != "4" && this.saveSettings.toolType != "5") {
        if (this.validateSearchFilters()) {
          this.checkIsValid = true;
          return false;
        } else {
          let tempUserData = {}
          this.SearchFilters.forEach((resp) => {
            if (resp ) {
              if (resp.data.FilterType == "Multi Select" && this.filterData.hasOwnProperty(resp.data.FilterLabel)) {
                let temp;
                this.filterData[resp.data.FilterLabel].forEach((code) => {
                  temp = temp ? temp + '"' + code[resp.data.PostcodeValueColumn] + '",' : '"' + code[resp.data.PostcodeValueColumn] + '",';
                });
                tempUserData[resp.data.SourceIQAParameter] = escape(temp ? temp.slice(0, -1) : "");
               }
               if (resp.data.FilterType == "Single Select" && this.filterData.hasOwnProperty(resp.data.FilterLabel)) {
                 tempUserData[resp.data.SourceIQAParameter]=escape(this.filterData[resp.data.FilterLabel][resp.data.PostcodeValueColumn])
               }else if(resp.data.FilterType != "Multi Select" && this.filterData.hasOwnProperty(resp.data.FilterLabel)){
                 tempUserData[resp.data.SourceIQAParameter]=escape(this.filterData[resp.data.FilterLabel])
               }
            }
          })
          this.userData = Object.assign({},tempUserData);
          this.searchWithParameter(1);
        }
      }
    },
    defaultSearchResult() {
      this.isAdvancedSearch = false;
      this.isSimpleSearch = true;
      if (this.saveSettings.typeOfPagination == 1) {
        this.items = [];
      }
      this.errorAlert = false;
      if (this.saveSettings.toolType != "4" && this.saveSettings.toolType != "5") {
          let tempUserData = {}
          this.SearchFilters.forEach((resp,i) => {
            if (resp ) {
              if (resp.data.FilterType == "Multi Select" && (resp.data.Defaultvalue|| resp.data.urlParameterName&&url.searchParams.get(resp.data.urlParameterName))) {
                resp.dropdownData.forEach(ele => {
                   if(ele[resp.data.PostcodeValueColumn].toLowerCase()==resp.data.Defaultvalue.toLowerCase() || ele[resp.data.PostcodeValueColumn].toLowerCase() == url.searchParams.get(resp.data.urlParameterName).toLowerCase()){
                      // this.filterData[resp.data.FilterLabel]=ele
                      Vue.set(this.filterData,resp.data.FilterLabel,ele)
                   }
                 });
                tempUserData[resp.data.SourceIQAParameter]=escape(resp.data.Defaultvalue)
               }
               if (resp.data.FilterType == "Single Select" && (resp.data.Defaultvalue || resp.data.urlParameterName&&url.searchParams.get(resp.data.urlParameterName))) {
                 resp.dropdownData.forEach(ele => {
                   if(ele[resp.data.PostcodeValueColumn]?.toLowerCase()==resp.data.Defaultvalue?.toLowerCase() || ele[resp.data.PostcodeValueColumn]?.toLowerCase() == url.searchParams.get(resp.data.urlParameterName)?.toLowerCase()){
                     if(resp.data.urlParameterName && url.searchParams.get(resp.data.urlParameterName)){
                       ele[resp.data.urlParameterName]=url.searchParams.get(resp.data.urlParameterName)
                      }
                      // this.filterData[resp.data.FilterLabel]=ele
                      Vue.set(this.filterData,resp.data.FilterLabel,ele)
                   }
                 });
                 tempUserData[resp.data.SourceIQAParameter]=escape(resp.data.urlParameterName&&url.searchParams.get(resp.data.urlParameterName)?url.searchParams.get(resp.data.urlParameterName):resp.data.Defaultvalue)
               }else if(resp.data.FilterType != "Multi Select" && resp.data.Defaultvalue){
                 if(resp.data.FilterType == "CheckBox"){
                   tempUserData[resp.data.SourceIQAParameter]=true
                   this.filterData[resp.data.FilterLabel] = true
                 }else{
                   tempUserData[resp.data.SourceIQAParameter] = escape( resp.data.urlParameterName&&url.searchParams.get(resp.data.urlParameterName)?url.searchParams.get(resp.data.urlParameterName):resp.data.Defaultvalue)
                   this.filterData[resp.data.FilterLabel]     = resp.data.urlParameterName&&url.searchParams.get(resp.data.urlParameterName)?url.searchParams.get(resp.data.urlParameterName):resp.data.Defaultvalue
                 }
               }
            }
          })
          this.userData = Object.assign({},tempUserData);
          this.searchWithParameter(1);
      }
    },
    searchResult2(item, isEnable, event) {
      let temp;
      this.mainEventArr2 = [];
      if (this.configForm.isIQAFilterEnable) {
        this.dropdownArray.forEach((resp, i) => {
          if (this.isActiveFlag[i]) {
            temp = temp ? temp + '"' + resp.code + '",' : '"' + resp.code + '",';
          }
        });
        this.userData[this.configForm.SourceIQAParameter] = temp ? temp.slice(0, -1) : "";
        this.searchWithParameter(1);
        this.isEventSearch = true;
      } else {
        if (isEnable) {
          this.searchWithParameter(0);
          this.isEventSearch = true;
        } else {
          this.bigData.forEach((resp) => {
            this.dropdownArray.forEach((response, i) => {
              if (this.isActiveFlag[i]) {
                if ( JSON.stringify(resp).toLowerCase().includes(response.desp.toLowerCase()))
                  if (!this.mainEventArr2.includes(resp)) {
                    this.mainEventArr2.push(resp);
                  }
              }
            });
            if (this.eventSearchInput && JSON.stringify(resp).toLowerCase().includes(this.eventSearchInput.toLowerCase())) {
              if (!this.mainEventArr2.includes(resp)) {
                this.mainEventArr2.push(resp);
              }
            }
          });
          this.items=[]
          if(!this.eventSearchInput.length){
            this.items=this.bigData.slice(0,15)
          }else{
            this.items=this.mainEventArr2.slice(0,15)
          }
          this.setPagination(1);
          this.isSelectAdvSearch = this.mainEventArr2.length > 0 ? true : false;
          this.totalpagesAdc = Math.floor(this.mainEventArr2.length / this.saveSettings.perPage + (this.mainEventArr2.length % this.saveSettings.perPage > 0 ? 1 : 0));
        }
      }
    },
    advanceSearchResult() {
      this.isAdvanceSearchClick = !this.isAdvanceSearchClick;
    },
    async searchWithParameter(pNo) {
      this.loading	=	true;
      this.infoPagination = pNo;
      let params;
      await mtapi.setIqaParamter(this.saveSettings.iqaDocumentVersionKey,this.userData ? this.userData : "").then((resp) => {
        params = resp?resp:'';
        this.getData(params);
      });
    },
    async getData(params) {
      let urlwithLimit = `/api/iqa?QueryDocumentVersionKey=${this.saveSettings?.iqaDocumentVersionKey}` + params + `&Limit=` + this.saveSettings.perPage + `&Offset=` + (this.infoPagination - 1) * this.saveSettings.perPage;
      await mtapi.axios.get(urlwithLimit).then((response) => {
        if (response.status === 200) {
          this.queryResult = new mtapi.ImisEntityCollection(response?.data);
          if ((this.saveSettings.typeOfPagination == 2 && this.infoPagination > 1) || (this.saveSettings.typeOfPagination == 2 && !this.saveSettings.isIQAFilterEnable) || this.saveSettings.typeOfPagination == 3) {
            this.queryResult.Entities.forEach((itemss) => {
              this.items.push(itemss);
              this.mainEventArr4.push(itemss);
            });
            this.lastIndex4 = 2;
          } else {
            if (this.saveSettings.typeOfPagination == 1 && !this.saveSettings.isIQAFilterEnable) {
              this.mainEventArr5 = this.queryResult.Entities;
            }
            this.items = this.queryResult.Entities;
          }
          if (this.items && this.items.length > 0) {
            if (this.saveSettings.toolType != 4 && this.saveSettings.toolType != 5) {
              response.data.Items.$values.forEach((ele) => {
                // if(this.saveSettings.perPage && ele.Properties?.$values[this.saveSettings.perPage]?.Value ){
                if(ele.Properties?.$values ){
                  let partyId 
                  ele.Properties.$values.forEach(element => {
                    if(element.Name=="ID"){
                      partyId = element.Value
                    }
                  });
                  if(partyId){
                    this.getImage(partyId).then((resp) => {
                      if (resp) {
                        Vue.set(this.imageOBject, resp.id, resp.image);
                      }
                    });
                  }
                }
              });
            }
            this.totalcountloop = response.data.TotalCount / 100;
            this.bigData = [];
            this.getBigDataApi(params, 0);
            if (response.data.TotalCount > this.saveSettings.perPage) {
              this.pagiantionvisible = true;
              this.pagination(response.data.TotalCount);
              if (this.totalpages == this.infoPagination) {
                this.isLoadMore = false;
              } else {
                this.isLoadMore = true;
              }
            } else {
              this.isLoadMore = false;
              this.pagiantionvisible = false;
              this.pagination(response.data.TotalCount);
            }
          } else {
            this.errorAlert = true;
          }
        }
      });
      this.loading	=	false;
    },
    async getBigDataApi(params, index) {
      this.loading	=	true;
      let urlwithLimit = `/api/iqa?QueryDocumentVersionKey=${this.saveSettings?.iqaDocumentVersionKey}` + params + `&Limit=100` + `&Offset=` + index * 100;
      let total = this.totalcountloop >= 1 ? Math.floor(this.totalcountloop) + 1 : 0;
      if (this.counter < total && this.bigDataFlag) {
        this.bigDataFlag = false;
        for (let index = 0; index < total; index++) {
          this.getBigDataApi(params, index);
          this.counter++;
        }
      }
      await mtapi.axios.get(urlwithLimit).then((response) => {
        if (response.status === 200) {
        new mtapi.ImisEntityCollection(response?.data).Entities.forEach((ele) => {
          this.bigData.push(ele);
        });
        this.isSearchClick = true;
        if (this.saveSettings.typeOfPagination == 1 && !this.saveSettings.isIQAFilterEnable) {
          this.pageData();
        }
        }
      });
      this.loading	=	false;
    },
    async iqaSearchFilterSingle() {
      this.loading	=	true;
      if(this.configForm && this.configForm.searchFilters){
        this.configForm.searchFilters.forEach(async (resp, index) => {
          if (resp.FilterType == "CheckBox" || resp.FilterType == "Free Text") {
            this.singleSearchFilterList.push({ data: resp, value: "", })
            Vue.set(this.SearchFilters, index, { data: resp, value: "", });
          } else {
            if ( !(resp.FilterType == "Free Text" && resp.FilterType == "CheckBox") ) {
              let response = await mtapi.axios.get( `/api/iqa?QueryDocumentVersionKey=${resp.IQA}` );
              if (response.status === 200) {
                if(resp.FilterType == 'Single Select'){
                    this.singleSearchFilterList.push( { type: resp.FilterType, data: resp, searchFilterValues: this.searchFilterDefaults( resp, response.data.Items.$values ), dropdownData: this.setDropDownValue( response.data.Items.$values ), dropdownSettings: this.dropDownSelectChoices( resp.FilterType, resp ), })
                }else if(resp.FilterType == 'Multi Select'){
                  this.multiSearchFilterList.push( { type: resp.FilterType, data: resp, searchFilterValues: this.searchFilterDefaults( resp, response.data.Items.$values ), dropdownData: this.setDropDownValue( response.data.Items.$values ), dropdownSettings: this.dropDownSelectChoices( resp.FilterType, resp ), })
                }
                Vue.set(this.SearchFilters, index, { type: resp.FilterType, data: resp, searchFilterValues: this.searchFilterDefaults( resp, response.data.Items.$values ), dropdownData: this.setDropDownValue( response.data.Items.$values ), dropdownSettings: this.dropDownSelectChoices( resp.FilterType, resp ), });
              }
            }
          }
        });
      }
		  this.loading	=	false;
    },
    async iqaAdvanceSearchFilter() {
      this.loading	=	true;
      this.isActiveFlag = [];
      if (this.configForm.toolType == "4" || this.configForm.toolType == "5") {
        if (this.configForm.selectSearchFilter) {
          let response = await mtapi.axios.get(`/api/iqa?QueryDocumentVersionKey=${this.configForm.selectSearchFilter}`);
          if (response.status === 200) {
            response.data.Items.$values.forEach((resp) => {
              let temp = { code: "", desp: "", isActive:false };
              resp.Properties.$values.forEach((respdata) => {
                if (respdata.Name == this.configForm.selectDescription) {
                temp.desp = respdata.Value;
                } else if (respdata.Name == this.configForm.selectCode) {
                temp.code = respdata.Value;
                }
              });
              this.dropdownArray.push(temp);
            });
            this.dropdownArray.forEach((res) => {
              this.isActiveFlag.push(false);
            });
          }
        }
      } else {
        this.configForm.advancedSearchFilters.forEach(async (resp, index) => {
          if (resp.FilterType == "CheckBox" || resp.FilterType == "Free Text") {
            Vue.set(this.AdvanceSearchFilters, index, { data: resp, value: "", });
          } else {
            if (!(resp.FilterType == "Free Text" && resp.FilterType == "CheckBox")) {
              let response = await mtapi.axios.get(`/api/iqa?QueryDocumentVersionKey=${resp.IQA}`);
              if (response.status === 200) {
                if (resp.FilterLabel == "member types") {
                  this.dropdownArray = response.data.Items.$values;
                  this.dropdownArray.forEach((res) => {
                    this.isActiveFlag.push(false);
                  });
                }
                Vue.set(this.AdvanceSearchFilters, index, { type: resp.FilterType, data: resp, searchFilterValues: this.searchFilterDefaults( resp, response.data.Items.$values ), dropdownData: this.setDropDownValue( response.data.Items.$values ), dropdownSettings: this.dropDownSelectChoices( resp.FilterType, resp ), });
              }
            }
          }
        });
      }
      this.loading	=	false;
    },
    setDropDownValue(result) {
      let responseData = new Array();
      let resultSet = result;
      let columns = [];
      for (var i = 0; i < resultSet.length; i++) {
        let entityData = resultSet[i];
        let rowData = {};
        for (var j = 0; j < entityData.Properties.$values.length; j++) {
          let propData = entityData.Properties.$values[j];
          let name = propData.Name ? propData.Name : "";
          let value = "";
          if (propData.Value) {
            value = typeof propData.Value == "object" ? propData.Value.$value : propData.Value;
          }
          if (!columns.includes(name)) columns.push(name);
          rowData[name] = value;
        }
        responseData.push(rowData);
      }
      return responseData;
    },
    dropDownSelectChoices(selectType, element) {
      let object;
      if (selectType === "Single Select") {
        object = {
          singleSelection: true,
          idField: element.PostcodeValueColumn,
          textField: element.PostcodeDescriptionColumn,
          allowSearchFilter: true,
          closeDropDownOnSelection: true,
        };
      } else if (selectType === "Multi Select") {
        object = {
          singleSelection: false,
          idField: element.PostcodeValueColumn,
          textField: element.PostcodeDescriptionColumn,
          selectAllText: "Select All",
          unSelectAllText: "UnSelect All",
          itemsShowLimit: 5,
          allowSearchFilter: true,
        };
      }
      return object;
    },
    searchFilterDefaults(data, dataArr) {
      let defaultDataArray = [];
      if (data.Defaultvalue !== "") {
        let defaultValues = data.Defaultvalue.split(",");
        for (var i = 0; i < defaultValues.length; i++) {
          dataArr.filter((dataObj) => {
            if (dataObj[data.PostcodeValueColumn] === defaultValues[i].trim()) {
              defaultDataArray.push(dataObj);
            }
          });
        }
      }
      return defaultDataArray;
    },
    getDynamicSection1(value, item) {
      let tempHTML = "";
      if(item){
        value.split("[[").forEach((values) => {
          if (values.includes("]]")) {
            let key = values.split("]]")[0];
            if (item[key] == "" || item[key] == undefined) {
              value = value.replace("[[" + key + "]]", require("@/assets/img/calendar.png"));
            } else {
              value = value.replace("[[" + key + "]]",window.location.origin + "/" + item[key]);
            }
          }
        });
      }
      tempHTML = value;
      return tempHTML;
    },
    getDynamicSection2(value, item) {
     	let tempHTML = "";
      	value.split("[[").forEach((values) => {
          if (values.includes("]]")) {
            let key = values.split("]]")[0];
            if (typeof item[key] == "object") {
              if (typeof item[key]["$value"] != "number" && this.isDate(item[key]["$value"])) {
                value = value.replace("[[" + key + "]]", item[key]["$value"]!= undefined?this.DateFormat(item[key]["$value"]):'');
              } else {
                value = value.replace("[[" + key + "]]", item[key]["$value"]!= undefined?item[key]["$value"]:'');
              }
            } else {
              if (typeof item[key] != "number" && this.isDate(item[key])) {
                value = value.replace("[[" + key + "]]", item[key]!=undefined?this.DateFormat(item[key]):'');
              } else {
                value = value.replace("[[" + key + "]]", item[key]!=undefined?item[key]:'');
              }
            }
          }
        });
        tempHTML = value;
     	return tempHTML;
    },
    getDynamicTitle2(value, item) {
      let tempHTML = "";
      if(item && value){
        value.split("[[").forEach((values) => {
          if (values.includes("]]")) {
            let key = values.split("]]")[0];
            if (typeof item[key] == "object") {
              value = value.replace("[[" + key + "]]", item[key]["$value"]);
              if (this.isDate(item[key]["$value"])) {
                value = value.replace("[[" + key + "]]", this.DateFormat(item[key]["$value"]));
              } else {
                value = value.replace("[[" + key + "]]", item[key]["$value"]);
              }
            } else {
              if (this.isDate(item[key])) {
                value = value.replace("[[" + key + "]]", this.DateFormat(item[key]));
              } else if(value.includes('<img') && (this.saveSettings.toolType==4 ||  this.saveSettings.toolType==5)){
                  value = value.replace("[[" + key + "]]", window.location.origin + "/" + item[key]);
              }else {
                value = value.replace("[[" + key + "]]", item[key] != undefined?item[key]:'');
              }
            }
          }
        });
        tempHTML = value;
      }
      return tempHTML;
    },
    DateFormat: function (date) {
		return moment(date, "YYYY-MM-DD").format("Do MMM YYYY");
    // return moment(date, "YYYY-MM-DD").format('LLL');
    },
    isDate(date){
      if(date==undefined) return false
      return	date.match(/^(\d{4})\-(\d{2})\-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/);
      // return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
    },
    getImage(partyId) {
      return mtapi.axios.get(`/api/PartyImage?PartyId=${partyId}`).then((resp) => {
          if ( resp.status === 200 && resp?.data && resp?.data?.Items && resp?.data?.Items.$values ) {
            if ( resp?.data?.Items.$values[0]) {
              //  && resp?.data?.Items.$values[0].Purpose != "" // remove from if condition because not clear what purpose we are using this
              let data = { id: partyId, image: URL.createObjectURL(mtapi.base64ToBlob(resp?.data?.Items.$values[0] ?.Image?.$value)), };
              return data;
            } else {
              	let data = { id: partyId, image: this.marcImg, };
              	return data;
            }
          }
        });
    },
    doEvent(event) {
      event.preventDefault();
    },
    advancedQueryFIlter() {
      let filter = {};
      this.AdvanceSearchFilters.forEach((resp) => {
        if ( resp.type == "Single Select" && resp.searchFilterValues && resp.searchFilterValues.hasOwnProperty(resp.data.PostcodeValueColumn) ) {
          filter[resp.data.PostcodeValueColumn] = [resp.searchFilterValues[resp.data.PostcodeValueColumn],];
        } else if (resp.type == "Multi Select") {
          resp.searchFilterValues.forEach((respItem) => {
            if (!filter.hasOwnProperty(resp.data.PostcodeValueColumn)) {
              filter[resp.data.PostcodeValueColumn] = [respItem[resp.data.PostcodeValueColumn],];
            } else {
              filter[resp.data.PostcodeValueColumn].push(respItem[resp.data.PostcodeValueColumn]);
            }
          });
        }
      });
      if (this.advSearchInput) {
        filter["allSearch"] = [this.advSearchInput];
      }
      this.mainarr = this.filterArray(filter);
      this.totalpagesAdc = Math.floor( this.mainarr.length / this.saveSettings.perPage + (this.mainarr.length % this.saveSettings.perPage > 0 ? 1 : 0) );
      this.setPagination(1);
      this.isAdvancedSearch = true;
      this.isSimpleSearch = false;
    },
    advancedQueryFIlter1(item) {
      let filter = {};
      filter[item.Name] = [item.Value];
      this.mainarr = this.filterArray(filter);
      this.totalpagesAdc = Math.floor( this.mainarr.length / this.saveSettings.perPage + (this.mainarr.length % this.saveSettings.perPage > 0 ? 1 : 0) );
      this.setPagination(1);
      this.isAdvancedSearch = true;
      this.isSimpleSearch = false;
    },
    filterArray(filters) {
      let response = [];
      const filterKeys = Object.keys(filters);
      if (filterKeys.length == 0) {
        return this.bigData;
      }
      this.bigData.filter((item) => {
        let flag = true;
        let andFlag = true;
        filterKeys.forEach((key, index) => {
          if (flag && this.saveSettings.andOr != 2) {
            if (key == "allSearch") {
             if ( JSON.stringify(item) .toLowerCase() .includes(this.advSearchInput.toLowerCase()) ) {
                response.push(item);
                flag = false;
              }
            } else if (filters[key].includes(item[key])) {
              response.push(item);
              flag = false;
            }
          }
          if (this.saveSettings.andOr == 2) {
            if (key == "allSearch") {
             if ( !JSON.stringify(item) .toLowerCase() .includes(this.advSearchInput.toLowerCase()) ) {
                andFlag = false;
              }
            } else if (!filters[key].includes(item[key])) {
              andFlag = false;
            }
            if (filterKeys.length == index + 1 && andFlag) {
              response.push(item);
            }
          }
        });
      });
      return response;
    },
    advanceSearchField(event) {
      this.advancedItems = [];
      let textvalue = event.target.value;
      let i = 2;
      let temp = [];
      this.bigData.filter((ele, index) => {
        if ( JSON.stringify(ele.LastName) .toLowerCase() .includes(textvalue.toLowerCase()) ) {
          temp.push(ele);
          if (i > this.saveSettings.perPage) {
            i = 1;
            this.advancedItems.push(temp);
            temp = [];
          }
          this.totalpagesAdc = this.advancedItems.length;
          i++;
        }

        if (index == this.bigData.length - 1) {
          if (temp.length > 0) {
            this.advancedItems.push(temp);
            temp = [];
          }
          this.isAdvancedSearch = true;
          this.isSimpleSearch = false;
        }
      });
    },
    getBigData() {
    },
    checkTicks(i) {
      if (i == "All") {
        this.isActiveFlag.forEach((resp, j) => {
          resp = false;
          Vue.set(this.isActiveFlag, j, false);
        });
        Vue.set(this.isActiveFlag, i, !this.isActiveFlag[i]);
      } else {
        Vue.set(this.isActiveFlag, i, !this.isActiveFlag[i]);
        this.isActiveFlag["All"] = false;
      }
      if(!this.isActiveFlag.filter(Boolean).length){
        this.isActiveFlag["All"] = true;
      }
      this.infoPagination = 1;
      this.items = [];
      if ( (this.saveSettings.typeOfPagination == 3 || this.saveSettings.typeOfPagination == 2) && !this.saveSettings.isIQAFilterEnable ) {
        this.mainEventArr2 = [];
        this.mainEventArr4 = [];
        this.lastIndex4 = 1;
        this.getInfinite();
      }
      this.advacnedinfoPagination = 1;
      this.pageData();
      this.isMasonary = this.saveSettings.isMasonry;
    },
    pageData() {
      this.mainEventArr5 = [];
      this.infoPagination = this.advacnedinfoPagination;
      let mainEventArr3 = [];
      this.dropdownArray.forEach((resp, i) => {
        if (this.isActiveFlag[i] || this.isActiveFlag["All"]) {
          mainEventArr3.push(resp.desp.toLowerCase());
        }
      });

      this.bigData.forEach((resp, z) => {
        mainEventArr3.forEach((element) => {
          if (JSON.stringify(resp).toLowerCase().includes(element)) {
            if (!this.mainEventArr5.includes(resp) && resp) {
              this.mainEventArr5.push(resp);
            }
          }
        });

       	if ( this.eventSearchInput && JSON.stringify(resp) .toLowerCase() .includes(this.eventSearchInput.toLowerCase()) ) {
          if (!this.mainEventArr5.includes(resp)) {
            this.mainEventArr5.push(resp);
          }
        }
      });

      if (this.mainEventArr5 && this.mainEventArr5.length > 0) {
        this.mainEventArr6 = [];
        for ( let index = (this.infoPagination - 1) * this.saveSettings.perPage; index < this.infoPagination * this.saveSettings.perPage; index++ ) {
          if (this.mainEventArr5[index])
            this.mainEventArr6.push(this.mainEventArr5[index]);
        }
      }
      this.totalpagesAdc = Math.floor( this.mainEventArr5.length / this.saveSettings.perPage + (this.mainEventArr5.length % this.saveSettings.perPage > 0 ? 1 : 0) );
    },
    searchAgain() {
      this.advancedItems = [];
      this.items = [];
      this.filterData ={}
      this.userData={}
      this.SearchFilters.map((resp) => {
        if (resp.searchFilterValues) {
          resp.searchFilterValues = [];
        }
        resp.value = "";
      });
      this.clearAdvFilter();
      this.isAdvanceSearchClick = false;
      this.isSearchClick = false;
      this.searchWithParameter(1)
    },
    changePage(pNo) {
      this.loading	=	true;
      this.PageNo = pNo;
      this.searchWithParameter(pNo);
      this.isAsync = true;
      if (this.totalpages == pNo) {
        this.isLoadMore = false;
      }
    },
    setPagination(pNo) {
      this.advancedItems = [];
      let totalResult = this.mainarr.length > pNo * this.saveSettings.perPage ? pNo * this.saveSettings.perPage : this.mainarr.length;
      let totalEventResult = this.mainEventArr2.length > pNo * this.saveSettings.perPage ? pNo * this.saveSettings.perPage : this.mainEventArr2.length;
      for ( let index = (pNo - 1) * this.saveSettings.perPage; index < totalResult; index++ ) {
        this.advancedItems.push(this.mainarr[index]);
      }
      for ( let index = (pNo - 1) * this.saveSettings.perPage; index < totalEventResult; index++ ) {
        this.mainEventArr.push(this.mainEventArr2[index]);
      }
    },
    pagination(totalcount) {
      var pages = 0;
      pages = totalcount / this.saveSettings.perPage;
      this.totalpages = Math.ceil(pages);
    },
  },
  computed: {
    isDisabled() {
      let Disabled = false;
      if (this.isSearchClick) {
        Disabled = false;
      } else {
        Disabled = true;
      }
      return Disabled;
    },
  },
};
</script>
