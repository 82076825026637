
<template>
	<div class="psc-cb-ipart-container" :style="{backgroundColor:Settings.rowSetting.background.contBackGroundCol}">
		<div class="section-marquee" :class="Settings.rowSetting.width==2?'textWidth':Settings.rowSetting.width==1?'fullWidth':'inner'">
			<div v-if="preset==1" class="psc-cb-marquee-container preset1">
				<h2 class="psc-cb-marquee-title psc-cb-ipart-title" v-html="Settings.mainHeading" v-if="Settings.mainHeading&&Settings.mainHeading.length"></h2>
				<ul class="psc-cb-marquee" :style="{'animation-duration': Settings.animationDuration+'s'}">
					<li v-for="(item, index) of Settings.items" :key="index" ><a :target="item.buttonLinkTabOption==2?'_blank':''" :href="item && item.btnLinkPath ? item.btnLinkPath : 'javascript:void(0)'"><span :style="'width: 150px; height: 150px; background-image: url('+ item.imagePath+');'"></span></a></li>
				</ul>
				<div class="psc-cb-marquee-text" v-html="Settings.mainHeadingContext" v-if="Settings.mainHeadingContext&&Settings.mainHeadingContext.length"></div>
			</div>
	  </div>
    <div class="psc-cb-ipart-blocklink" v-if="Settings.rowSetting.blockBtnLinkPath">
			<a :target="Settings.rowSetting.buttonLinkTarget==2?'_blank':''" :class="Settings.rowSetting.blockClass" :href="Settings.rowSetting.blockBtnLinkPath?Settings.rowSetting.blockBtnLinkPath+(Settings.rowSetting.buttonSource==2&& Settings.rowSetting.hasOwnProperty('blockBtnLinkParameters')?Settings.rowSetting.blockBtnLinkParameters:''):'javascript:void(0)'" class="psc-cb-button">{{Settings.rowSetting.blockLabel}}</a>
		</div>
  </div>
</template>

<script>
export default {
  name: "Marquee",
  components: {},
  props: {
    Settings: Object,
  },
  data() {
    return {
      style: {},
      isActive: this.Settings.toolType != undefined && this.Settings.toolType !== "" ? false : true,
      mdIcon: '<i class="md-icon md-icon-font md-tab-icon md-theme-default fas fa-users"></i>',
      preset: this.Settings.toolType != undefined && this.Settings.toolType !== "" ? this.Settings.toolType : "",
    };
  },
  methods:{ }
 };
</script>
