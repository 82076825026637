<template>
	<div class="psc-cb-ipart-container" :style="{backgroundColor:Settings.rowSetting.background.contBackGroundCol}">
		<div class="section-imagegallery" :class="Settings.rowSetting.width==2?'textWidth':Settings.rowSetting.width==1?'fullWidth':'inner'">

			<div v-if="preset==1" class="psc-cb-imagegallery-container preset1">
				<ul class="psc-cb-imagegallery">
					<li v-for="(item, i) in Settings.items" :key="i">
						<a>
							<span
							class="psc-cb-imagegallery-image"
							@click="openImageMod(i)"
							:style="{ 'background-image': 'url(' + item.imagePath + ')' }"
							></span>
							<span class="psc-cb-imagegallery-caption">{{ item.title }}</span>
						</a>
					</li>
				</ul>
			</div><!--.preset1-->

			<div v-if="preset==2" class="psc-cb-imagegallery-container preset2">
				<div class="psc-cb-imagegallery-text">
					<h2 v-if="Settings.mainHeading&&Settings.mainHeading.length" class="psc-cb-imagegallery-title psc-cb-ipart-title" v-html="Settings.mainHeading"></h2>
					<div v-if="Settings.mainHeadingContext&&Settings.mainHeadingContext.length" class="psc-cb-imagegallery-intro psc-cb-ipart-intro" v-html="Settings.mainHeadingContext"></div>
					<ul class="psc-cb-imagegallery-features">
						<li v-for="feature,index in 4" :key="index">
							<div class="feature-title">
								<i v-bind:class="Settings['icon'+(index+1)]"></i>
								<h5  v-html="Settings['title'+(index+1)]"></h5>
							</div>
							<div class="feature-description" v-html="Settings['subDes'+(index+1)]"></div>
						</li>
					</ul>
				</div>      
				<ul class="psc-cb-imagegallery">
					<li v-for="(item, i) in Settings.items" :key="i">
						<a>
							<span
							class="psc-cb-imagegallery-image"
							@click="openImageMod(i)"
							:style="{ 'background-image': 'url(' + item.imagePath + ')' }"
							></span>
							<span class="psc-cb-imagegallery-caption">{{ item.title }}</span>
						</a>
					</li>
				</ul>
			</div><!--.preset2-->

			<div v-if="preset==3" class="psc-cb-imagegallery-container preset3">
					<VueSlickCarousel
						:ref="'largeImage'+SortOrder"
						:asNavFor="$refs['thumbImage'+SortOrder]"
						:focusOnSelect="true"
						class="lightbox"
					>
						<div v-for="(item, i) in Settings.items" :key="SortOrder + i" class="psc-cb-imagegallery-slide">
							<!--img class="psc-cb-imagegallery-image-large" :src="item.imagePath ? item.imagePath: require('@/assets/img/examples/card-blog3.jpg')" /-->
							<div class="psc-cb-imagegallery-slideimage" :style="{ backgroundImage: 'url('+item.imagePath+')' }"></div>
							<h5 class="psc-cb-imagegallery-title" v-html="item.title"></h5>
						</div>
					</VueSlickCarousel>
					<VueSlickCarousel
						class="psc-cb-imagegallery-thumbslist"
						:ref="'thumbImage' + SortOrder"
						:asNavFor="$refs['largeImage' + SortOrder]"
						:slidesToShow="
							Settings.items.length > 10 ? 10 : Settings.items.length - 1
						"
						:focusOnSelect="true"
					>
						<div class="psc-cb-imagegallery-thumb" v-for="(item, i) in Settings.items" :key="SortOrder + i">
							<!-- <img :src="item.imagePath ? item.imagePath: require('@/assets/img/examples/card-blog3.jpg')">  -->
							<span :style="{ backgroundImage: 'url('+item.imagePath+')' }"></span>
						</div>
					</VueSlickCarousel>
			</div><!--.preset3-->

			<button
				:id="'imgs' + SortOrder"
				class="psc-cb-button btn-open-modal"
				data-toggle="modal"
				:data-target="'#modal-fullscreen' + SortOrder"
				@click="openModal($event)"
			>
				<i class="fa fa-expand-alt"></i> Fullscreen
			</button>

			<div
				v-if="isModalOpen"
				class="fade modal modal-fullscreen modal_open psc-cb-imagegallery-modal"
				:id="'modal-fullscreen' + SortOrder"
				tabindex="-1"
				role="dialog"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-body">
							<button type="button" class="close closeBtn" data-dismiss="modal" aria-hidden="true">
								<i class="fa fa-times"></i>
							</button>
							<div
								class="modal-carousels"
								v-if="Settings.items && Settings.items.length > 0 && isModalOpen"
							>
								<VueSlickCarousel
									class="modal-carousel-slide"
									:ref="'gallery'"
									:options="topSliderOptions"
									@beforeChange="syncSliders"
									:focusOnSelect="true"
								>
									<div
										v-for="(item, i) in Settings.items"
										:key="'For' + SortOrder + i"
										class="psc-cb-imagegallery-modal-carousel-slide"
									>
										<img :src="item.imagePath" />
										<div v-html="item.title"></div>
									</div>
								</VueSlickCarousel>
								<VueSlickCarousel
									class="modal-thumbslist"
									:ref="'featureList'"
									:options="bottomSliderOptions"
									:slidesToShow="Settings.items.length > 10 ? 10 : Settings.items.length - 1"
									@beforeChange="syncSliders"
									:focusOnSelect="true"
								>
									<div
										v-for="(item, i) in Settings.items"
										:key="'For' + SortOrder + i"
										class="psc-cb-imagegallery-modal-carousel-thumb"
									>
										<!--img :id="'thumbImg' + SortOrder + i" :src="item.imagePath" /-->
										<span :style="{ backgroundImage: 'url('+item.imagePath+')' }"></span>
									</div>
								</VueSlickCarousel>
							</div>
						</div>
					</div>
				</div>
			</div>  <!--.modal-->
		</div>
		<div class="psc-cb-ipart-blocklink" v-if="Settings.rowSetting.blockBtnLinkPath">
			<a :target="Settings.rowSetting.buttonLinkTarget==2?'_blank':''" :class="Settings.rowSetting.blockClass" :href="Settings.rowSetting.blockBtnLinkPath?Settings.rowSetting.blockBtnLinkPath+(Settings.rowSetting.buttonSource==2&& Settings.rowSetting.hasOwnProperty('blockBtnLinkParameters')?Settings.rowSetting.blockBtnLinkParameters:''):'javascript:void(0)'" class="psc-cb-button">{{Settings.rowSetting.blockLabel}}</a>
		</div>
  	</div>
</template>

<script>

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
	name: "Gallery",
	components: { VueSlickCarousel },
	props:{
		Settings: Object,
		licenseInfo: Object,
		SortOrder: Number,
	},
	data() {
		return {
			topSliderOptions: {
				asNavFor: this.$refs.featureList,
			},
			bottomSliderOptions: {
				slidesToShow: 8,
				asNavFor: this.$refs.gallery,
			},
			getAllData: false,
			isModalOpen: false,
			preset: this.Settings.toolType ? this.Settings.toolType : "",
		};
	},
	mounted(){
		setTimeout(() => {
		this.getAllData = true;
			setTimeout(() => {
				this.isModalOpen = true;
			}, 1000);
		}, 1000);
	},
	methods:{
		openModal(event){
			event.preventDefault();
		},
		openImageMod(i){
			if(document.getElementById("imgs" + this.SortOrder)) {
				document.getElementById("imgs" + this.SortOrder).click();
				document.getElementById("thumbImg" + this.SortOrder + i).click();
				// let ele= document.getElementsByClassName("secCont2")
				// ele.forEach(element=>{
				//   element.style.maxWidth =
				//   125 * 10 + "px";
				// })
			}
		},
		syncSliders(currentPosition, nextPosition){
			if(this.$refs.featureList){
				this.$refs.featureList.asNavFor = this.$refs.gallery;
				this.$refs.gallery.asNavFor = this.$refs.featureList;
			}
		},
	},
};
</script>