<template>
	<div class="psc-cb-ipart-container" :style="{backgroundColor:Settings.rowSetting.background.contBackGroundCol}">
		<div class="section-overlays" :class="Settings.rowSetting.width==2?'textWidth':Settings.rowSetting.width==1?'fullWidth':'inner'">
			<div class="psc-cb-overlays-container preset1">
				<div class="psc-cb-introbar">
          <h2 class="psc-cb-overlays-title" v-html="Settings.mainHeading"></h2>
          <div class="psc-cb-overlays-intro" v-html="Settings.mainHeadingContext"></div>
        </div>
        <ul class="psc-cb-overlays">
          <li v-for="(item, index) of Settings.items" :key="index">
			<div class="psc-cb-overlay-box" @click.prevent="openOverlay(index)">
				<h4 class="psc-cb-overlay-title" v-html="item.title"></h4>
				<div class="psc-cb-overlay-trigger" v-html="item.secondContext"></div>
			</div>
            <div class="psc-cb-overlay-overlay" :ref="('overlay' + index)">
                <div class="inner">
					<a @click.prevent="closeOverlay(index)"><i class="fa fa-times"></i></a>
					<div class="psc-cb-overlay-long" v-html="(`<h2>${item.title}</h2>` + item.context)"></div>
				</div>
            </div>
          </li>
        </ul>
			</div>
		</div>
    <div class="psc-cb-ipart-blocklink" v-if="Settings.rowSetting.blockBtnLinkPath">
      <a :target="Settings.rowSetting.buttonLinkTarget==2?'_blank':''" :class="Settings.rowSetting.blockClass" :href="Settings.rowSetting.blockBtnLinkPath?Settings.rowSetting.blockBtnLinkPath+(Settings.rowSetting.buttonSource==2 && Settings.rowSetting.hasOwnProperty('blockBtnLinkParameters')?Settings.rowSetting.blockBtnLinkParameters:''):'javascript:void(0)'" class="psc-cb-button">{{Settings.rowSetting.blockLabel}}</a>
    </div>
 	</div>
</template>

<script>

export default {
  name: "Overlays",
  components: {},
  props: {
    Settings: Object,
  },
  data() {
    return {
     
     };
  },
  mounted() {
  },
  methods: {
    openOverlay(i){
      let overlaykey	=	'overlay' + i;
      let overlay		=	this.$refs[overlaykey];
      overlay[0].classList.add('active');
	  return;
    }
    ,closeOverlay(i){
      let overlaykey	=	'overlay' + i;
      let overlay		=	this.$refs[overlaykey];
      overlay[0].classList.remove('active');
	  return;
    }
  },
};
</script>
<!--
<style lang="scss" scoped>
.psc-cb-root .psc-cb-overlays-container{ display: block; overflow: hidden; }
		.psc-cb-root .psc-cb-overlays-container .psc-cb-overlays{ display: flex; align-items: center; justify-content: center; gap: 1rem 4.5%; flex-wrap: wrap; }
			.psc-cb-root .psc-cb-overlays-container .psc-cb-overlays>li{ display: block; width: 30%; }
				.psc-cb-root .psc-cb-overlays-container .psc-cb-overlays.cardsperrow-one{ display: block; }
					.psc-cb-root .psc-cb-overlays-container .psc-cb-overlays.cardsperrow-one>li{ width: 100%; }
				.psc-cb-root .psc-cb-overlays-container .psc-cb-overlays.cardsperrow-two{ gap: 4%; }
					.psc-cb-root .psc-cb-overlays-container .psc-cb-overlays.cardsperrow-two>li{ width: 48%; }
				.psc-cb-root .psc-cb-overlays-container .psc-cb-overlays.cardsperrow-four{ gap: 4%; }
					.psc-cb-root .psc-cb-overlays-container .psc-cb-overlays.cardsperrow-four>li{ width: 24%; }
				.psc-cb-root .psc-cb-overlays-container .psc-cb-overlays.cardsperrow-five{ gap: 2.5%; }
					.psc-cb-root .psc-cb-overlays-container .psc-cb-overlays.cardsperrow-five>li{ width: 18%; }
				.psc-cb-root .psc-cb-overlays-container .psc-cb-overlays>li .psc-cb-overlay-trigger{ display: block; padding: 2rem; background-color: var(--box-bg); color: var(--colour-default-fg); border-radius: var(--box-border-radius); box-shadow: var(--box-shadow); overflow: hidden; text-align: var(--card-align); }
				.psc-cb-root .psc-cb-overlays-container .psc-cb-overlays>li .psc-cb-overlay-overlay{ position: fixed; inset: 0; z-index: 1000000000; display: flex; align-items: center; justify-content: center; background-color: #fff; color: var(--overlay-fg); opacity: 0; transform: scale(0); transition: all 0.6s; }
				.psc-cb-root .psc-cb-overlays-container .psc-cb-overlays>li .psc-cb-overlay-overlay>a{ position: absolute; right: 0%; top: 0%;}
					.psc-cb-root .psc-cb-overlays-container .psc-cb-overlays>li .psc-cb-overlay-overlay.active{ opacity: 1; transform: scale(1); }
		
</style>
-->
