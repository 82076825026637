<template>
	<div class="psc-cb-ipart-container" :class="{'animate':Settings.rowSetting.animation && Settings.rowSetting.animation!=0}" :data-fx="Settings.rowSetting.animation" :style="{backgroundColor:Settings.rowSetting.background.contBackGroundCol}">
		<div class="section-cards" :class="Settings.rowSetting.width==2?'textWidth':Settings.rowSetting.width==1?'fullWidth':'inner'">
			<div v-if="preset==1" class="psc-cb-card-container preset1">
				<h2 class="psc-cb-card-title" v-html="Settings.mainHeading" v-if="Settings.mainHeading&&Settings.mainHeading.length"></h2>
				<div  class="psc-cb-card-intro" v-html="Settings.mainHeadingContext" v-if="Settings.mainHeadingContext&&Settings.mainHeadingContext.length"> </div>
				<div class="psc-cb-cards" :class="rowClasses[Settings.cardsPerRow]">
					<div  v-for="(item, index) of  Settings.items"  :key="index" class="psc-cb-cards-card" :id="getDynamicId(index)">
						<a :class="item.buttonClass" :target="item.buttonLinkTabOption==2?'_blank':''" :href="item&&item.btnLinkPath?item.btnLinkPath+ (item.itemButtonSource==2&& item.hasOwnProperty('btnLinkParameters')?item.btnLinkParameters:''):'javascript:void(0)'" v-if="item && item.btnLinkPath" class="psc-cb-boxlink">
							<span class="psc-cb-boxlink-image" :style="'background-image: url('+item.imagePath+');'"></span>
							<span class="psc-cb-boxlink-title" v-html="item.title"></span>
						</a>
					</div>
				</div>
			</div>

			<div v-if="preset==2" class="psc-cb-card-container preset2 horizontal">
				<h2 class="psc-cb-card-title" v-html="Settings.mainHeading" v-if="Settings.mainHeading&&Settings.mainHeading.length"></h2>
				<div  class="psc-cb-card-intro" v-html="Settings.mainHeadingContext" v-if="Settings.mainHeadingContext&&Settings.mainHeadingContext.length"> </div>
				<div class="psc-cb-cards" :class="rowClasses[Settings.cardsPerRow]">
						<blog-card 
							v-for="(item, index) of Settings.items" 
							:key="index" 
							card-plain 
							:id="getDynamicId(index)"
							type="horizontal" 
							:shadow-normal="false" 
							:no-colored-shadow="false" 
							:card-image="item.imagePath"
							:card-url="item&&item.btnLinkPath?item.btnLinkPath:'#'"
						>
				<!-- reverse-row -->
							<template slot="cardContent">
								<span class="psc-cb-card-subheading" v-html="item.optionalHeading"></span>
								<h4 class="psc-cb-card-heading" v-html="item.title"></h4>
								<div class="psc-cb-card-details">
									<span class="psc-cb-card-description" v-html="item.context"></span>
									<a :target="item.buttonLinkTabOption==2?'_blank':''" :class="getButtonClass(item)" :href="item && item.btnLinkPath ? item.btnLinkPath + (item.itemButtonSource==2&& item.hasOwnProperty('btnLinkParameters')?item.btnLinkParameters:'') : 'javascript:void(0)'" class="psc-cb-button" v-if="item && item.btnLinkPath">{{ item.buttonLabel }}</a>
								</div>
							</template>
						</blog-card>
				</div>
			</div>

			<div v-if="preset==3" class="psc-cb-card-container preset3 vertical">
				<h2 class="psc-cb-card-title" v-html="Settings.mainHeading" v-if="Settings.mainHeading&&Settings.mainHeading.length"></h2>
				<div  class="psc-cb-card-intro" v-html="Settings.mainHeadingContext" v-if="Settings.mainHeadingContext&&Settings.mainHeadingContext.length"> </div>
				<div class="psc-cb-cards" :class="rowClasses[Settings.cardsPerRow]">
				<blog-card 
					v-for="(item, index) of Settings.items" 
					:key="index" 
					card-plain 
					:id="getDynamicId(index)"
					type="vertical" 
					:shadow-normal="false" 
					:no-colored-shadow="false" 
					:card-image="item.imagePath"
					:card-url="item && item.btnLinkPath ? item.btnLinkPath + (item.itemButtonSource==2&& item.hasOwnProperty('btnLinkParameters')?item.btnLinkParameters:'') : 'javascript:void(0)'"
					:card-href="item && item.btnLinkPath ? item.btnLinkPath + (item.itemButtonSource==2&& item.hasOwnProperty('btnLinkParameters')?item.btnLinkParameters:'') : 'javascript:void(0)'"
					:card-target="item.buttonLinkTabOption==2?'_blank':''"
				>
					<template slot="cardContent">
					<span class="psc-cb-card-subheading" v-html="item.optionalHeading"></span>
					<h4 class="psc-cb-card-heading" v-html="item.title"></h4>
					<div class="psc-cb-card-details">
						<span class="psc-cb-card-description" v-html="item.context"></span>
						<a :target="item.buttonLinkTabOption==2?'_blank':''" :class="getButtonClass(item)" :href="item && item.btnLinkPath ? item.btnLinkPath + (item.itemButtonSource==2&& item.hasOwnProperty('btnLinkParameters')?item.btnLinkParameters:'') : 'javascript:void(0)'" class="psc-cb-button" v-if="item && item.btnLinkPath">{{ item.buttonLabel }}</a>
					</div>
					</template>
				</blog-card>
				</div>
			</div>

			<div v-if="preset==4" class="psc-cb-card-container preset4 horizontal">
				<h2 class="psc-cb-card-title" v-html="Settings.mainHeading" v-if="Settings.mainHeading&&Settings.mainHeading.length"></h2>
				<div  class="psc-cb-card-intro" v-html="Settings.mainHeadingContext" v-if="Settings.mainHeadingContext&&Settings.mainHeadingContext.length"> </div>
						<div class="psc-cb-cards" :class="rowClasses[Settings.cardsPerRow]">
				<blog-card 
							v-for="(item, index) of Settings.items" 
							:key="index" 
							:indexNo="index"
							card-plain 
							:id="getDynamicId(index)"
							type="horizontal" 
							:toolType=preset
							:shadow-normal="false" 
							:no-colored-shadow="false" 
							:card-image="item.imagePath"
							:card-url="item&&item.btnLinkPath?item.btnLinkPath:'#'"
						>
							<template slot="cardContent">
								<span class="psc-cb-card-subheading" v-html="item.optionalHeading"></span>
								<h4 class="psc-cb-card-heading" v-html="item.title"></h4>
								<div class="psc-cb-card-details">
									<span class="psc-cb-card-description" v-html="item.context"></span>
									<a :target="item.buttonLinkTabOption==2?'_blank':''" :class="getButtonClass(item)" :href="item && item.btnLinkPath ? item.btnLinkPath + (item.itemButtonSource==2&& item.hasOwnProperty('btnLinkParameters')?item.btnLinkParameters:'') : 'javascript:void(0)'" class="psc-cb-button" v-if="item && item.btnLinkPath">{{ item.buttonLabel }}</a>
								</div>
							</template>
						</blog-card>
				</div>
			</div>

			<div v-if="preset==5" class="psc-cb-card-container preset5 vertical">
				<h2 class="psc-cb-card-title" v-html="Settings.mainHeading" v-if="Settings.mainHeading&&Settings.mainHeading.length"></h2>
				<div  class="psc-cb-card-intro" v-html="Settings.mainHeadingContext" v-if="Settings.mainHeadingContext&&Settings.mainHeadingContext.length"> </div>
						<div class="psc-cb-cards" :class="rowClasses[Settings.cardsPerRow]">
				<blog-card
							v-for="(item, index) of Settings.items"
							:key="index"
							card-plain
							:id="getDynamicId(index)"
							type="vertical" 
							:shadow-normal="false"
							:no-colored-shadow="false"
							:card-image="item.imagePath"
							:cardurl="item&&item.btnLinkPath?item.btnLinkPath:'#'"
						>
							<template slot="cardContent">
								<span class="psc-cb-card-subheading" v-html="item.optionalHeading"></span>
								<h4 class="psc-cb-card-heading" v-html="item.title"></h4>
								<div class="psc-cb-card-details">
									<div class="psc-cb-card-description" v-html="item.context"></div>
									<a :target="item.buttonLinkTabOption==2?'_blank':''" :class="getButtonClass(item)" :href="item && item.btnLinkPath ? item.btnLinkPath + (item.itemButtonSource==2&& item.hasOwnProperty('btnLinkParameters')?item.btnLinkParameters:'') : 'javascript:void(0)'" class="psc-cb-button" v-if="item && item.btnLinkPath">{{ item.buttonLabel }}</a>
								</div>
							</template>
						</blog-card>
				</div>
			</div>
		</div>
		<div class="psc-cb-ipart-blocklink" v-if="Settings.rowSetting.blockBtnLinkPath">
			<a :target="Settings.rowSetting.buttonLinkTarget==2?'_blank':''" :class="Settings.rowSetting.blockClass" :href="Settings.rowSetting.blockBtnLinkPath?Settings.rowSetting.blockBtnLinkPath+(Settings.rowSetting.buttonSource==2&& Settings.rowSetting.hasOwnProperty('blockBtnLinkParameters')?Settings.rowSetting.blockBtnLinkParameters:''):'javascript:void(0)'" class="psc-cb-button">{{Settings.rowSetting.blockLabel}}</a>
		</div>
  </div>
</template>

<script>
import { BlogCard } from "@/components";
import ImisCkEditor from '../../components/ui/ImisCkEditor.vue';
import common from "../../mtapi/common";

export default {
  name: "Card",
  components: {
    BlogCard,
  },
  props: {
    Settings: Object,
  },
  data() {
    return {
      style: {},
      rowClasses:common.getRowClasses(),
      contBackGroundColHex: "rgba(255, 255, 255, 0)",
      isActive:
        this.Settings.toolType != undefined && this.Settings.toolType !== ""
          ? false
          : true,
      editorIndex: 0,
      openEditorFlag: false,
      mdIcon:
        '<i class="md-icon md-icon-font md-tab-icon md-theme-default fas fa-users"></i>',
      preset:
        this.Settings.toolType != undefined && this.Settings.toolType !== ""
          ? this.Settings.toolType
          : "",
      context: "",
      mainHeading: {
        title: [
          "Autodesk looks to future of 3D printing with Project Escher",
          "Lyft launching cross-platform service this week",
          "6 insights into the French Fashion landscape",
        ],
        icon: ["fa fa-users", "far fa-smile", "fa fa-search"],
      },
      editorData: '',
      editorConfig: {        
        iMIS: {
          allowedBlockAttributes: ['id', 'class', 'data-test']
        }
      },
      configForm: {
        showIcon:
          (this.Settings && this.Settings.showIcon) ||
          this.Settings.showIcon == 0
            ? this.Settings.showIcon
            : 1,
        iconPostion: this.Settings.iconPostion || 0,
        iconSize: this.Settings.iconSize || "30px",
        openTab: this.Settings.openTab || 0,
        items:this.Settings.items,
        rowSetting:
          this.Settings && this.Settings.rowSetting
            ? this.Settings.rowSetting
            : {
                spacing: {
                  margin: {
                    t: 0,
                    r: 0,
                    b: 0,
                    l: 0,
                    ratio: "px",
                  },
                  padding: {
                    t: 0,
                    r: 0,
                    b: 0,
                    l: 0,
                    ratio: "px",
                  },
                },
                themes: {
                  // name: 'light',
                  primaryColor: "red",
                  classes: "",
                },
                background: {
                  backGroundCol: "",
                  imageParallex: false,
                  selectBackground: 0,
                  mainColor: "",
                  contBackGroundCol: "rgba(255, 255, 255, 0)",
                  backgroundStyle: 0,
                  imagePath: "",
                  imageKey: "",
                  textCol: "",
                  tabCol: "",
                  opacity: 50,
                },
              },
      },
    };
  },
  methods:{
	getDynamicId(i){
		if(this.Settings.rowSetting.id){
			return this.Settings.rowSetting.id + '-' + i
		}
		return ''
	},
	getButtonClass(item){
		let btnclass = item.buttonLinkTabOption==2?' psc-cb-new-tab ':''
		if(item.buttonClass){
			btnclass += ' '+ item.buttonClass
		}
      return btnclass 
    },
    getHeightCard(){
     
      let height= {height: this.Settings && this.Settings.cardHeight  ? this.Settings.cardHeight+'px' : '100px',
                  overflow: 'hidden'
                  }
                 
      return height;
    },
    getLineCard(){
      let height= {
                  "-webkit-line-clamp": this.Settings && this.Settings.cardLines  ? this.Settings.cardLines : 4
                  }
                 
      return height;
    }
  }
 };
</script>