<template>
	<div class="psc-cb-ipart-container" :style="{backgroundColor:Settings.rowSetting.background.contBackGroundCol}">
		<div class="section-features" :class="Settings.rowSetting.width==2?'textWidth':Settings.rowSetting.width==1?'fullWidth':'inner'">
			<div class="psc-cb-features-container" :class="'preset'+preset">
				<h2 class="psc-cb-features-title psc-cb-ipart-title" v-html="Settings.mainHeading" v-if="Settings.mainHeading&&Settings.mainHeading.length"></h2>
				<div class="psc-cb-features-description psc-cb-ipart-intro" v-html="Settings.mainHeadingContext" v-if="Settings.mainHeadingContext&&Settings.mainHeadingContext.length"></div>
				<div class="psc-cb-features-mainimage" v-if="preset==4 || preset==5" :style="{backgroundImage:'url('+Settings.mainImagePath+')'}"></div>
				<div class="psc-cb-features-cards" :class="rowClasses[Settings.cardsPerRow]">
					<h2 class="psc-cb-features-title" v-html="Settings.mainHeading" v-if="(preset==4 || preset==5) && Settings.mainHeading&&Settings.mainHeading.length"></h2>
					<div class="psc-cb-features-card"
						v-for="(item, index) of Settings.items"
						:key="index"
					>
						<div class="psc-cb-features-card-image" v-if="preset!=4 || preset!=5">
							<div :class="iconClasses[Settings.iconSize]"><i :class="item.icon"></i></div>
						</div>
						<div class="psc-cb-features-card-details">
							<div class="psc-cb-features-card-title" v-if="preset!=4 || preset!=5" v-html="item.title"></div>
							<div class="psc-cb-features-card-title" v-if="preset==4 || preset==5">
								<i class="psc-cb-features-card-title-icon" :class="item.icon + ' '+ iconClasses[Settings.iconSize]"></i>
								<span class="psc-cb-features-card-title-label" v-html="item.title"></span>
							</div>
							<div class="psc-cb-features-card-description" v-html="item.context"></div>
							<div class="psc-cb-features-card-action" v-if="preset!=4 || preset!=5">
								<a :target="item.buttonLinkTabOption==2?'_blank':''" :class="getButtonClass(item)" v-if="!item.btnLink" :href="item.btnLinkPath + (item.itemButtonSource==2&& item.hasOwnProperty('btnLinkParameters')?item.btnLinkParameters:'')" v-html="item.buttonLabel" class="psc-cb-button" ></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    <div class="psc-cb-ipart-blocklink" v-if="Settings.rowSetting.blockBtnLinkPath">
			<a :target="Settings.rowSetting.buttonLinkTarget==2?'_blank':''" :class="Settings.rowSetting.blockClass" :href="Settings.rowSetting.blockBtnLinkPath?Settings.rowSetting.blockBtnLinkPath+(Settings.rowSetting.buttonSource==2&& Settings.rowSetting.hasOwnProperty('blockBtnLinkParameters')?Settings.rowSetting.blockBtnLinkParameters:''):'javascript:void(0)'" class="psc-cb-button">{{Settings.rowSetting.blockLabel}}</a>
		</div>
  </div>
</template>

<script>
import common from "../../mtapi/common";
export default {
  name: "Features",
  components: { },
  props: {
    Settings: Object,
  },
  data() {
    return {
      rowClasses:common.getRowClasses(),
      iconClasses: common.getClassName(),
      isActive: (this.Settings.toolType || this.Settings.toolType == 0) && this.Settings.toolType != "" ? false : true,
      preset: this.Settings.toolType || this.Settings.toolType == 0 ? this.Settings.toolType : "",
      configForm: {
        swapPosition: this.Settings && this.Settings.swapPosition ? this.Settings.swapPosition : "",
        copyAdd: this.Settings && this.Settings.copyAdd ? this.Settings.copyAdd : 0,
        mainHeading: this.Settings && this.Settings.mainHeading ? this.Settings.mainHeading : common.getDefaultConfigLabel(9).mainHeading,
        mainHeadingContext: this.Settings && this.Settings.mainHeadingContext ? this.Settings.mainHeadingContext : common.getDefaultConfigLabel(9).mainHeadingContext,
        showIcon: (this.Settings && this.Settings.showIcon) || this.Settings.showIcon == 0 ? this.Settings.showIcon : 1,
        iconPostion: this.Settings.iconPostion || 0,
        iconSize: this.Settings.iconSize || "medium",
        openTab: this.Settings.openTab || 0,
        items:
          this.Settings && this.Settings.items
            ? this.Settings.items
            : [
                {
                  buttonLabel: "Learn More",
                  title: "Profile",
                  icon: "fas fa-users",
                  context: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
                },
                {
                  buttonLabel: "Learn More",
                  title: "Message",
                  icon: "far fa-smile",
                  context: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
                },
                {
                  buttonLabel: "Learn More",
                  title: "Setting",
                  icon: "fa fa-search",
                  context: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
                },
              ],
        rowSetting:
          this.Settings && this.Settings.rowSetting
            ? this.Settings.rowSetting
            : {
                spacing: {
                 margin: { t: 0, r: 0, b: 0, l: 0, ratio: "px!important", },
                 padding: { t: 0, r: 0, b: 0, l: 0, ratio: "px!important", },
                },
                themes: {
                  primaryColor: "red",
                  classes: "",
                },
                background: {
                  backGroundCol: "",
                  imageParallex: false,
                  selectBackground: 0,
                  mainColor: "",
                  contBackGroundCol: "rgba(255, 255, 255, 0)",
                  backgroundStyle: 0,
                  imagePath: "",
                  imageKey: "",
                  textCol: "",
                  tabCol: "",
                  opacity: 50,
                },
              },
        toolType: this.Settings && this.Settings.toolType ? this.Settings.toolType : "",
        toolIndex: 9,
      },
    };
  },
  mounted() {
    this.updateValue();
  },
  methods: {
    getButtonClass(item){
      let btnclass = item.buttonLinkTabOption==2?' psc-cb-new-tab ':''
      if(item.buttonClass){
        btnclass += ' '+ item.buttonClass
      }
      return btnclass 
    },
    selectPreset(i) {
      this.isActive = false;
      this.preset = i;
    },
    updateValue() {
    },
  },
};
</script>
