<template>
  <div class="psc-cb-ipart-container" :style="{backgroundColor:Settings.rowSetting.background.contBackGroundCol}">
    <div class="section-header">
      <!-- Banners 1 -->
      <div v-if="isActive||preset==1" class="psc-cb-header preset1" 
        :style="{'min-height': Settings.cardHeight+'px', backgroundImage: 'url('+Settings.items[0]['imagePath']+')', 'background-position-y': Settings.items[0]['imgPosY']+'px'}" 
        :class="{'parallax':Settings.items[0].imageParallex}"
      >
        <div
          class="psc-cb-header-content"
          :class="`position${item.swapPosition} align${item.textAlign}`"
          v-for="(item, index) of Settings.items"
          :key="index"
          :style="{color: item.fontColor}"
        >
          <h2 v-html="item.title"></h2>
          <div class="description" v-html="item.context"></div>
          <a :class="item.buttonClass" :target="item.buttonLinkTabOption==2?'_blank':''" v-if="item&&item.btnLinkPath" :href="item.btnLinkPath +(item.itemButtonSource==2&& item.hasOwnProperty('btnLinkParameters')?item.btnLinkParameters:'')" class="psc-cb-button" v-html="item.buttonLabel"></a>
        </div>
      </div>
      <!-- end Banners 1 -->

      <!-- Banners 2 -->
      <div v-if="isActive || preset == 2" class="psc-cb-header-container">
        <carousel :per-page="1" :navigationEnabled="true" :mouse-drag="false" :paginationPadding="3"
          indicators="hover" :navigationNextLabel="`<i class='fas fa-angle-right side-icon-size'></i>`"
          :navigationPrevLabel="`<i class='fas fa-angle-left side-icon-size'></i>`"
          :navigationClickTargetSize="8"
          :paginationActiveColor="Settings.primaryColor? Settings.primaryColor: 'var(--brand-2-fg)'"
          :paginationColor="Settings.primaryColor? Settings.primaryColor: 'var(--brand-2-fg)'"
          :autoplay=true :autoplayTimeout="Settings.sliderTime" :loop="true"
          :mouseDrag="true" :speed="Settings.sliderSpeed">
          <template v-for="(item, index) of Settings.items" >
            <slide :key="index">
              <div class="psc-cb-header preset2" :style="{'min-height': Settings.cardHeight+'px', backgroundImage: 'url('+item['imagePath']+')', 'background-position-y': item['imgPosY']+'px'}" 
                :class="{'parallax':item.imageParallex}">
                <div
                  class="psc-cb-header-content"
                  :class="`position${item.swapPosition} align${item.textAlign}`"
                  :style="{color: item.fontColor}"
                >
                  <h2 v-html="item.title"></h2>
                  <div class="description" v-html="item.context"></div>
                  <a :class="item.buttonClass" :target="item.buttonLinkTabOption==2?'_blank':''" v-if="item&&item.btnLinkPath" :href="item.btnLinkPath +(item.itemButtonSource==2&& item.hasOwnProperty('btnLinkParameters')?item.btnLinkParameters:'')" v-html="item.buttonLabel"></a>
                </div>
              </div>
            </slide>
          </template>
        </carousel>
      </div>
      <!-- end Banners 2 -->
    </div>
    <div class="psc-cb-ipart-blocklink" v-if="Settings.rowSetting.blockBtnLinkPath">
      <a :target="Settings.rowSetting.buttonLinkTarget==2?'_blank':''" :class="Settings.rowSetting.blockClass" :href="Settings.rowSetting.blockBtnLinkPath?Settings.rowSetting.blockBtnLinkPath+(Settings.rowSetting.buttonSource==2&& Settings.rowSetting.hasOwnProperty('blockBtnLinkParameters')?Settings.rowSetting.blockBtnLinkParameters:''):'javascript:void(0)'" class="psc-cb-button">{{Settings.rowSetting.blockLabel}}</a>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import common from "../../mtapi/common";
export default {
  name: "Banners",
  components: { Carousel,
    Slide,},
  props: { Settings: Object,},
  data() {
    return {
      companyName: null,
      companyEmail: null,
      companyPassword: null,
      toggledClass: false,
      style: {},
      contBackGroundColHex: "rgba(255, 255, 255, 0)",
      defaultItem: common.getDefaultItems(5),
      isActive: this.Settings.toolType && this.Settings.toolType != "" ? false : true,
      editorIndex: 0,
      openEditorFlag: false,
      mdIcon: '<i class="md-icon md-icon-font md-tab-icon md-theme-default fas fa-users"></i>',
      preset: this.Settings.toolType ? this.Settings.toolType : "",
      context: "",
      mainHeading: {
        title: [ "Autodesk looks to future of 3D printing with Project Escher", "Lyft launching cross-platform service this week", "6 insights into the French Fashion landscape", ],
        icon: ["fa fa-users", "far fa-smile", "fa fa-search"],
      },
      editorData: '',
      editorConfig: { iMIS: { allowedBlockAttributes: ['id', 'class', 'data-test'] } },
      configForm: {
        sliderTime:this.Settings && this.Settings.sliderTime ? this.Settings.sliderTime : 5000,
        cardHeight: this.Settings && this.Settings.cardHeight ? this.Settings.cardHeight : "500",
        swapPosition: this.Settings && this.Settings.swapPosition ? this.Settings.swapPosition : 5,
        textAlign: this.Settings && this.Settings.textAlign ? this.Settings.textAlign : 4,
        mainHeading: this.Settings && this.Settings.hasOwnProperty('mainHeading') ? this.Settings.mainHeading : common.getDefaultConfigLabel(5).mainHeading,
        mainButtonLabel: this.Settings && this.Settings.mainButtonLabel ? this.Settings.mainButtonLabel : common.getDefaultConfigLabel(5).mainButtonLabel,
        mainHeadingContext: this.Settings && this.Settings.hasOwnProperty('mainHeadingContext') ? this.Settings.mainHeadingContext : common.getDefaultConfigLabel(5).mainHeadingContext,
        showIcon: (this.Settings && this.Settings.showIcon) || this.Settings.showIcon == 0 ? this.Settings.showIcon : 1,
        iconPostion: this.Settings.iconPostion || 0,
        iconSize: this.Settings.iconSize || "medium",
        openTab: this.Settings.openTab || 0,
        items: this.Settings && this.Settings.items ? this.Settings.items
            : [
                {
                  buttonLabel: "Read More",
                  optionalHeading: "ENTERPRISE",
                  title: "Profile",
                  icon: "fas fa-users",
                  image: 'https://api.pseudocode.com.au/imgsrc/?r=1',
                  imageKey: "",
                  context: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
                },
                {
                  buttonLabel: "Read More",
                  optionalHeading: "ENTERPRISE",
                  title: "Message",
                  icon: "far fa-smile",
                  image: 'https://api.pseudocode.com.au/imgsrc/?r=2',
                  context: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
                },
                {
                  buttonLabel: "Read More",
                  optionalHeading: "ENTERPRISE",
                  title: "Setting",
                  icon: "fa fa-search",
                  image: 'https://api.pseudocode.com.au/imgsrc/?r=3',
                  context: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
                },
              ],
        rowSetting:
          this.Settings && this.Settings.rowSetting
            ? this.Settings.rowSetting
            : {
                spacing: {
                 margin: { t: 0, r: 0, b: 0, l: 0, ratio: "px!important", },
                 padding: { t: 0, r: 0, b: 0, l: 0, ratio: "px!important", },
                },
                themes: {
                  primaryColor: "red",
                  classes: "",
                },
                background: {
                  backGroundCol: "",
                  imageParallex: false,
                  selectBackground: 0,
                  mainColor: "",
                  contBackGroundCol: "rgba(255, 255, 255, 0)",
                  backgroundStyle: 0,
                  imagePath: "",
                  imageKey: "",
                  textCol: "",
                  tabCol: "",
                  opacity: 50,
                },
              },
        toolType: this.Settings && this.Settings.toolType ? this.Settings.toolType : "",
        toolIndex: 5,
      },
    };
  },
  mounted() {
    this.updateValue();
  },
  methods: {
    header(nr) {
      let css = {
        backgroundImage: `url(${ this.Settings.items[nr]['imagePath'] })`,
        minHeight: this.Settings.cardHeight && this.Settings.cardHeight > 200 ? this.Settings.cardHeight + "px!important" : "200px!important",
        maxHeight: this.Settings.cardHeight && this.Settings.cardHeight > 200 ? this.Settings.cardHeight + "px!important" : "200px!important",
        backgroundPosition: this.configForm.toolType? this.Settings.items[nr]["imgPosX"] +"px " +this.Settings.items[nr]["imgPosY"] +"px!important": "0px0px",
        backgroundRepeat: "no-repeat",
        backgroundSize: this.configForm.toolType ? '100%' : '100%',
        backgroundAttachment: this.Settings.imageParallex ? "fixed" : "",
      };
      return css;
    },
    bodyClick() {
      let bodyClick = document.getElementById("bodyClick");
      if (bodyClick === null) {
        let body = document.querySelector("body");
        let elem = document.createElement("div");
        elem.setAttribute("id", "bodyClick");
        body.appendChild(elem);
        let bodyClick = document.getElementById("bodyClick");
        bodyClick.addEventListener("click", this.toggleNavbarMobile);
      } else {
        bodyClick.remove();
      }
    },
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
      this.bodyClick();
    },

    selectPreset(i) {
      this.isActive = false;
      this.preset = i;
   
    },
    updateValue() {
      this.$parent.$emit("configForm", this.configForm);
    },

    getHeightCard() {
      let height = {
        height: this.Settings && this.Settings.cardHeight ? this.Settings.cardHeight + "px" : "100px",
        overflow: "hidden",
      };
      return height;
    },
    getLineCard() {
      let height = { "-webkit-line-clamp": this.Settings && this.Settings.cardLines ? this.Settings.cardLines : 4,};
      return height;
    },
  },
};
</script>
