<template>
  <div>
    <!-- <div v-if="themePath">
      <link rel="stylesheet" :href="themePath" />
    </div> -->

	<link v-if="Settings.rowSetting.externalThemeCss&&Settings.rowSetting.externalThemeCss.length" rel="stylesheet" :href="Settings.rowSetting.externalThemeCss" />
    <div class="main-containers" v-bind:class="getMainClasses()" v-bind:style="style">
      <Tab
        v-if="Settings.toolIndex == 0 || Settings.toolIndex == undefined"
        v-bind:Settings="Settings"
        :class="getDynamicClass()"
        :id="getDynamicId()"
      />
      <Accordion
        v-if="Settings.toolIndex == 1"
        v-bind:Settings="Settings"
        :class="getDynamicClass()"
        :id="getDynamicId()"
      />
       <Texts
        v-if="Settings.toolIndex == 2"
        v-bind:Settings="Settings"
        :class="getDynamicClass()"
        :id="getDynamicId()"
      />
      <Testimonial
        v-if="Settings.toolIndex == 3"
        v-bind:Settings="Settings"
        :class="getDynamicClass()"
        :id="getDynamicId()"
      />
      <Card
        v-if="Settings.toolIndex == 4"
        v-bind:Settings="Settings"
        :class="getDynamicClass()"
        :id="getDynamicId()"
      />
      <Banners
        v-if="Settings.toolIndex == 5"
        v-bind:Settings="Settings"
        :class="getDynamicClass()"
        :id="getDynamicId()"
      />
      <Teams
        v-if="Settings.toolIndex == 7"
        v-bind:Settings="Settings"
        :class="getDynamicClass()"
        :id="getDynamicId()"
      />
      <Pricing
        v-if="Settings.toolIndex == 8"
        v-bind:Settings="Settings"
        :class="getDynamicClass()"
        :id="getDynamicId()"
      />
      <Features
        v-if="Settings.toolIndex == 9"
        v-bind:Settings="Settings"
        :class="getDynamicClass()"
        :id="getDynamicId()"
      />
      <FlipBox
        v-if="Settings.toolIndex == 10"
        v-bind:Settings="Settings"
        :class="getDynamicClass()"
        :id="getDynamicId()"
      />
      <Events
        v-if="Settings.toolIndex == 13"
        v-bind:Settings="Settings"
        :class="getDynamicClass()"
        :id="getDynamicId()"
      />
      <Product
        v-if="Settings.toolIndex == 14 "
        v-bind:Settings="Settings"
        :class="getDynamicClass()"
        :id="getDynamicId()"
      />
     
      <Gallery
        v-if="Settings.toolIndex == 15"
        v-bind:Settings="Settings"
        v-bind:SortOrder="sortIndex"
        
        :class="getDynamicClass()"
        :id="getDynamicId()"
      />
      <Counter
        v-if="Settings.toolIndex == 16 "
        v-bind:Settings="Settings"
        :class="getDynamicClass()"
        :id="getDynamicId()"
      />
      <Badges
        v-if="Settings.toolIndex == 18 "
        v-bind:Settings="Settings"
        :class="getDynamicClass()"
        :id="getDynamicId()"
      />
      <Marquee
        v-if="Settings.toolIndex == 19 "
        v-bind:Settings="Settings"
        :class="getDynamicClass()"
        :id="getDynamicId()"
      />
      <DynamicMarquee
        v-if="Settings.toolIndex == 22 "
        v-bind:Settings="Settings"
        :class="getDynamicClass()"
        :id="getDynamicId()"
      />
      
      <PerspectiveCarousel
        v-if="Settings.toolIndex == 20 "
        v-bind:Settings="Settings"
        :class="getDynamicClass()"
        :id="getDynamicId()"
      />
      <EmbeddedVideo
        v-if="Settings.toolIndex == 21 "
        v-bind:Settings="Settings"
        :class="getDynamicClass()"
        :id="getDynamicId()"
      />
      <Overlays
        v-if="Settings.toolIndex == 23 "
        v-bind:Settings="Settings"
        :class="getDynamicClass()"
        :id="getDynamicId()"
      />
      <FilteredData
       v-if="Settings.toolIndex == 12" 
       :saveSettings="Settings"
       :class="getDynamicClass()"
       :id="getDynamicId()"
      />
      <div class="afterss"></div>
    </div>
  </div>
</template>
<script>
import Tab from "./displayComponent/Tab.vue";
import Accordion from "./displayComponent/Accordion.vue";
import Texts from "./displayComponent/Texts.vue";
import Testimonial from "./displayComponent/Testimonial.vue";
import Features from "./displayComponent/Features.vue";
import Teams from "./displayComponent/Teams.vue";
import Card from "./displayComponent/Card.vue";
import Banners from "./displayComponent/Banners.vue";
import FlipBox from "./displayComponent/FlipBox.vue";
import Pricing from "./displayComponent/Pricing.vue";
import Events from "./displayComponent/Events.vue";
import Product from "./displayComponent/Product.vue";
import Counter from "./displayComponent/Counter.vue";
import Gallery from "./displayComponent/Gallery.vue";
import FilteredData from "./displayComponent/FilteredData.vue";
import Badges from "./displayComponent/Badges.vue";
import Marquee from "./displayComponent/Marquee.vue"
import DynamicMarquee from "./displayComponent/DynamicMarquee.vue"
import Overlays from "./displayComponent/Overlays.vue"
import PerspectiveCarousel from "./displayComponent/PerspectiveCarousel.vue"
import EmbeddedVideo from "./displayComponent/EmbeddedVideo.vue"

export default {
  name: "DisplaySetting",
  components: {
    Tab,
    Accordion,
    Texts,
    Testimonial,
    Card,
    Banners,
    Teams,
    Features,
    FlipBox,
    Pricing,
    Events,
    FilteredData,
    Product,
    Counter,
    Gallery,
    Badges,
    Marquee,
    PerspectiveCarousel,
    EmbeddedVideo,
    DynamicMarquee,
    Overlays
  },
  props: {
    Settings: Object,
    SortOrder: String,
    sortIndex: Number,
  },
  data() {
    return {
      imagePath :window.location.origin,
      style: {},
      configForm: this.Settings,
      themeSelected: this.Settings && this.Settings.themeSelected ? this.Settings.themeSelected : "",
      themePath: this.Settings && this.Settings.themePath ? this.Settings.themePath : "",
    };
  },
  mounted() {
    this.changeStyle();
    this.$nextTick(()=>{
      if(this.Settings.rowSetting.animation){
        // console.log(this.Settings.rowSetting.animation)
      }
    });
  },
  methods: {
    changeStyle() {
      if ( this.configForm && this.configForm.rowSetting && this.configForm.rowSetting.background ) {
        let margin = this.configForm.rowSetting.spacing.margin;
        let padding = this.configForm.rowSetting.spacing.padding;
        this.style = {
          "background-repeat":
            this.configForm.rowSetting.background &&
            this.configForm.rowSetting.background.imagePath ? "no-repeat!important" : "",
          "background-size":
            this.configForm.rowSetting.background &&
            this.configForm.rowSetting.background.imagePath ? "cover!important" : "",
          "background-position":
            this.configForm.rowSetting.background &&
            this.configForm.rowSetting.background.imagePath ? "center!important" : "",
          "margin-top":
            margin.t != 0 ||
            margin.r != 0 ||
            margin.b != 0 ||
            margin.l != 0 ||
            padding.t != 0 ||
            padding.r != 0 ||
            padding.b != 0 ||
            padding.l != 0 ? Number(margin.t) + margin.ratio + "!important" : "",
          "margin-right":
            margin.t != 0 ||
            margin.r != 0 ||
            margin.b != 0 ||
            margin.l != 0 ||
            padding.t != 0 ||
            padding.r != 0 ||
            padding.b != 0 ||
            padding.l != 0 ? Number(margin.r) + margin.ratio + "!important" : "",
          "margin-bottom":
            margin.t != 0 ||
            margin.r != 0 ||
            margin.b != 0 ||
            margin.l != 0 ||
            padding.t != 0 ||
            padding.r != 0 ||
            padding.b != 0 ||
            padding.l != 0 ? Number(margin.b) + margin.ratio + "!important" : "",
          "margin-left":
            margin.t != 0 ||
            margin.r != 0 ||
            margin.b != 0 ||
            margin.l != 0 ||
            padding.t != 0 ||
            padding.r != 0 ||
            padding.b != 0 ||
            padding.l != 0 ? Number(margin.l) + margin.ratio + "!important" : "",

          "padding-top":
            margin.t != 0 ||
            margin.r != 0 ||
            margin.b != 0 ||
            margin.l != 0 ||
            padding.t != 0 ||
            padding.r != 0 ||
            padding.b != 0 ||
            padding.l != 0 ? Number(padding.t) + padding.ratio + "!important" : "",
          "padding-right":
            margin.t != 0 ||
            margin.r != 0 ||
            margin.b != 0 ||
            margin.l != 0 ||
            padding.t != 0 ||
            padding.r != 0 ||
            padding.b != 0 ||
            padding.l != 0 ? Number(padding.r) + padding.ratio + "!important" : "",
          "padding-bottom":
            margin.t != 0 ||
            margin.r != 0 ||
            margin.b != 0 ||
            margin.l != 0 ||
            padding.t != 0 ||
            padding.r != 0 ||
            padding.b != 0 ||
            padding.l != 0 ? Number(padding.b) + padding.ratio + "!important" : "",
          "padding-left":
            margin.t != 0 ||
            margin.r != 0 ||
            margin.b != 0 ||
            margin.l != 0 ||
            padding.t != 0 ||
            padding.r != 0 ||
            padding.b != 0 ||
            padding.l != 0 ? Number(padding.l) + padding.ratio + "!important" : "",
          "background-color":
            this.configForm.rowSetting.background &&
            this.configForm.rowSetting.background.mainColor ? this.configForm.rowSetting.background.mainColor + "!important" : "",
          "background-image":
            this.configForm.rowSetting.background &&
            this.configForm.rowSetting.background.imagePath ? "url("+this.imagePath + this.configForm.rowSetting.background.imagePath.substring(1) + ")!important" : "",
        };
      }
      if ( this.configForm && this.configForm.rowSetting && this.configForm.rowSetting.background.imageParallex ) {
        this.style["background-attachment"] = "fixed!important";
      }
      let themeIndexPath = 0;
      if (this.themePath) {
        themeIndexPath = 1;
      } else {
        themeIndexPath = 0;
      }

      if ( this.configForm && this.configForm.rowSetting && this.configForm.rowSetting.background.imagePath && document.getElementById("ID" + this.SortOrder) && document.getElementById("ID" + this.SortOrder).children && document.getElementById("ID" + this.SortOrder).children[ themeIndexPath ] && document.getElementById("ID" + this.SortOrder).children[themeIndexPath].children[1]) {
        document.getElementById("ID" + this.SortOrder).children[themeIndexPath].children[1].style.position = "absolute";
        document.getElementById("ID" + this.SortOrder).children[themeIndexPath].children[1].style.content = "";
        document.getElementById("ID" + this.SortOrder).children[themeIndexPath].children[1].style.inset = 0;
        document.getElementById("ID" + this.SortOrder).children[themeIndexPath].children[1].style.background =" -webkit-linear-gradient(left," + this.configForm.rowSetting.background.gradient1 + " 0%," + this.configForm.rowSetting.background.gradient2 + " 100%)";
        document.getElementById("ID" + this.SortOrder).children[themeIndexPath].children[1].style.background =" linear-gradient(to right," + this.configForm.rowSetting.background.gradient1 + " 0%," + this.configForm.rowSetting.background.gradient2 + " 100%)";
      } else {
        if (document.getElementById("ID" + this.SortOrder) && document.getElementById("ID" + this.SortOrder).children && document.getElementById("ID" + this.SortOrder).children[themeIndexPath] && document.getElementById("ID" + this.SortOrder).children[themeIndexPath].children[1]) {
          document.getElementById("ID" + this.SortOrder).children[themeIndexPath].children[1].style.position = "unset";
          document.getElementById("ID" + this.SortOrder).children[themeIndexPath].children[1].style.content = "";
          document.getElementById("ID" + this.SortOrder).children[themeIndexPath].children[1].style.inset = "";
        }
      }
      if(this.Settings.rowSetting.inlineThemeCss){
        let stylestr    =   '';
        for(let key in this.style){
          if(this.style[key].length)  stylestr    +=  `${key}: ${this.style[key]};`;
        }
        stylestr        += this.Settings.rowSetting.inlineThemeCss
        this.style = stylestr
      }
    },
    getMainClasses() {
      let main = this.configForm && this.configForm.themeSelected ? this.configForm.themeSelected : "" + this.configForm && this.configForm.rowSetting && this.configForm.rowSetting.background && this.configForm.rowSetting.background.imagePath ? "linearGradient" : "";
      this.themePath = this.configForm && this.configForm.themePath ? this.configForm.themePath : "";
      if(this.Settings && this.Settings.rowSetting && this.Settings.rowSetting.spacing && (this.Settings.rowSetting.spacing.margin || this.Settings.rowSetting.spacing.padding)){
       let marg=this.Settings.rowSetting.spacing.margin
       if(marg.t !=0 || marg.b!=0 || marg.l!=0 || marg.r!=0 ){  
        main=' updatedMargin '
       }
       let padd=this.Settings.rowSetting.spacing.padding
       if(padd.t !=0 || padd.b!=0 || padd.l!=0 || padd.r!=0 ){
         main+=' updatedPadding '
       }
      }
      main += ' '+ this.themeSelected && (this.themeSelected != undefined)?this.themeSelected:'';
      return main;
    },

    getDynamicClass() {
      return this.Settings && this.Settings.rowSetting && this.Settings.rowSetting.themes && this.Settings.rowSetting.themes.classes ? this.Settings.rowSetting.themes.classes : "";
    },
    getDynamicId(){
      return this.Settings?.rowSetting?.id
    }
  },
};
</script>


