
<template>
	<div class="psc-cb-ipart-container" :style="{backgroundColor:Settings.rowSetting.background.contBackGroundCol}">
		<div class="section-marquee" :class="Settings.rowSetting.width==2?'textWidth':Settings.rowSetting.width==1?'fullWidth':'inner'">
			<div v-if="preset==1" class="psc-cb-marquee-container preset1">
				<h2 class="psc-cb-marquee-title psc-cb-ipart-title" v-html="Settings.mainHeading" v-if="Settings.mainHeading&&Settings.mainHeading.length"></h2>
        <marquee-text  :duration="Number(Settings.animationDuration)"  :paused="isPaused" @mouseenter="isPaused = !isPaused" @mouseleave="isPaused = false">
          <div  v-for="item,i in items" :key="i">
            <a target="_blank" :class="imageBlank[i]?'noimage hidden':''" :href="item[Settings.selectedIqaLinkField]?item[Settings.selectedIqaLinkField]:''">
              <img :src="item[Settings.selectedIqaField]" @error="replaceByDefault($event,i)" />
            </a>
          </div>
        </marquee-text>
				<div class="psc-cb-marquee-text" v-html="Settings.mainHeadingContext" v-if="Settings.mainHeadingContext&&Settings.mainHeadingContext.length"></div>
			</div>
	  </div>
    <div class="psc-cb-ipart-blocklink" v-if="Settings.rowSetting.blockBtnLinkPath">
			<a :target="Settings.rowSetting.buttonLinkTarget==2?'_blank':''" :class="Settings.rowSetting.blockClass" :href="Settings.rowSetting.blockBtnLinkPath?Settings.rowSetting.blockBtnLinkPath+(Settings.rowSetting.buttonSource==2&& Settings.rowSetting.hasOwnProperty('blockBtnLinkParameters')?Settings.rowSetting.blockBtnLinkParameters:''):'javascript:void(0)'" class="psc-cb-button">{{Settings.rowSetting.blockLabel}}</a>
		</div>
  </div>
</template>

<script>
import mtapi from "../../mtapi";
import MarqueeText from 'vue-marquee-text-component'

export default {
  name: "DynamicMarquee",
  components: {
    'marquee-text': MarqueeText
  },
  props: {
    Settings: Object,
  },
  data() {
    return {
      isPaused:false,
      style: {},
      imageBlank:{},
      iqaData:[],
      iqaQueryData:[],
      items:[],
      isActive: this.Settings.toolType != undefined && this.Settings.toolType !== "" ? false : true,
      mdIcon: '<i class="md-icon md-icon-font md-tab-icon md-theme-default fas fa-users"></i>',
      preset: this.Settings.toolType != undefined && this.Settings.toolType !== "" ? this.Settings.toolType : "",
    };
  },
  mounted() {
    this.getDocumentVersionKey(this.Settings.ipartIqaDocumentVersionKey, 0);
  },
  methods:{
    replaceByDefault(e,i) {
      e.target.src = ''
      this.imageBlank[i]=true
    },
    getImagePath(imagePath){
      if(!imagePath)
        return
      return (window.location.origin + "/" + imagePath)
    },
    async getDocumentVersionKey(value, index) {
      this.iqaQueryData = [];
      let url = `/api/iqa?QueryDocumentVersionKey=${value}`
      if(this.Settings.perPage){
        url += `&Limit=${this.Settings.perPage}`;
      }
      let response = await mtapi.axios.get(url);
      if (response.status === 200) {
        // EventBus.$emit("updatedIqaFilterData", response.data.Items.$values);
        response.data.Items.$values[0].Properties.$values.forEach((val) => {
          this.iqaQueryData.push(val.Name);
        });
        response.data.Items.$values.forEach((ele) => {
          let dataObj = {};
          ele.Properties.$values.forEach((item) => {
            dataObj[item.Name] = item.Value;
          });
        });
        this.iqaData = [];
          response.data.Items.$values.forEach((ele) => {
            let dataObj = {};
            ele.Properties.$values.forEach((item) => { dataObj[item.Name] = item.Value; });
            this.iqaData.push(Object.assign({}, dataObj));
          });
          this.items=[]
          this.items = this.iqaData
      }
    },
   }
 };
</script>

<style>
/* .dynmarquee{ height: 150px !important; }
.dynmarquee>div{ width: 150px; height: 150px !important; }
.dynmarquee .dynmarqueetile{ display: block; width: 100%; padding: 0 0 100%; background-position: center; background-repeat: no-repeat; background-size: contain; }
.dynmarquee .dynmarqueetile span{ display: block; width: 150px; height: 150px; } */
.dynmarqueecontainer{ position: relative; display: block; height: 10vh; width: 100vh; }
	.dynmarqueecontainer .dynmarquee{  }
		.dynmarqueecontainer .dynmarquee .dynmarqueeimg{  }
		.dynmarqueecontainer .dynmarquee .dynmarqueetilecntr{  }
			.dynmarqueecontainer .dynmarquee .dynmarqueetilecntr .dynmarqueetile{  }
				.dynmarqueecontainer .dynmarquee .dynmarqueetilecntr .dynmarqueetile img{ display: block; max-width: 100%; }
				.dynmarqueecontainer .dynmarquee .dynmarqueetilecntr .dynmarqueetile span{  }
	.dynmarquee a{ display: block; width: 15vh; height: 15vh; }
	.dynmarquee a img{ display: block; width: 15vh; height: 15vh; }
.marquee-text-wrap{ width: 100%; }
	.marquee-text-text{  }
		.marquee-text-text>div{ float: left; }
			.marquee-text-text>div a{ display: flex; align-items: center; justify-content: center; padding: 0 1rem; }
				.marquee-text-text>div a img{ display: inline-block; max-width: 100%; max-height: 150px; }
</style>