<template>
	<div class="psc-cb-ipart-container" :style="{backgroundColor:Settings.rowSetting.background.contBackGroundCol}">
		<div class="section-tabset" :class="Settings.rowSetting.width==2?'textWidth':Settings.rowSetting.width==1?'fullWidth':'inner'">
			<div class="psc-cb-tabset-container" :class="`preset${preset}`">
				<div class="psc-cb-tabset-nav">
					<a v-for="(item, index) of Settings.items" :key="index" @click.prevent="activeTabIndex=index" :class="{'active':activeTabIndex==index}">
						<span class="psc-cb-tabset-nav-icon" v-if="Settings.showIcon" :class="iconClasses[Settings.iconSize]"><em :class="item.icon"></em></span>
						<span class="psc-cb-tabset-nav-title" v-html="item.title"></span>
					</a>
				</div>
				<div class="psc-cb-tabset-tabs">
					<div v-for="(item, index) of Settings.items" :key="index" class="psc-cb-tabset-tab"  :class="{'active':activeTabIndex==index}" v-html="item.context" >
					</div>
				</div>
			</div>
		</div>
    <div class="psc-cb-ipart-blocklink" v-if="Settings.rowSetting.blockBtnLinkPath">
			<a :target="Settings.rowSetting.buttonLinkTarget==2?'_blank':''" :class="Settings.rowSetting.blockClass" :href="Settings.rowSetting.blockBtnLinkPath?Settings.rowSetting.blockBtnLinkPath+(Settings.rowSetting.buttonSource==2&& Settings.rowSetting.hasOwnProperty('blockBtnLinkParameters')?Settings.rowSetting.blockBtnLinkParameters:''):'javascript:void(0)'" class="psc-cb-button">{{Settings.rowSetting.blockLabel}}</a>
		</div>
	</div>
</template>

<script>
import common from "../../mtapi/common";
export default {
  name: "Tab",
  components: {
    // Tabs,
    // NavTabsCard,
  },
  props: {
    Settings: Object,
  },
  data() {
    return {
      envMode:"cloud",
      size_full:"full",
      iconClasses: common.getClassName(),
      imagePath :window.location.origin,
      style: {},
      contBackGroundColHex: "rgba(255, 255, 255, 0)",
      isActive:
        this.Settings && (this.Settings.toolType || this.Settings.toolType == 0)
          ? false
          : true,
      editorIndex: 0,
      openEditorFlag: false,
      mdIcon:
        '<i class="md-icon md-icon-font md-tab-icon md-theme-default fas fa-users"></i>',
      preset:
        this.Settings && (this.Settings.toolType || this.Settings.toolType == 0)
          ? this.Settings.toolType
          : "",
      context: "",
      mainHeading: {
        title: ["Profile", "Shopping", "Biking"],
        icon: ["fa fa-users", "far fa-smile", "fa fa-search"],
      },
      editorData: '',
      editorConfig: {        
        iMIS: {
          allowedBlockAttributes: ['id', 'class', 'data-test']
        }
      },
      configForm: {
        showIcon:
          (this.Settings && this.Settings.showIcon) ||
          (this.Settings && this.Settings.showIcon == 0)
            ? this.Settings.showIcon
            : 1,
        iconPostion:
          this.Settings && this.Settings.iconPostion
            ? this.Settings.iconPostion
            : 0,
        iconSize:
          this.Settings && this.Settings.iconSize
            ? this.Settings.iconSize
            : "30px!important",
        openTab:
          this.Settings && this.Settings.openTab ? this.Settings.openTab : 0,
        items:
          this.Settings && this.Settings.items
            ? this.Settings.items
            : [
                {
                  title: "Profile",
                  icon: "face",
                  context:
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
                },
                {
                  title: "Message",
                  icon: "message",
                  context:
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
                },
                {
                  title: "Setting",
                  icon: "build",
                  context:
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
                },
              ],
        rowSetting:
          this.Settings && this.Settings.rowSetting
            ? this.Settings.rowSetting
            : {
                spacing: {
                  margin: {
                    t: 15,
                    r: 10,
                    b: 15,
                    l: 10,
                    ratio: "px!important",
                  },
                  padding: {
                    t: 10,
                    r: 10,
                    b: 10,
                    l: 10,
                    ratio: "px!important",
                  },
                },
                themes: {
                  name: "light",
                  primaryColor: "red",
                  classes: "",
                },
                background: {
                  backGroundCol: "",
                  imageParallex: false,
                  selectBackground: 0,
                  mainColor: "",
                  contBackGroundCol: "rgba(255, 255, 255, 0)",
                  backgroundStyle: 0,
                  imagePath: "",
                  imageKey: "",
                  textCol: "",
                  tabCol: "",
                  opacity: 50,
                },
              },
      },
	  activeTabIndex: 0
    };
  },
  mounted() {
    this.updateValue();
    let margin = this.configForm.rowSetting.spacing.margin;
    let padding = this.configForm.rowSetting.spacing.padding;
    setTimeout(() => {
      if (this.configForm.showIcon == 1) {
        this.mainHeading.icon.forEach((value, index) => {
          value.split(" ").forEach((value) => {
            document
              .getElementsByClassName("md-tab-nav-button")
              [index].children[0].children[0].children[0].classList.add(value);
          });
          if (
            document.getElementsByClassName("md-tab-nav-button") &&
            document.getElementsByClassName("md-tab-nav-button").length > 0 &&
            this.configForm.showIcon == 1
          ) {
            document.getElementsByClassName("md-tab-nav-button")[
              index
            ].children[0].children[0].children[0].style.fontSize = String(
              this.configForm.iconSize
            );
          }
        });
      }
    }, 200);
  },
  watch: {
    "mainHeading.icon": function (newVal, oldVal) {
      //to work with changes in prop
      if (newVal)
        newVal.forEach((element, index) => {
          element.split(" ").forEach((value) => {
            if (
              document.getElementsByClassName("md-tab-nav-button") &&
              document.getElementsByClassName("md-tab-nav-button").length > 0 &&
              this.configForm.showIcon == 1
            ) {
              document
                .getElementsByClassName("md-tab-nav-button")
                [index].children[0].children[0].children[0].classList.add(
                  value
                );
            }
          });
          if (
            document.getElementsByClassName("md-tab-nav-button") &&
            document.getElementsByClassName("md-tab-nav-button").length > 0 &&
            this.configForm.showIcon == 1
          ) {
            document.getElementsByClassName("md-tab-nav-button")[
              index
            ].children[0].children[0].children[0].style.fontSize = String(
              this.configForm.iconSize
            );
          }
        });
    },
    context: {
      handler() {
        this.reverseContext();
      },
      deep: true,
    },
  },
  methods: {
    updateValue() {
      this.mainHeading.title = [];
      this.mainHeading.icon = [];
      this.$parent.$emit("configForm", this.configForm);
      if (this.configForm && this.configForm.items)
        this.configForm.items.filter((value) => {
          this.mainHeading.title.push(value.title);
          this.mainHeading.icon.push(value.icon);
          let margin = this.configForm.rowSetting.spacing.margin;
          let padding = this.configForm.rowSetting.spacing.padding;
          if (this.configForm && this.configForm.rowSetting)
            this.style = {
              "background-repeat":
                this.configForm.rowSetting.background &&
                this.configForm.rowSetting.background.imagePath
                  ? "no-repeat!important"
                  : "",
              "background-size":
                this.configForm.rowSetting.background &&
                this.configForm.rowSetting.background.imagePath
                  ? "cover!important"
                  : "",
              "background-position":
                this.configForm.rowSetting.background &&
                this.configForm.rowSetting.background.imagePath
                  ? "center!important"
                  : "",
              "margin-top":
                margin.t != 0 ||
                margin.r != 0 ||
                margin.b != 0 ||
                margin.l != 0 ||
                padding.t != 0 ||
                padding.r != 0 ||
                padding.b != 0 ||
                padding.l != 0
                  ? Number(margin.t) + margin.ratio + "!important"
                  : "",
              "margin-right":
                margin.t != 0 ||
                margin.r != 0 ||
                margin.b != 0 ||
                margin.l != 0 ||
                padding.t != 0 ||
                padding.r != 0 ||
                padding.b != 0 ||
                padding.l != 0
                  ? Number(margin.r) + margin.ratio + "!important"
                  : "",
              "margin-bottom":
                margin.t != 0 ||
                margin.r != 0 ||
                margin.b != 0 ||
                margin.l != 0 ||
                padding.t != 0 ||
                padding.r != 0 ||
                padding.b != 0 ||
                padding.l != 0
                  ? Number(margin.b) + margin.ratio + "!important"
                  : "",
              "margin-left":
                margin.t != 0 ||
                margin.r != 0 ||
                margin.b != 0 ||
                margin.l != 0 ||
                padding.t != 0 ||
                padding.r != 0 ||
                padding.b != 0 ||
                padding.l != 0
                  ? Number(margin.l) + margin.ratio + "!important"
                  : "",
              "padding-top":
                margin.t != 0 ||
                margin.r != 0 ||
                margin.b != 0 ||
                margin.l != 0 ||
                padding.t != 0 ||
                padding.r != 0 ||
                padding.b != 0 ||
                padding.l != 0
                  ? Number(padding.t) + padding.ratio + "!important"
                  : "",
              "padding-right":
                margin.t != 0 ||
                margin.r != 0 ||
                margin.b != 0 ||
                margin.l != 0 ||
                padding.t != 0 ||
                padding.r != 0 ||
                padding.b != 0 ||
                padding.l != 0
                  ? Number(padding.r) + padding.ratio + "!important"
                  : "",
              "padding-bottom":
                margin.t != 0 ||
                margin.r != 0 ||
                margin.b != 0 ||
                margin.l != 0 ||
                padding.t != 0 ||
                padding.r != 0 ||
                padding.b != 0 ||
                padding.l != 0
                  ? Number(padding.b) + padding.ratio + "!important"
                  : "",
              "padding-left":
                margin.t != 0 ||
                margin.r != 0 ||
                margin.b != 0 ||
                margin.l != 0 ||
                padding.t != 0 ||
                padding.r != 0 ||
                padding.b != 0 ||
                padding.l != 0
                  ? Number(padding.l) + padding.ratio + "!important"
                  : "",
              // "background-color":
              //   this.configForm.rowSetting.background &&
              //   this.configForm.rowSetting.background.mainColor
              //     ? this.configForm.rowSetting.background.mainColor +
              //       "!important"
              //     : "",
              "background-image":
                this.configForm.rowSetting.background &&
                this.configForm.rowSetting.background.imagePath
                  ? "url("+this.imagePath +
                    this.configForm.rowSetting.background.imagePath.substring(
                      1
                    ) +
                    ")!important"
                  : "",
            };
          if (
            this.configForm.rowSetting &&
            this.configForm.rowSetting.background &&
            this.configForm.rowSetting.background.imageParallex
          ) {
            this.style["background-attachment"] = "fixed!important";
          }
          this.contBackGroundColHex =
            this.configForm.rowSetting &&
            this.configForm.rowSetting.background &&
            this.configForm.rowSetting.background.contBackGroundCol;
        });
      if (this.configForm.showIcon == 1) {
        setTimeout(() => {
          this.mainHeading.icon.forEach((value, index) => {
            value.split(" ").forEach((value) => {
              if (
                document.getElementsByClassName("md-tab-nav-button") &&
                document.getElementsByClassName("md-tab-nav-button").length > 0
              )
                document
                  .getElementsByClassName("md-tab-nav-button")
                  [index].children[0].children[0].children[0].classList.add(
                    value
                  );
            });
            if (
              this.configForm.iconSize == "20px!important" &&
              document.getElementsByClassName("md-tab-nav-button") &&
              document.getElementsByClassName("md-tab-nav-button").length > 0 &&
              this.configForm.showIcon == 1
            ) {
              // document.getElementsByClassName("md-tab-nav-button")[index].children[0].children[0].children[0].classList.add('fa-xs');
            }
            if (
              this.configForm.iconSize == "20px!important" &&
              document.getElementsByClassName("md-tab-nav-button") &&
              document.getElementsByClassName("md-tab-nav-button").length > 0
            ) {
              document
                .getElementsByClassName("md-tab-nav-button")
                [index].children[0].children[0].children[0].classList.add(
                  "md-size-x1"
                );
              document
                .getElementsByClassName("md-tab-nav-button")
                [index].children[0].children[0].children[0].classList.remove(
                  "md-size-x4"
                );
              document
                .getElementsByClassName("md-tab-nav-button")
                [index].children[0].children[0].children[0].classList.remove(
                  "md-size-x2"
                );
              document
                .getElementsByClassName("md-tab-nav-button")
                [index].children[0].children[0].children[0].classList.remove(
                  "md-size-x3"
                );
            } else if (
              this.configForm.iconSize == "25px!important" &&
              document.getElementsByClassName("md-tab-nav-button") &&
              document.getElementsByClassName("md-tab-nav-button").length > 0
            ) {
              document
                .getElementsByClassName("md-tab-nav-button")
                [index].children[0].children[0].children[0].classList.add(
                  "md-size-x2"
                );
              document
                .getElementsByClassName("md-tab-nav-button")
                [index].children[0].children[0].children[0].classList.remove(
                  "md-size-x1"
                );
              document
                .getElementsByClassName("md-tab-nav-button")
                [index].children[0].children[0].children[0].classList.remove(
                  "md-size-x4"
                );
              document
                .getElementsByClassName("md-tab-nav-button")
                [index].children[0].children[0].children[0].classList.remove(
                  "md-size-x3"
                );
            } else if (
              this.configForm.iconSize == "30px!important" &&
              document.getElementsByClassName("md-tab-nav-button") &&
              document.getElementsByClassName("md-tab-nav-button").length > 0
            ) {
              document
                .getElementsByClassName("md-tab-nav-button")
                [index].children[0].children[0].children[0].classList.add(
                  "md-size-x3"
                );
              document
                .getElementsByClassName("md-tab-nav-button")
                [index].children[0].children[0].children[0].classList.remove(
                  "md-size-x1"
                );
              document
                .getElementsByClassName("md-tab-nav-button")
                [index].children[0].children[0].children[0].classList.remove(
                  "md-size-x2"
                );
              document
                .getElementsByClassName("md-tab-nav-button")
                [index].children[0].children[0].children[0].classList.remove(
                  "md-size-x4"
                );
            } else if (
              this.configForm.iconSize == "35px!important" &&
              document.getElementsByClassName("md-tab-nav-button") &&
              document.getElementsByClassName("md-tab-nav-button").length > 0
            ) {
              document
                .getElementsByClassName("md-tab-nav-button")
                [index].children[0].children[0].children[0].classList.add(
                  "md-size-x4"
                );
              document
                .getElementsByClassName("md-tab-nav-button")
                [index].children[0].children[0].children[0].classList.remove(
                  "md-size-x1"
                );
              document
                .getElementsByClassName("md-tab-nav-button")
                [index].children[0].children[0].children[0].classList.remove(
                  "md-size-x2"
                );
              document
                .getElementsByClassName("md-tab-nav-button")
                [index].children[0].children[0].children[0].classList.remove(
                  "md-size-x3"
                );
            }
          });
        }, 200);
      }
    },
    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            0: parseInt(result[1], 16),
            1: parseInt(result[2], 16),
            2: parseInt(result[3], 16),
          }
        : null;
    },
    getstyle() {
      let margin = this.configForm.spacing.margin;
      let style = {
        "margin-top": Number(margin.t) + "px",
        "margin-right": Number(margin.t) + "px",
        "margin-bottom": "0px",
        "margin-left": "0px",
        "padding-top": "0px",
        "padding-right": "0px",
        "padding-bottom": "0px",
        "padding-left": "0px",
      };
      return style;
    },
    signalChange: function (evt) {
      this.$emit("change", evt);
    },
    addclassDynamic(i) {},
    selectPreset(i) {
      this.isActive = false;
      this.preset = i;
    },
    reverseContext() {
      this.configForm.items[this.editorIndex].context = this.context;
    },
    openEditor(i) {
      this.editorIndex = i;
      this.openEditorFlag = true;
    },
    closeEditor() {
      this.openEditorFlag = false;
    },
  },
};
</script>