<template>
  <div class="section-pricing " :class="Settings.rowSetting.width == 2? 'textWidth': Settings.rowSetting.width == 1? 'fullWidth' : 'inner'">
    <!-- Pricing 2 -->
    <div v-if="isActive || preset == 2" class="align-items-center">
      <div class=" items-content image-bg preset0">
        <div class="section section-pricing-2">
          <div class="container" :style="{ background: Settings.rowSetting.background.contBackGroundCol + '!important', }">
            <div class="md-layout">
              <div class=" md-layout-item md-size-50 md-small-size-100 justify-content-center mx-auto text-center " >
                <h2 class="main_heading">{{ Settings.mainHeading }}</h2>
                <tabs pills-align="center" :tab-active="selectedTab + 1 ? selectedTab + 1 : 1" :tab-name=" getTabTitles( configForm.toolType ? Settings.items : defaultItem[2] )" plain color-button="rose" v-on:tabChange="tabChange">
                  <template slot="tab-pane-1" />
                  <template slot="tab-pane-2" />
                </tabs>
              </div>
            </div>
            <div class="md-layout" v-for="(mainItem, mainIndex) of Settings.items" :key="mainIndex">
              <div class="md-layout-item md-size-33 md-small-size-100" v-for="(item, index) of mainItem.mainItems" :key="index">
                <div v-if="mainIndex == selectedTab">
                  <full-bg-card v-if="item.premium" card-pricing :card-image=" item.imageKey ? item.imagePath : require('@/assets/img/examples/city.jpg')">
                    <template slot="cardContent">
                      <h6 class="card-category">{{ item.optionalHeading }}</h6>
                      <h2 class="card-title">
                        <small>$</small>
                        {{ item.secondMainPrice }} 
                        <small>{{ item.period }}</small>
                      </h2>
                      <ul>
                        <li v-for="(sitem, sindex) of item.subItem" :key="sindex" >
                          <b>{{ sitem.price }}</b> {{ sitem.priceItem }}
                        </li>
                      </ul>
                      <md-button :href=" item && item.btnLink ? item.btnLink : 'javascript:void(0)'" class="md-white md-round"> {{ item.buttonLabel }} </md-button>
                    </template>
                  </full-bg-card>

                  <pricing-card v-if="!item.premium" card-plain>
                    <template slot="cardContent">
                      <h6 class="card-category"> {{ item.optionalHeading }} </h6>
                      <h2 class="card-title">
                        <small>$</small>{{ item.secondMainPrice }}
                        <small>{{ item.period }}</small>
                      </h2>
                      <ul>
                        <li v-for="(sitem, sindex) of item.subItem" :key="sindex" > <b>{{ sitem.price }}</b> {{ sitem.priceItem }} </li>
                      </ul>
                      <md-button :href=" item && item.btnLink ? item.btnLink : 'javascript:void(0)'" class="md-rose md-round"> {{ item.buttonLabel }} </md-button>
                    </template>
                  </pricing-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end Pricing 2 -->

    <!-- Pricing 3 -->
    <div v-if="isActive || preset == 3" class="align-items-center">
      <div class=" items-content image-bg preset0">
        <div class="section section-pricing-3">
          <div class="container" :style="{ background: Settings.rowSetting.background.contBackGroundCol + '!important',}">
            <div class="md-layout">
              <div class=" md-layout-item md-size-66 md-small-size-100 justify-content-center mx-auto text-center">
                <h2 class="main_heading">{{ Settings.mainHeading }}</h2>
                <div class="description" v-html="Settings.mainHeadingContext"></div>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-33 md-small-size-100 ml-auto" v-for="(item, index) of Settings.items" :key="index">
                <pricing-card :card-bg="item.premium ? 'primary' : ''">
                  <template slot="cardContent">
                    <h6 class="card-category text-gray">{{ item.title }}</h6>
                    <h2 class="card-title">
                      <small>$</small>{{ item.mainPrice }}
                      <small>{{ item.period }}</small>
                    </h2>
                    <ul>
                      <li v-for="(subItem, zindex) of item.subItem" :key="zindex"> <b>{{ subItem.price }}</b> {{ subItem.priceItem }} </li>
                    </ul>
                    <md-button :href=" item && item.btnLink ? item.btnLink : 'javascript:void(0)'" class="md-round" :class="{ 'md-white': item.premium, 'md-danger': !item.premium,}"> {{ item.buttonLabel }} </md-button>
                  </template>
                </pricing-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end Pricing 3 -->

    <!-- Pricing 4 -->
    <div v-if="isActive || preset == 4" class="align-items-center">
      <div class=" items-content image-bg preset0">
        <div class="section section-pricing-4">
          <div class="container" :style="{ background: Settings.rowSetting.background.contBackGroundCol + '!important',}">
            <div class="md-layout">
              <div class=" md-layout-item md-size-66 md-small-size-100 justify-content-center mx-auto text-center">
                <h2 class="main_heading">{{ Settings.mainHeading }}</h2>
                <h5 class="description" v-html="Settings.mainHeadingContext"></h5>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-25 md-small-size-100" v-for="(item, index) of Settings.items" :key="index">
                <pricing-card :card-bg="item.premium ? 'success' : ''">
                  <template slot="cardContent">
                    <h6 class="card-category text-gray">{{ item.title }}</h6>
                    <h2 class="card-title"> <small>$</small>{{ item.mainPrice }}</h2>
                    <ul>
                      <li v-for="(subItem, zindex) of item.subItem" :key="zindex">
                        <md-icon :class="{'text-success': subItem.priceStatus, 'text-danger': !subItem.priceStatus,}">{{ subItem.priceStatus ? "check" : "close" }}</md-icon>
                        {{ subItem.priceItem }}
                      </li>
                    </ul>
                    <md-button :href=" item && item.btnLink ? item.btnLink : 'javascript:void(0)'" class="md-round" :class="{'md-white': item.premium, 'md-danger': !item.premium,}">{{ item.buttonLabel }}</md-button>
                  </template>
                </pricing-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end Pricing 4 -->

    <!-- Pricing 5 -->
    <div v-if="isActive || preset == 5" class="align-items-center">
      <div class=" items-content image-bg preset0">
        <div class="section section-pricing-5">
          <div class="container" :style="{background: Settings.rowSetting.background.contBackGroundCol + '!important',}">
            <div class="md-layout">
              <div class="md-layout-item md-size-33 md-small-size-100">
                <h2 class="main_heading">{{ Settings.mainHeading }}</h2>
                <tabs pills-align="left" :tab-active="selectedTab + 1 ? selectedTab + 1 : 1" :tab-name=" getTabTitles( configForm.toolType ? Settings.items : defaultItem[5] )" plain color-button="primary">
                  <template slot="tab-pane-1" />
                  <template slot="tab-pane-2" />
                </tabs>
                <p class="text-gray" v-html="Settings.mainHeadingContext"></p>
              </div>
              <div :class="{ 'md-layout-item md-size-60 md-small-size-100 mx-auto': mainIndex == selectedTab,}" v-for="(mainItem, mainIndex) of Settings.items" :key="mainIndex">
                <div v-if="mainIndex == selectedTab">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-50 md-small-size-100" v-for="(item, index) of mainItem.mainItems" :key="index">
                      <pricing-card :card-plain="item.premium ? false : true">
                        <template slot="cardContent">
                          <h6 class="card-category text-gray"> {{ item.optionalHeading }} </h6>
                          <h2 class="card-title"> <small>$</small> <template> {{ item.secondMainPrice }} </template> <small>{{ item.period }}</small> </h2>
                          <ul> <li v-for="(subItem, zindex) of item.subItem" :key="zindex"> <b><template v-if="subItemFlag">{{ subItem.price }}</template> <template v-else>{{ subItem.secondPriceFlag }}</template> </b> {{ subItem.priceItem }}</li> </ul>
                          <md-button :href=" item && item.btnLink ? item.btnLink : 'javascript:void(0)'" class="md-round" :class="{ 'md-primary': item.premium, 'md-white': !item.premium, }"> {{ item.buttonLabel }} </md-button>
                        </template>
                      </pricing-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end Pricing 5 -->

    <!-- Pricing 1 -->
    <div v-if="isActive || preset == 1" class="align-items-center">
      <div class=" items-content image-bg preset0">
        <div class="section section-pricing-1 preset-5">
          <div class="container" :style="{ background: Settings.rowSetting.background.contBackGroundCol + '!important', }">
            <div class="md-layout">
              <div class=" md-layout-item md-size-66 md-small-size-100 justify-content-center mx-auto text-center">
                <h2 class="main_heading">{{ Settings.mainHeading }}</h2>
                <h5 class="description" v-html="Settings.mainHeadingContext"></h5>
              </div>
            </div>
            <div class="md-layout">
              <div class=" md-layout-item md-size-50 md-small-size-100 md-large-size-50" v-for="(item, index) of Settings.items" :key="index">
                <pricing-card :card-plain="item.premium ? false : true">
                  <template slot="cardContent"> 
                    <h6 class="card-category text-gray"> {{ item.optionalHeading }}</h6>
                    <div :class="{ 'icon icon-rose': item.premium, icon: !item.premium, }" > <i class="material-icons">weekend</i> </div>
                    <h3 class="card-title">{{ item.title }}</h3>
                    <p class="card-description" v-html="item.context"></p>
                    <md-button href="javascript:void(0)" class="md-round" :class="{ 'md-rose': item.premium, 'md-white': !item.premium, }"> {{ item.buttonLabel }}</md-button>
                  </template>
                </pricing-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end Pricing 1 -->
  </div>
</template>

<script>
import { Tabs, FullBgCard, PricingCard } from "@/components";
import common from "../../mtapi/common";
export default {
  name: "Pricing",
  components: {
    PricingCard,
    FullBgCard,
    Tabs,
  },
  props: { Settings: Object, },
  data() {
    return {
      selectedTab: 0,
      defaultItem: common.getDefaultItems(8),
      subItemFlag: true,
      mainHeading: { title: [], },
      isActive: this.Settings.toolType && this.Settings.toolType != "" ? false : true,
      preset: this.Settings.toolType ? this.Settings.toolType : "",
      configForm: {
        swapPosition: this.Settings && this.Settings.swapPosition ? this.Settings.swapPosition : "",
        copyAdd: this.Settings && this.Settings.copyAdd ? this.Settings.copyAdd : 0,
        mainHeading: this.Settings && this.Settings.hasOwnProperty('mainHeading') ? this.Settings.mainHeading : common.getDefaultConfigLabel(8).mainHeading,
        mainHeadingContext: this.Settings && this.Settings.hasOwnProperty('mainHeadingContext') ? this.Settings.mainHeadingContext : common.getDefaultConfigLabel(8).mainHeadingContext,
        showIcon: (this.Settings && this.Settings.showIcon) || this.Settings.showIcon == 0 ? this.Settings.showIcon : 1,
        iconPostion: this.Settings.iconPostion || 0,
        iconSize: this.Settings.iconSize || "medium",
        openTab: this.Settings.openTab || 0,
        items: this.Settings.items,
        rowSetting:
          this.Settings && this.Settings.rowSetting
            ? this.Settings.rowSetting
            : {
                spacing: {
                 margin: { t: 0, r: 0, b: 0, l: 0, ratio: "px!important", },
                 padding: { t: 0, r: 0, b: 0, l: 0, ratio: "px!important", },
                },
                themes: { primaryColor: "red", classes: "", },
                background: {
                  backGroundCol: "",
                  imageParallex: false,
                  selectBackground: 0,
                  mainColor: "",
                  contBackGroundCol: "rgba(255, 255, 255, 0)",
                  backgroundStyle: 0,
                  imagePath: "",
                  imageKey: "",
                  textCol: "",
                  tabCol: "",
                  opacity: 50,
                },
              },
        toolType: this.Settings && this.Settings.toolType ? this.Settings.toolType : "",
        toolIndex: 8,
      },
    };
  },
  mounted() {
    this.$on("tabChange", (data) => {
      this.selectedTab = data;
    });
    this.updateValue();
  },
  methods: {
    tabChange(data) {
      this.selectedTab = data;
    },
    getTabTitles(items) {
      let titles = [];
      items.forEach((element) => {
        titles.push(element.title);
      });
      return titles;
    },
    sectionPricing(image) {
      return { backgroundImage: `url(${image})`, };
    },
    changePrices() {
      this.subItemFlag = !this.subItemFlag;
    },
    selectPreset(i) {
      this.isActive = false;
      this.preset = i;
      EventBus.$emit("preset", i);
    },
    updateValue() {
      this.configForm.items.filter((value) => {
        this.mainHeading.title.push(value.title);
      });
    },
  },
};
</script>
