<template>
	<div class="psc-cb-ipart-container" :style="{backgroundColor:Settings.rowSetting.background.contBackGroundCol}">
		<div class="section-flipbox" :class="Settings.rowSetting.width==2?'textWidth':Settings.rowSetting.width==1?'fullWidth':'inner'">
			<h2 class="psc-cb-flipbox-title" v-html="Settings.mainHeading"></h2>
			<div class="psc-cb-flipbox-text" v-html="Settings.mainHeadingContext"></div>
			
			<!-- Preset 1 -->
			<div v-if="isActive||preset==1 || preset==3" class="psc-cb-flipbox-container" :class="'preset'+preset + ' ' + rowClasses[Settings.cardsPerRow]">
				<div class="psc-cb-flipbox-card" 
					v-for="(item, index) of this.Settings.items" 
					:key="index"
				>
					<div class="psc-cb-flipbox-face psc-cb-flipbox-front" :class="{'hasImage':item.imagePath.length}" :style="{backgroundColor: item.color,color: item.fontColor, backgroundImage: 'url('+item.imagePath+')'}">
						<div class="psc-cb-flipbox-content">
							<div v-if="preset==3" class="psc-cb-flipbox-icon-image-bar" >
								<span class="psc-cb-flipbox-icon-image" :style="{ 'background-image': 'url('+item.iconImagePath+')' }"></span>
							</div>
								<span v-if="preset!=3" class="psc-cb-flipbox-subheading">
									<em v-if="Settings.showIcon" :class="item.icon"></em>
									<span v-html="item.optionalHeading"></span>
								</span>
								<h4 v-html="item.title" :style="{ color: item.fontColor }"></h4>
								<div v-if="preset!=3" class="psc-cb-flipbox-description" v-html="item.context"></div>
						</div>
					</div>
					<div class="psc-cb-flipbox-face psc-cb-flipbox-back" :class="{'hasImage':item.b_imagePath.length}" :style="{backgroundColor: item.b_color,color: item.b_fontColor, backgroundImage: 'url('+item.b_imagePath+')'}">
						<div class="psc-cb-flipbox-content">
							<span v-if="preset!=3" class="psc-cb-flipbox-subheading">
								<em v-if="Settings.showIcon" :class="item.b_icon"></em>
								<span v-html="item.b_title"></span>
							</span>
							<div class="psc-cb-flipbox-description" v-html="item.b_context"></div>
							<div class="psc-cb-flipbox-actions"><a :target="item.b_buttonLinkTabOption==2?'_blank':''" :class="getBackButtonClass(item)" v-if="item.b_btnLinkPath" :href="item.b_btnLinkPath + (item.b_itemButtonSource==2&& item.hasOwnProperty('b_btnLinkParameters')?item.b_btnLinkParameters:'')" class="psc-cb-button">{{ item.b_buttonLabel }}</a></div>
						</div>
					</div>
				</div>
			</div>
			<!-- end Preset 1 -->

			<!-- Preset 2-->
			<div v-if="isActive||preset==2" class="psc-cb-flipbox-container preset2" :class="rowClasses[Settings.cardsPerRow]">
				<div class="psc-cb-flipbox-card" 
					v-for="(item, index) of this.Settings.items" 
					:key="index"
					:class="{'flipped':manualRotateState[index][index]}"
				>
					<div class="psc-cb-flipbox-face psc-cb-flipbox-front" :class="{'hasImage':item.imagePath.length}" :style="{backgroundColor: item.color, backgroundImage: 'url('+item.imagePath+')'}">
						<div class="psc-cb-flipbox-content">
							<span class="psc-cb-flipbox-subheading">
								<em v-if="Settings.showIcon" :class="item.icon"></em>
								<span v-html="item.optionalHeading"></span>
							</span>
							<h4 v-html="item.title"></h4>
							<div class="psc-cb-flipbox-description" v-html="item.context"></div>
						</div>
						<div class="psc-cb-flipbox-subactions"><a @click="manualRotateState[index][index] = true"><i class="fa fa-sync-alt"></i> Rotate</a></div>
					</div>
					<div class="psc-cb-flipbox-face psc-cb-flipbox-back" :class="{'hasImage':item.b_imagePath.length}" :style="{backgroundColor: item.b_color, backgroundImage: 'url('+item.b_imagePath+')'}">
						<div class="psc-cb-flipbox-content">
							<span class="psc-cb-flipbox-subheading">
								<em v-if="Settings.showIcon" :class="item.b_icon"></em>
								<span v-html="item.b_title"></span>
							</span>
							<div class="psc-cb-flipbox-description" v-html="item.b_context"></div>
							<div class="psc-cb-flipbox-actions"><a :target="item.buttonLinkTabOption==2?'_blank':''" :class="getButtonClass(item)" v-if="item.btnLinkPath" :href="item.btnLinkPath + (item.itemButtonSource==2&& item.hasOwnProperty('btnLinkParameters')?item.btnLinkParameters:'')" class="psc-cb-button">{{ item.buttonLabel }}</a></div>
						</div>
						<div class="psc-cb-flipbox-subactions"><a @click="manualRotateState[index][index] = false"><i class="fa fa-sync-alt"></i> Back</a></div>
					</div>
				</div>
			</div>
			<!-- end Preset 2 -->
		</div>
    	<div class="psc-cb-ipart-blocklink" v-if="Settings.rowSetting.blockBtnLinkPath">
			<a :target="Settings.rowSetting.buttonLinkTarget==2?'_blank':''" :class="Settings.rowSetting.blockClass" :href="Settings.rowSetting.blockBtnLinkPath?Settings.rowSetting.blockBtnLinkPath+(Settings.rowSetting.buttonSource==2&& Settings.rowSetting.hasOwnProperty('blockBtnLinkParameters')?Settings.rowSetting.blockBtnLinkParameters:''):'javascript:void(0)'" class="psc-cb-button">{{Settings.rowSetting.blockLabel}}</a>
		</div>
  </div>
</template>

<script>
import common from "../../mtapi/common";
export default {
  name: "FlipBox",
  components: {},
  props: {
    Settings: Object,
    indexNum: Number,
  },
  data() {
    return {
      rowClasses:common.getRowClasses(),
      manualRotateState: this.getRotating(this.Settings.items),
      defaultItem: common.getDefaultItems(10),
      isActive:
        this.Settings.toolType && this.Settings.toolType != "" ? false : true,
      preset: this.Settings.toolType ? this.Settings.toolType : "",
      configForm: {
        swapPosition:
          this.Settings && this.Settings.swapPosition
            ? this.Settings.swapPosition
            : "",
        copyAdd:
          this.Settings && this.Settings.copyAdd ? this.Settings.copyAdd : 0,
        mainHeading:
          this.Settings && this.Settings.mainHeading
            ? this.Settings.mainHeading
            : "Follow your dreams",
        mainHeadingContext:
          this.Settings && this.Settings.mainHeadingContext
            ? this.Settings.mainHeadingContext
            : "We’re constantly trying to express ourselves and actualize our dreams. If you have the opportunity to play this game of life you need to appreciate every moment",
        showIcon:
          (this.Settings && this.Settings.showIcon) ||
          this.Settings.showIcon == 0
            ? this.Settings.showIcon
            : 1,
        iconPostion: this.Settings.iconPostion || 0,
        iconSize: this.Settings.iconSize || "medium",
        openTab: this.Settings.openTab || 0,
        items:
          this.Settings && this.Settings.items
            ? this.Settings.items
            : common.getItems(
                this.Settings.toolIndex,
                this.Settings.toolType,
                1
              ),
        rowSetting:
          this.Settings && this.Settings.rowSetting
            ? this.Settings.rowSetting
            : {
                spacing: {
                  margin: {
                    t: 0,
                    r: 0,
                    b: 0,
                    l: 0,
                    ratio: "px!important",
                  },
                  padding: {
                    t: 0,
                    r: 0,
                    b: 0,
                    l: 0,
                    ratio: "px!important",
                  },
                },
                themes: {
                  primaryColor: "red",
                  classes: "",
                },
                background: {
                  backGroundCol: "",
                  imageParallex: false,
                  selectBackground: 0,
                  mainColor: "",
                  contBackGroundCol: "rgba(255, 255, 255, 0)",
                  backgroundStyle: 0,
                  imagePath: "",
                  imageKey: "",
                  textCol: "",
                  tabCol: "",
                  opacity: 50,
                },
              },
        toolType:
          this.Settings && this.Settings.toolType ? this.Settings.toolType : "",
        toolIndex: 10,
      },
	  cards: []
    };
  },
  mounted() {
    this.updateValue();
    if (this.indexNum) {
      this.preset = this.indexNum;
      this.selectPreset(this.preset);
    }
  },
  methods: {
    getButtonClass(item){
      let btnclass = item.buttonLinkTabOption==2?' psc-cb-new-tab ':''
      if(item.buttonClass){
        btnclass += ' '+ item.buttonClass
      }
      return btnclass 
    },
    getBackButtonClass(item){
      let btnclass = item.b_buttonLinkTabOption==2?' psc-cb-new-tab ':''
      if(item.b_buttonClass){
        btnclass += ' '+ item.b_buttonClass
      }
      return btnclass 
    },
    getRotating(items) {
      let data = [];
      for (let i = 0; i < items.length; i++) {
        data.push({ [i]: false });
      }
      return data;
    },
    sectionTeam(image) {
      return {
        backgroundImage: `url(${image})`,
      };
    },
    selectPreset(i) {
      this.isActive = false;
      this.preset = i;
      EventBus.$emit("preset", i);
    },
    updateValue() {},
    getHeightCard() {
      let height = {
        height:
          this.Settings && this.Settings.cardHeight
            ? this.Settings.cardHeight + "px"
            : "100px",
        overflow: "hidden",
      };

      return height;
    },
    getLineCard() {
      let height = {
        "-webkit-line-clamp":
          this.Settings && this.Settings.cardLines
            ? this.Settings.cardLines
            : 3,
      };

      return height;
    }
  },
};
</script>
