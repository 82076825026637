<template>
	<div class="psc-cb-ipart-container" :style="{backgroundColor:Settings.rowSetting.background.contBackGroundCol}">
		<div class="section-badges" :class="Settings.rowSetting.width==2?'textWidth':Settings.rowSetting.width==1?'fullWidth':'inner'">
			<div v-if="preset==1" class="psc-cb-badges-container preset1">
				<h2 class="psc-cb-badges-title psc-cb-ipart-title" v-html="Settings.mainHeading" v-if="Settings.mainHeading&&Settings.mainHeading.length"></h2>
				<div class="psc-cb-badges-text psc-cb-ipart-intro" v-html="Settings.mainHeadingContext" v-if="Settings.mainHeadingContext&&Settings.mainHeadingContext.length"></div>
				<ul class="psc-cb-badges-list">
					<li  v-for="(item, index) of Settings.items" :key="index"><a :target="item.buttonLinkTabOption==2?'_blank':''" :href="item && item.btnLinkPath ? item.btnLinkPath + (item.itemButtonSource==2&& item.hasOwnProperty('btnLinkParameters')?item.btnLinkParameters:'') : 'javascript:void(0)'"><img  :src="item.imagePath" /></a></li>
				</ul>
			</div>
	  </div>
    <div class="psc-cb-ipart-blocklink" v-if="Settings.rowSetting.blockBtnLinkPath">
      <a :target="Settings.rowSetting.buttonLinkTarget==2?'_blank':''" :class="Settings.rowSetting.blockClass" :href="Settings.rowSetting.blockBtnLinkPath?Settings.rowSetting.blockBtnLinkPath+(Settings.rowSetting.buttonSource==2&& Settings.rowSetting.hasOwnProperty('blockBtnLinkParameters')?Settings.rowSetting.blockBtnLinkParameters:''):'javascript:void(0)'" class="psc-cb-button">{{Settings.rowSetting.blockLabel}}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Badges",
  components: {},
  props: {
    Settings: Object,
  },
  data() {
    return {
      style: {},
      isActive: this.Settings.toolType != undefined && this.Settings.toolType !== "" ? false : true,
      mdIcon: '<i class="md-icon md-icon-font md-tab-icon md-theme-default fas fa-users"></i>',
      preset: this.Settings.toolType != undefined && this.Settings.toolType !== "" ? this.Settings.toolType : "",
      configForm: {
        showIcon: (this.Settings && this.Settings.showIcon) || this.Settings.showIcon == 0 ? this.Settings.showIcon : 1,
        iconPostion: this.Settings.iconPostion || 0,
        iconSize: this.Settings.iconSize || "30px",
        openTab: this.Settings.openTab || 0,
        items: this.Settings && this.Settings.items ? this.Settings.items
            : [
                {
                  buttonLabel: "Read More",
                  optionalHeading: "ENTERPRISE",
                  title: "Profile",
                  icon: "fas fa-users",
                  image: 'https://api.pseudocode.com.au/imgsrc/?r=1',
                  imageKey: "",
                  context: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
                },
                {
                  buttonLabel: "Read More",
                  optionalHeading: "ENTERPRISE",
                  title: "Message",
                  icon: "far fa-smile",
                  image: 'https://api.pseudocode.com.au/imgsrc/?r=2',
                  context: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
                },
                {
                  buttonLabel: "Read More",
                  optionalHeading: "ENTERPRISE",
                  title: "Setting",
                  icon: "fa fa-search",
                  image: 'https://api.pseudocode.com.au/imgsrc/?r=3',
                  context: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
                },
              ],
        rowSetting: this.Settings && this.Settings.rowSetting ? this.Settings.rowSetting
            : {
                spacing: {
                  margin: { t: 0, r: 0, b: 0, l: 0, ratio: "px", },
                  padding: { t: 0, r: 0, b: 0, l: 0, ratio: "px", },
                },
                themes: { primaryColor: "red", classes: "", },
                background: {
                  backGroundCol: "",
                  imageParallex: false,
                  selectBackground: 0,
                  mainColor: "",
                  contBackGroundCol: "rgba(255, 255, 255, 0)",
                  backgroundStyle: 0,
                  imagePath: "",
                  imageKey: "",
                  textCol: "",
                  tabCol: "",
                  opacity: 50,
                },
              },
      },
    };
  },
  methods:{ }
 };
</script>
